import React, { Component } from 'react'
import '../App.css'
import PropTypes from 'prop-types'
import ShowClosedCollectionHeader from '../components/ShowClosedCollectionHeader'
import ShowClosedCollectionContent from '../components/ShowClosedCollectionContent'
import { Logger } from "react-remote-logger"

class ShowClosedCollection extends Component {
  componentDidMount() {
    Logger({
      'timestamp': (new Date()).toLocaleString(),
      'level': 'info',
      'user_id': localStorage.user_id,
      'user': localStorage.user_email,
      'organization': localStorage.organization,
      'action': 'GET',
      'url': window.location.href,
      'message': `GET ${window.location.href}`
    })
  }

  render () {
    return (
      <div className='App'>
        <ShowClosedCollectionHeader
          org={this.props.match.params.org}
          selectedClosedCollectionId={this.props.location.state && this.props.location.state.selectedClosedCollectionId ? this.props.location.state.selectedClosedCollectionId : localStorage.getItem('selectedClosedCollectionId')}
          selectedClosedCollectionType={this.props.location.state && this.props.location.state.selectedClosedCollectionType ? this.props.location.state.selectedClosedCollectionType : localStorage.getItem('selectedClosedCollectionType')}
        />
        <ShowClosedCollectionContent
          org={this.props.match.params.org}
          selectedClosedCollectionId={this.props.location.state && this.props.location.state.selectedClosedCollectionId ? this.props.location.state.selectedClosedCollectionId : localStorage.getItem('selectedClosedCollectionId')}
        />
      </div>
    )
  }
}

ShowClosedCollection.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
}

export default ShowClosedCollection
