import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import SearchBar from './SearchBar'
import AddButton from './AddButton'
// import UploadCsvButton from './UploadCsvButton'
import DataTable from './DataTable'
import {
  countListTypeContentById,
  countSearchedTypeByOrgAndGroup,
  getListTypeContentByPage,
  searchTypeByGroupAndPage
} from '../utils/pouchdb'
import FadeLoader from '../components/FadeLoader'
import Pagination from './Pagination'

class DataContentRight extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      listTypeContent: null,
      listTypeContentNumber: null,
      allListTypeContentNumber: null,
      groupId: props.groupId,
      groupName: props.groupName,
      searchKey: null,
      selectedPage: 1,
      selectedPageSize: 10
    }
  }

  async componentDidMount () {
    this._isMounted = true
    var listTypeContent = await getListTypeContentByPage(this.state.org, this.state.groupId, this.state.selectedPage, this.state.selectedPageSize)
    var listTypeContentNumber = await countListTypeContentById(this.state.org, this.state.groupId)
    if (this._isMounted && listTypeContent && countListTypeContentById) {
      this.setState({
        allListTypeContentNumber: listTypeContentNumber,
        listTypeContent: listTypeContent.docs,
        listTypeContentNumber
      })
    }
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.groupId !== this.props.groupId || nextProps.org !== this.props.org) {
      this.setState({
        org: nextProps.org,
        listTypeContent: null,
        groupId: nextProps.groupId,
        groupName: nextProps.groupName,
        selectedPage: 1,
        selectedPageSize: 10
      })
      var listTypeContent = await getListTypeContentByPage(nextProps.org, nextProps.groupId, 1, 10)
      var listTypeContentNumber = await countListTypeContentById(nextProps.org, nextProps.groupId)
      if (listTypeContent) {
        this.setState({
          allListTypeContentNumber: listTypeContentNumber,
          listTypeContent: listTypeContent.docs,
          listTypeContentNumber
        })
      }
    }
  }

  async reloadComponent () {
    var listTypeContent = await getListTypeContentByPage(this.state.org, this.state.groupId, this.state.selectedPage, this.state.selectedPageSize)
    var listTypeContentNumber = await countListTypeContentById(this.state.org, this.state.groupId)
    if (listTypeContent && listTypeContentNumber) {
      this.setState({
        allListTypeContentNumber: listTypeContentNumber,
        listTypeContent: listTypeContent.docs,
        listTypeContentNumber
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  setPage (page) {
    const self = this
    var searchKey = self.state.searchKey
    self.setState({
      selectedPage: page
    })
    if (searchKey && searchKey !== '') {
      searchTypeByGroupAndPage(self.state.org, self.state.groupId, page, self.state.selectedPageSize, searchKey).then(function (listTypeContent) {
        self.setState({
          listTypeContent: listTypeContent.docs
        })
      })
    } else {
      getListTypeContentByPage(self.state.org, self.state.groupId, page, self.state.selectedPageSize).then(function (listTypeContent) {
        self.setState({
          listTypeContent: listTypeContent.docs
        })
      })
    }
  }

  setPageSize (pageSize, page) {
    const self = this
    var searchKey = self.state.searchKey
    self.setState({
      selectedPageSize: pageSize,
      selectedPage: page
    })
    if (searchKey && searchKey !== '') {
      searchTypeByGroupAndPage(self.state.org, self.state.groupId, page, pageSize, searchKey).then(function (listTypeContent) {
        self.setState({
          listTypeContent: listTypeContent.docs
        })
      })
    } else {
      getListTypeContentByPage(self.state.org, this.state.groupId, page, pageSize).then(function (listTypeContent) {
        self.setState({
          listTypeContent: listTypeContent.docs
        })
      })
    }
  }

  searchType (searchKey, pageSize) {
    const self = this
    self.setState({
      searchKey: searchKey
    })
    if (searchKey !== '') {
      countSearchedTypeByOrgAndGroup(self.state.org, self.state.groupId, searchKey).then(function (numberSearchedType) {
        self.setState({
          listTypeContentNumber: numberSearchedType
        })
        searchTypeByGroupAndPage(self.state.org, self.state.groupId, 1, pageSize, searchKey).then(function (type) {
          self.setState({
            selectedPage: 1,
            listTypeContent: type.docs
          })
        })
      })
    } else {
      countListTypeContentById(self.state.org, self.state.groupId).then(function (listTypeContentNumber) {
        self.setState({
          listTypeContentNumber
        })
        getListTypeContentByPage(self.state.org, self.state.groupId, 1, pageSize).then(function (listTypeContent) {
          self.setState({
            selectedPage: 1,
            listTypeContent: listTypeContent.docs
          })
        })
      })
    }
  }

  render () {
    if (!this.state.listTypeContent || !this.state.listTypeContentNumber) {
      return (
        <div className='right-standard-content'>
          <div style={styles.content}>
            <FadeLoader />
          </div>
        </div>
      )
    }
    return (
      <div className='right-standard-content'>
        <div style={styles.content}>
          <SearchBar
            getSearchKey={(key) => this.searchType(key, this.state.selectedPageSize)}
          />
          <div className='add-button-container'>
            <div style={styles.buttonContent}>
              <AddButton
                org={this.state.org}
                group={this.state.groupId}
                groupName={this.props.groupName}
                groupType='data'
              />
            </div>
            {/* <div style={styles.buttonContent}>
              <UploadCsvButton
                group={this.state.groupId}
              />
            </div> */}
          </div>
          <DataTable
            org={this.state.org}
            listTypeContent={this.state.listTypeContent}
            groupId={this.state.groupId}
            groupName={this.state.groupName}
            listTypeContentLength={this.state.allListTypeContentNumber}
            reloadComponent={() => this.reloadComponent()}
          />
          {this.state.listTypeContentNumber > 10
            ? (
              <Pagination
                dataLength={this.state.listTypeContentNumber}
                setPage={(page) => { this.setPage(page) }}
                setPageSize={(pageSize, page) => { this.setPageSize(pageSize, page) }}
                selectedPage={this.state.selectedPage}
                selectedPageSize={this.state.selectedPageSize}
              />
            )
            : null}
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    paddingTop: 16,
    height: 'calc(100% - 16px)',
    width: '100%',
    maxWidth: 766,
    minWidth: 360
  },
  buttonContent: {
    float: 'right'
  }
}

DataContentRight.propTypes = {
  groupId: PropTypes.string,
  groupName: PropTypes.string,
  org: PropTypes.string
}

export default DataContentRight
