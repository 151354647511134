import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import '../css/table.css'
import '../css/style.css'
import ascendIcon from '../images/ascendingIcon.svg'
import descendIcon from '../images/descendingIcon.svg'
import arrowDownIcon from '../images/arrowDownAccordionIcon.svg'
import arrowUpIcon from '../images/arrowUpAccordionIcon.svg'
import showIcon from '../images/showIcon.svg'
import csvIcon from '../images/csvIcon.svg'
import pdfIcon from '../images/pdfIcon.svg'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { exportCsv, exportPdf } from '../utils/exporter'
import { getSortFields } from '../utils/pouchdb'
import { Logger } from "react-remote-logger"
class ClosedTableByCollection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      index: null,
      org: props.org,
      closedCollection: props.closedCollection,
      valueKey: '',
      isSorted: false,
      type: null,
      selectedClosedCollectionId: null,
      selectedClosedCollectionType: null,
      sortableFields: null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.closedCollection !== this.props.closedCollection) {
      this.setState({
        org: nextProps.org,
        closedCollection: nextProps.closedCollection
      })
    }
  }

  idIsInList (collections) {
    var self = this
    var isInList = true
    _.forEach(collections, function (collection) {
      if (self.props.idList.includes(collection._id)) {
        isInList = true && isInList
      } else {
        isInList = false && isInList
      }
    })
    return isInList
  }

  findPath (ob, key) {
    const path = [];
    const keyExists = (obj) => {
      if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
        return false;
      }
      else if (obj.hasOwnProperty(key)) {
        return true;
      }
      else if (Array.isArray(obj)) {
        let parentKey = path.length ? path.pop() : "";
  
        for (let i = 0; i < obj.length; i++) {
          path.push(`${parentKey}[${i}]`);
          const result = keyExists(obj[i], key);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
      else {
        for (const k in obj) {
          path.push(k);
          const result = keyExists(obj[k], key);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
      return false;
    };
  
    keyExists(ob);
    path.push(key)
  
    return path.join(".");
  }

  getSortableFieldValue(collection, key) {
    const value = _.get(collection, this.findPath(collection, key))
    return typeof(value) == 'string' ? value.replace(';', ' ') : value
  }

  sort(collections, key, order) {
    const self = this
    const path = this.findPath(collections, key).split(".")
    path.shift()
    collections = _.map(collections, function(c) {
      _.update(c, path.join("."), function(n) { return self.getSortableFieldValue(c, key) });
      return c
    })
    return _.orderBy(collections, [path.join("."), 'startAt'], [order, 'desc'])
  }

  sortByColumn (key, collections) {
    const columnState = !this.state[key]
    this.setState({
      type: collections[0].type,
      isSorted: true,
      [key]: columnState,
      collections: columnState ? this.sort(collections, key, 'asc') : this.sort(collections, key, 'desc'),
      valueKey: key
    })
  }

  showCollection (id, type) {
    Logger({
      'timestamp': (new Date()).toLocaleString(),
      'level': 'debug',
      'user_id': localStorage.user_id,
      'user': localStorage.user_email,
      'organization': localStorage.organization,
      'action': 'GET',
      'collection': id,
      'url': window.location.href,
      'message': `GET ${window.location.href}`
    })
    this.setState({
      selectedClosedCollectionId: id,
      selectedClosedCollectionType: type
    })
  }

  renderTable (collections) {
    const { t } = this.props
    if (!this.state.isSorted) {
      collections = this.sort(collections, 'startAt', 'desc')
    } else {
      if (this.state.index === this.state.type && (!this.props.searchKey || this.props.searchKey == '')) {
        collections = this.state.collections
      }
    }
    if (!this.state.sortableFields) {
      return null
    }
    return (
      <div>
        <table cellSpacing='0' className='table-collapsed'>
          <thead>
            <tr className='head'>
              <td className='head-border'>
                <Checkbox
                  onChange={(e) => this.props.handleChange(e, collections)}
                  color='default'
                  value='all'
                  checked={this.props.idList.length >= collections.length && this.idIsInList(collections)}
                />
              </td>
              <td
                className='td-left-padding head-center head-border td-outline'
                role='gridcell'
                onClick={
                  () => !this.props.searchKey ? this.sortByColumn('user', collections) : {}
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <div style={{ maxWidth: 172 }}>
                  <span>{t('speakers').toUpperCase()}</span>
                  {
                    this.state.valueKey === 'user'
                      ? (this.state.user === true
                        ? <img
                          style={styles.icon}
                          src={ascendIcon}
                          alt='true'
                        />
                        : <img
                          style={styles.icon}
                          src={descendIcon}
                          alt='true'
                        />)
                      : <img
                        style={styles.icon}
                        src={descendIcon}
                        alt='true'
                      />
                  }
                </div>
              </td>
              {this.state.sortableFields.map((field) => 
                <td key={field.key}
                 className='td-left-padding head-center head-border td-outline'
                 role='gridcell'
                 onClick={
                   () => !this.props.searchKey ? this.sortByColumn(field.key, collections) : {}
                 }
                 tabIndex={0}
                 onKeyPress={() => {}}
               >
                 <span>{field.name}</span>
                 {
                   this.state.valueKey === field.key
                     ? (this.state[field.key] === true
                       ? <img
                         style={styles.iconRigth}
                         src={ascendIcon}
                         alt='true'
                       />
                       : <img
                         style={styles.iconRigth}
                         src={descendIcon}
                         alt='true'
                       />)
                     : <img
                       style={styles.iconRigth}
                       src={descendIcon}
                       alt='true'
                     />
                 }
               </td>
              )}
             
              <td
                className='td-head-right-collapsed head-border td-outline'
                role='gridcell'
                onClick={
                  () => !this.props.searchKey ? this.sortByColumn('startAt', collections) : {}
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <span>{t('creations').toUpperCase()}</span>
                {
                  this.state.valueKey === 'startAt'
                    ? (this.state.startAt === true
                      ? <img
                        style={styles.iconRigth}
                        src={ascendIcon}
                        alt='true'
                      />
                      : <img
                        style={styles.iconRigth}
                        src={descendIcon}
                        alt='true'
                      />)
                    : <img
                      style={styles.iconRigth}
                      src={descendIcon}
                      alt='true'
                    />
                }
              </td>
            </tr>
          </thead>
          <tbody>
            {collections.map((collection) =>
              <tr
                key={collection._id}
                className='tr'
              >
                <td className='td'>
                  <Checkbox
                    onChange={(e) => this.props.handleChange(e)}
                    color='default'
                    value={collection._id}
                    checked={this.props.idList.includes(collection._id)}
                  />
                </td>
               <td
                  className='td td-left-padding td-center'
                >
                  {collection.user}
                </td>
                {this.state.sortableFields.map((field) => 
                  <td key={field.key}
                    className='td td-left-padding td-center'
                  >
                    {this.getSortableFieldValue(collection, field.key)}
                  </td>
                )}

              
                {/* <td
                  className='td td-right-collapsed clickable-td'
                  onClick={() => this.showCollection(collection._id)}
                  tabIndex='0'
                  role='gridcell'
                  onKeyPress={() => {}}
                >
                  {moment.utc(collection.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}
                </td> */}
                <td className='td td-right-standard hover-button'>
                  <div style={styles.contentButton}>
                    <span className='hover-button--off'>
                      {moment.utc(collection.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}
                    </span>
                    <div className='hover-button--on'>
                      <table cellSpacing='0' style={styles.table}>
                        <tbody>
                          <tr>
                            <td style={styles.iconContent}>
                              <div
                                style={{ outline: 'none' }}
                                tabIndex='0'
                                onClick={() => this.showCollection(collection._id, collection.type)}
                                role='button'
                                onKeyPress={() => {}}
                              >
                                <img
                                  style={styles.iconHover}
                                  src={showIcon}
                                  alt='true'
                                />
                              </div>
                            </td>
                            <td style={styles.iconContent}>
                              <div
                                style={{ outline: 'none' }}
                                tabIndex='0'
                                onClick={() => exportPdf(this.state.org, collection._id, collection.type.toLowerCase())}
                                role='button'
                                onKeyPress={() => {}}
                              >
                                <img
                                  src={pdfIcon}
                                  style={styles.iconHover}
                                  alt='true'
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  onClickHandler (index) {
    const self = this
    getSortFields(this.state.org, index)
    .then(function(res) {
      self.setState({
        sortableFields: res
      })
    })
    if (this.state.index === index) {
      this.setState({
        index: null
      })
    } else {
      this.setState({
        index: index
      })
    }
  }

  getArrowIcon (index) {
    if (this.state.index === index) {
      return (
        <div
          style={styles.arrowImgContent}
          tabIndex={index}
          onClick={() => { this.onClickHandler(index) }}
          onKeyPress={() => {}}
          role='button'
        >
          <img
            style={styles.iconRigth}
            src={arrowUpIcon}
            alt='true'
          />
        </div>
      )
    }
    return (
      <div
        style={styles.arrowImgContent}
        tabIndex={index}
        onClick={() => { this.onClickHandler(index) }}
        onKeyPress={() => {}}
        role='button'
      >
        <img
          style={styles.iconRigth}
          src={arrowDownIcon}
          alt='true'
        />
      </div>
    )
  }

  searchToSortfields(collection, sortableFields, searchKey) {
    if (sortableFields) {
      const self = this
      let res = false
      _.forEach(sortableFields, function(field) {
        const sortableField = self.getSortableFieldValue(collection, field.key)
        const value = typeof sortableField == 'string' ? self.getSortableFieldValue(collection, field.key).toLowerCase() : self.getSortableFieldValue(collection, field.key)
        res = res || typeof value == 'string' ? value.includes(searchKey.toLowerCase()) : false
      })
      return res
    }
  }

  render () {
    const { t } = this.props
    var searchKey = this.props.searchKey
    var closedCollection = this.state.closedCollection
    var selectedClosedCollectionId = this.state.selectedClosedCollectionId
    var selectedClosedCollectionType = this.state.selectedClosedCollectionType
    if (selectedClosedCollectionId) {
      localStorage.setItem('organization', this.state.org)
      localStorage.setItem('selectedClosedCollectionId', selectedClosedCollectionId)
      localStorage.setItem('selectedClosedCollectionType', selectedClosedCollectionType)
      return <Redirect
        to={{
          pathname: `/${this.state.org}/closed-collection/${selectedClosedCollectionId}`,
          state: {
            selectedClosedCollectionId: selectedClosedCollectionId,
            selectedClosedCollectionType: selectedClosedCollectionType
          }
        }}
      />
    }
    if (searchKey && searchKey != '') {
      if (this.state.index) {
        const self = this
        const collectionType = _.find(closedCollection, function(o) { return o.type == self.state.index })
        let collections = collectionType.collections
        const docs = _.filter(collections, function (o) { 
          return self.searchToSortfields(o, self.state.sortableFields, searchKey) || t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) || o.user.toLowerCase().includes(searchKey.toLowerCase()) || moment.utc(o.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm').includes(searchKey)
        })
        collectionType['collections'] = this.sort(docs, 'startAt', 'desc')
        var index = _.findIndex(closedCollection, function(o) { return o.type == self.state.index })
        closedCollection.splice(index, 1, collectionType)
      } else closedCollection = _.filter(closedCollection, function (o) { return t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) })
    }
    return (
      <div className='content-table-collapsed'>
        {closedCollection.map((collection) =>
          <div key={collection.type}>
            <div
              className='container-with-padding'
              // tabIndex={collection.type}
              style={styles.collapsedContent}
              // onClick={() => { this.onClickHandler(collection.type) }}
              // onKeyPress={() => {}}
              // role='button'
            >
              <div style={styles.rigthContent}>
                <div
                  style={styles.csvIconContent}
                  tabIndex={collection.type}
                  onClick={() => exportCsv(this.state.org, collection.type.toLowerCase())}
                  onKeyPress={() => {}}
                  role='button'
                >
                  <img
                    src={csvIcon}
                    alt='true'
                  />
                </div>
                <span style={styles.number}>{collection.collections.length}</span>
                {this.getArrowIcon(collection.type)}
              </div>
              <div style={styles.labelContent}>
                <span>
                  {t(`doctype.${collection.type}.name`, collection.type)}
                </span>
              </div>
            </div>
            {this.state.index === collection.type ? this.renderTable(collection.collections) : null}
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  collapsedContent: {
    paddingTop: 10,
    width: '100%',
    height: 30,
    borderBottom: '2px solid #EEEEEE'
  },
  arrowImgContent: {
    paddingTop: 10,
    width: '100%',
    height: 30,
    outline: 'none',
    cursor: 'pointer'
  },
  csvIconContent: {
    outline: 'none',
    cursor: 'pointer'
  },
  labelContent: {
    float: 'left',
    // width: 'calc(100% - 42px)',
    color: '#272D31',
    fontWeight: 700,
    fontSize: 14,
    width: 'calc(100% - 92px)'
  },
  number: {
    marginTop: 7,
    marginLeft: 12
  },
  rigthContent: {
    display: 'flex',
    float: 'right',
    // width: 42,
    color: '#272D31',
    fontWeight: 700,
    fontSize: 14,
    width: 92,
    height: 30,
    marginTop: -7
  },
  icon: {
    float: 'right',
    marginTop: 6,
    marginRight: 4,
    marginLeft: 12
  },
  iconRigth: {
    float: 'right',
    // marginTop: 6,
    marginLeft: 12,
    marginTop: 2
  },
  contentButton: {
    width: 120,
    float: 'right'
  },
  table: {
    float: 'right'
  },
  iconContent: {
    paddingLeft: 6
  },
  iconHover: {
    cursor: 'pointer'
  }
}

ClosedTableByCollection.propTypes = {
  closedCollection: PropTypes.array,
  idList: PropTypes.array,
  handleChange: PropTypes.func,
  searchKey: PropTypes.string,
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(ClosedTableByCollection))
