import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import Select from './Select'
import deleteIcon from '../images/deleteIcon.svg'
// import csvIcon from '../images/csvIcon.svg'
// import pdfIcon from '../images/pdfIcon.svg'
// import multiplePdfIcon from '../images/multiplePdfIcon.svg'
import {
  removeDocument
} from '../utils/pouchdb'
import Modal from 'react-responsive-modal'
import async from 'async'
import { withTranslation } from 'react-i18next'
import { Logger } from "react-remote-logger"

class ClosedCollectionContentHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      org: localStorage.getItem('organization'),
      openModal: false,
      options: this.props.options,
      selectedOption: this.props.selectedOption
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({
        options: nextProps.options,
        selectedOption: nextProps.selectedOption
      })
    }
  }

  onOpenModal () {
    this.setState({
      openModal: true
    })
  }

  onCloseModal () {
    var self = this
    self.setState({ openModal: false })
  }

  // getPdfIcon () {
  //   var idList = this.props.idList
  //   if (idList.length > 0 && idList.length === 1) {
  //     return pdfIcon
  //   }
  //   return multiplePdfIcon
  // }

  deleteCollection (idList) {
    var self = this
    var org = self.state.org
    async.eachLimit(idList, 1, function (id, callback) {
      removeDocument(org, id).then(function (result) {
        callback()
      }).catch(function (err) {
        console.log(err)
        callback()
      })
    }, function (err) {
      if (err) {
        console.log(err)
      } else {
        Logger({
          'timestamp': (new Date()).toLocaleString(),
          'level': 'debug',
          'user_id': localStorage.user_id,
          'user': localStorage.user_email,
          'organization': localStorage.organization,
          'action': 'DELETE',
          'collections': idList,
          'url': window.location.href,
          'message': `DELETE ${window.location.href}`
        })
        console.log('Documents deleted Successfully')
        self.onCloseModal()
        window.location.reload()
      }
    })
  }

  uploadCsv (idList) {
    console.log("UPLOAD CSV: ", idList)
  }

  uploadPdf (idList) {
    console.log("UPLOAD PDF: ", idList)
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <div style={styles.content}>
          <div className='closed-number'>
            <span>{t('closed')}: {this.props.closedCollectionLength}</span>
          </div>
          <table cellSpacing='0'>
            <tbody>
              <tr>
                <td style={styles.selectContent}>
                  <Select
                    handleChangeFilter={(selected) => this.props.handleChangeFilter(selected)}
                    selectedOption={this.state.selectedOption}
                    options={this.state.options}
                  />
                </td>
                {
                  this.props.idList.length > 0
                    ? <td style={styles.iconContent}>
                      <div
                        style={{ outline: 'none' }}
                        tabIndex='0'
                        onClick={() => this.onOpenModal()}
                        role='button'
                        onKeyPress={() => {}}
                      >
                        <img
                          style={styles.icon}
                          src={deleteIcon}
                          alt='true'
                        />
                      </div>
                    </td>
                    : null
                }
                {/* {
                  this.props.idList.length > 0
                    ? <td style={styles.iconContent}>
                      <div
                        style={{ outline: 'none' }}
                        tabIndex='0'
                        onClick={() => this.uploadCsv(this.props.idList)}
                        role='button'
                        onKeyPress={() => {}}
                      >
                        <img
                          src={csvIcon}
                          style={styles.icon}
                          alt='true'
                        />
                      </div>
                    </td>
                    : null
                }
                {
                  this.props.idList.length > 0
                    ? <td style={styles.iconContent}>
                      <div
                        style={{ outline: 'none' }}
                        tabIndex='0'
                        onClick={() => this.uploadPdf(this.props.idList)}
                        role='button'
                        onKeyPress={() => {}}
                      >
                        <img
                          src={this.getPdfIcon()}
                          style={styles.icon}
                          alt='true'
                        />
                      </div>
                    </td>
                    : null
                } */}
              </tr>
            </tbody>
          </table>
          <div>
            <Modal
              styles={bg}
              open={this.state.openModal}
              onClose={() => this.onCloseModal()}
              showCloseIcon={false}
              center
              focusTrapped={false}
            >
              <h2 style={styles.modalTitle}>{t('removal_collections')} </h2>
              <p style={styles.modalText}>
                {this.props.idList.length > 1 ? t('remove_selected_collections') : t('remove_selected_collection')}
              </p>
              <div>
                <button style={styles.modalButtonLeft} onClick={() => this.onCloseModal()}><span>{t('cancel')}</span></button>
                <button style={styles.modalButtonRight} onClick={() => this.deleteCollection(this.props.idList)}>{t('delete')}</button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const bg = {
  modal: {
    background: '#EBEBEB',
    borderRadius: '6px',
    color: '#272D31'
  }
}

const styles = {
  content: {
    paddingTop: 24,
    height: '100%',
    width: '100%'
  },
  selectContent: {
    paddingRight: 10
  },
  iconContent: {
    paddingTop: 6,
    paddingLeft: 6
  },
  icon: {
    cursor: 'pointer'
  },
  modalTitle: {
    fontSize: 14,
    marginTop: '-10px',
    textAlign: 'center'
  },
  modalText: {
    fontSize: '12px'
  },
  modalButtonLeft: {
    float: 'left',
    width: '100px',
    borderWidth: '0px',
    height: '24px',
    borderRadius: '3px',
    outline: 'none'
  },
  modalButtonRight: {
    backgroundColor: '#DA262E',
    borderWidth: '0px',
    float: 'right',
    width: '100px',
    height: '24px',
    borderRadius: '3px',
    outline: 'none'
  }
}

ClosedCollectionContentHeader.propTypes = {
  handleChangeFilter: PropTypes.func,
  selectedOption: PropTypes.object,
  options: PropTypes.array,
  idList: PropTypes.array,
  closedCollectionLength: PropTypes.number,
  t: PropTypes.func
}

export default (withTranslation()(ClosedCollectionContentHeader))
