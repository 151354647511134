import React from 'react'

class Error extends React.Component {
  render () {
    return (
      <div style={styles.content}>
        Url incorrect
      </div>
    )
  }
}

const styles = {
  content: {
    width: '100%',
    height: '420px',
    lineHeight: '420px',
    textAlign: 'center',
    fontSize: '24px',
    color: 'red'
  }
}

export default Error
