import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const styles = theme => ({
  root: {
    width: '100%'
  },
  input: {
    color: 'rgba(0,0,0,0.60)',
    fontSize: 15.8,
    borderColor: 'red'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: ' #BFBFBF !important'
  }
})

class PasswordComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showPassword: false
    }
  }

  handleClickShowPassword () {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword (event) {
    event.preventDefault()
  }

  render () {
    const { classes } = this.props
    if (this.props.hasError && this.props.value === '') {
      return (
        <div>
          <TextField
            error
            label={this.props.label}
            value={this.props.value}
            className={classes.root}
            onChange={(event) => this.props.onChange(event.target.value)}
            margin='normal'
            type={this.state.showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => this.handleClickShowPassword()}
                    onMouseDown={(e) => this.handleMouseDownPassword(e)}
                    edge='end'
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              style: { color: '#F44335', fontSize: 15.8, fontWeight: 400, fontStyle: 'normal' }
            }}
            variant='outlined'
          />
        </div>
      )
    } else {
      return (
        <div>
          <TextField
            label={this.props.label}
            value={this.props.value}
            className={classes.root}
            onChange={(event) => this.props.onChange(event.target.value)}
            margin='normal'
            type={this.state.showPassword ? 'text' : 'password'}
            InputProps={{
              classes: this.props.classes,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => this.handleClickShowPassword()}
                    onMouseDown={(e) => this.handleMouseDownPassword(e)}
                    edge='end'
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              style: { color: 'rgba(0,0,0,0.60)', fontSize: 15.8, fontWeight: 400, fontStyle: 'normal' }
            }}
            variant='outlined'
          />
        </div>
      )
    }
  }
}

PasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  hasError: PropTypes.bool
}

export default withStyles(styles)(PasswordComponent)
