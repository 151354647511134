import React from 'react'
import PropTypes from 'prop-types'
import addIcon from '../images/addIcon.svg'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class AddButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      org: props.org,
      addIsClicked: false
    }
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.org !== this.props.org) {
      this.setState({
        org: nextProps.org
      })
    }
  }

  saveGroupName (groupType) {
    if (groupType === 'user') {
      localStorage.setItem('userGroupId', this.props.group)
    }
    if (groupType === 'data') {
      localStorage.setItem('dataGroupId', this.props.group)
    }
  }

  render () {
    const { t } = this.props
    if (this.state.addIsClicked) {
      this.saveGroupName(this.props.groupType)
      localStorage.setItem('organization', this.state.org)
      return <Redirect
        to={{
          pathname: `/${this.state.org}/add-${this.props.groupType}`,
          state: {
            groupId: this.props.group,
            groupName: this.props.groupName,
            groupType: this.props.groupType
          }
        }}
      />
    }
    return (
      <div>
        <div
          style={styles.content}
          tabIndex='0'
          onClick={() => this.setState({ addIsClicked: true })}
          role='button'
          onKeyPress={() => {}}
        >
          <span style={styles.label}>{t('new_text_add')}</span>
          <img
            src={addIcon}
            alt=''
            style={styles.icon}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    paddingTop: 5,
    textAlign: 'left',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E02020',
    borderRadius: 18,
    width: 128,
    height: 25,
    outline: 'none',
    cursor: 'pointer'
  },
  label: {
    marginLeft: 24,
    color: '#DA262E',
    fontWeight: 400,
    fontSize: 16
  },
  icon: {
    marginRight: 14,
    float: 'right'
  }
}

AddButton.propTypes = {
  group: PropTypes.string,
  groupName: PropTypes.string,
  groupType: PropTypes.string,
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(AddButton))
