import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import '../css/table.css'
import '../css/style.css'
import ascendIcon from '../images/ascendingIcon.svg'
import descendIcon from '../images/descendingIcon.svg'
import arrowDownIcon from '../images/arrowDownAccordionIcon.svg'
import arrowUpIcon from '../images/arrowUpAccordionIcon.svg'
import showIcon from '../images/showIcon.svg'
import pdfIcon from '../images/pdfIcon.svg'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { exportPdf } from '../utils/exporter'
import { Logger } from "react-remote-logger"

class ClosedTableByUser extends Component {
  constructor (props) {
    super(props)
    this.state = {
      index: null,
      org: props.org,
      closedCollection: props.closedCollection,
      valueKey: '',
      isSorted: false,
      user: null,
      selectedClosedCollectionId: null,
      selectedClosedCollectionType: null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.closedCollection !== this.props.closedCollection) {
      this.setState({
        org: nextProps.org,
        closedCollection: nextProps.closedCollection
      })
    }
  }

  idIsInList (collections) {
    var self = this
    var isInList = true
    _.forEach(collections, function (collection) {
      if (self.props.idList.includes(collection._id)) {
        isInList = true && isInList
      } else {
        isInList = false && isInList
      }
    })
    return isInList
  }

  sort(collections, key, order) {
    return _.orderBy(collections, [key, 'startAt'], [order, 'desc'])
  }

  sortByColumn (key, collections) {
    const { t } = this.props
    const columnState = !this.state[key]
    this.setState({
      user: collections[0].user,
      isSorted: true,
      [key]: columnState,
      collections: columnState ? this.sort(collections, key, 'asc') : this.sort(collections, key, 'desc'),
      valueKey: key
    })
  }

  showCollection (id, type) {
    Logger({
      'timestamp': (new Date()).toLocaleString(),
      'level': 'debug',
      'user_id': localStorage.user_id,
      'user': localStorage.user_email,
      'organization': localStorage.organization,
      'action': 'GET',
      'collection': id,
      'url': window.location.href,
      'message': `GET ${window.location.href}`
    })
    this.setState({
      selectedClosedCollectionId: id,
      selectedClosedCollectionType: type
    })
  }

  renderTable (collections) {
    const { t } = this.props
    if (!this.state.isSorted) {
      collections = this.sort(collections, 'startAt', 'desc')
    } else {
      if (this.state.index === this.state.user && (!this.props.searchKey || this.props.searchKey == '')) {
        collections = this.state.collections
      }
    }
    return (
      <div>
        <table cellSpacing='0' className='table-collapsed'>
          <thead>
            <tr className='head'>
              <td className='head-border'>
                <Checkbox
                  onChange={(e) => this.props.handleChange(e, collections)}
                  color='default'
                  value='all'
                  checked={this.props.idList.length >= collections.length && this.idIsInList(collections)}
                />
              </td>
              <td
                className='td-left-padding head-center head-border td-outline'
                role='gridcell'
                onClick={
                  () => !this.props.searchKey ? this.sortByColumn('type', collections) : {}
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <div style={{ maxWidth: 172 }}>
                  <span>{t('collections').toUpperCase()}</span>
                  {
                    this.state.valueKey === 'type'
                      ? (this.state.type === true
                        ? <img
                          style={styles.icon}
                          src={ascendIcon}
                          alt='true'
                        />
                        : <img
                          style={styles.icon}
                          src={descendIcon}
                          alt='true'
                        />)
                      : <img
                        style={styles.icon}
                        src={descendIcon}
                        alt='true'
                      />
                  }
                </div>
              </td>
              <td
                className='td-head-right-collapsed head-border td-outline'
                role='gridcell'
                onClick={
                  () => !this.props.searchKey ? this.sortByColumn('startAt', collections) : {}
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <span>{t('creations').toUpperCase()}</span>
                {
                  this.state.valueKey === 'startAt'
                    ? (this.state.startAt === true
                      ? <img
                        style={styles.iconRigth}
                        src={ascendIcon}
                        alt='true'
                      />
                      : <img
                        style={styles.iconRigth}
                        src={descendIcon}
                        alt='true'
                      />)
                    : <img
                      style={styles.iconRigth}
                      src={descendIcon}
                      alt='true'
                    />
                }
              </td>
            </tr>
          </thead>
          <tbody>
            {collections.map((collection) =>
              <tr
                key={collection._id}
                className='tr'
              >
                <td className='td'>
                  <Checkbox
                    onChange={(e) => this.props.handleChange(e)}
                    color='default'
                    value={collection._id}
                    checked={this.props.idList.includes(collection._id)}
                  />
                </td>
                <td
                  className='td td-left-padding td-center'
                >
                  {t(`doctype.${collection.type}.name`, collection.type)}
                </td>
                {/* <td
                  className='td td-right-collapsed clickable-td'
                  onClick={() => this.showCollection(collection._id)}
                  tabIndex='0'
                  role='gridcell'
                  onKeyPress={() => {}}
                >
                  {moment.utc(collection.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}
                </td> */}
                <td className='td td-right-standard hover-button'>
                  <div style={styles.contentButton}>
                    <span className='hover-button--off'>
                      {moment.utc(collection.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}
                    </span>
                    <div className='hover-button--on'>
                      <table cellSpacing='0' style={styles.table}>
                        <tbody>
                          <tr>
                            <td style={styles.iconContent}>
                              <div
                                style={{ outline: 'none' }}
                                tabIndex='0'
                                onClick={() => this.showCollection(collection._id, collection.type)}
                                role='button'
                                onKeyPress={() => {}}
                              >
                                <img
                                  style={styles.iconHover}
                                  src={showIcon}
                                  alt='true'
                                />
                              </div>
                            </td>
                            <td style={styles.iconContent}>
                              <div
                                style={{ outline: 'none' }}
                                tabIndex='0'
                                onClick={() => exportPdf(this.state.org, collection._id, collection.type.toLowerCase())}
                                role='button'
                                onKeyPress={() => {}}
                              >
                                <img
                                  src={pdfIcon}
                                  style={styles.iconHover}
                                  alt='true'
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  onClickHandler (index) {
    if (this.state.index === index) {
      this.setState({
        index: null
      })
    } else {
      this.setState({
        index: index
      })
    }
  }

  getArrowIcon (index) {
    if (this.state.index === index) {
      return (
        <img
          style={styles.iconRigth}
          src={arrowUpIcon}
          alt='true'
        />
      )
    }
    return (
      <img
        style={styles.iconRigth}
        src={arrowDownIcon}
        alt='true'
      />
    )
  }

  render () {
    const { t } = this.props
    var searchKey = this.props.searchKey
    var closedCollection = this.state.closedCollection
    var selectedClosedCollectionId = this.state.selectedClosedCollectionId
    var selectedClosedCollectionType = this.state.selectedClosedCollectionType
    if (selectedClosedCollectionId && selectedClosedCollectionType) {
      localStorage.setItem('organization', this.state.org)
      localStorage.setItem('selectedClosedCollectionId', selectedClosedCollectionId)
      localStorage.setItem('selectedClosedCollectionType', selectedClosedCollectionType)
      return <Redirect
        to={{
          pathname: `/${this.state.org}/closed-collection/${selectedClosedCollectionId}`,
          state: {
            selectedClosedCollectionId: selectedClosedCollectionId,
            selectedClosedCollectionType: selectedClosedCollectionType
          }
        }}
      />
    }
    if (searchKey && searchKey != '') {
      if (this.state.index) {
        const self = this
        const collectionType = _.find(closedCollection, function(o) { return o.user == self.state.index })
        let collections = collectionType.collections
        const docs = _.filter(collections, function (o) { 
          return t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) || moment.utc(o.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm').includes(searchKey)
        })
        collectionType['collections'] = this.sort(docs, 'startAt', 'desc')
        var index = _.findIndex(closedCollection, function(o) { return o.user == self.state.index })
        closedCollection.splice(index, 1, collectionType)
      } else closedCollection = _.filter(closedCollection, function (o) { return o.user.toLowerCase().includes(searchKey.toLowerCase()) })
    }
    return (
      <div className='content-table-collapsed'>
        {closedCollection.map((collection) =>
          <div key={collection.user}>
            <div
              className='container-with-padding clicked'
              tabIndex={collection.user}
              style={styles.collapsedContent}
              onClick={() => { this.onClickHandler(collection.user) }}
              onKeyPress={() => {}}
              role='button'
            >
              <div style={styles.rigthContent}>
                <span>{collection.collections.length}</span>
                {this.getArrowIcon(collection.user)}
              </div>
              <div style={styles.labelContent}>{collection.user}</div>
            </div>
            {this.state.index === collection.user ? this.renderTable(collection.collections) : null}
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  collapsedContent: {
    paddingTop: 10,
    width: '100%',
    height: 30,
    borderBottom: '2px solid #EEEEEE'
  },
  labelContent: {
    float: 'left',
    width: 'calc(100% - 52px)',
    color: '#272D31',
    fontWeight: 700,
    fontSize: 14
  },
  rigthContent: {
    float: 'right',
    width: 52,
    color: '#272D31',
    fontWeight: 700,
    fontSize: 14
  },
  icon: {
    float: 'right',
    marginTop: 6,
    marginRight: 4,
    marginLeft: 12
  },
  iconRigth: {
    float: 'right',
    marginTop: 6,
    marginLeft: 12
  },
  contentButton: {
    width: 120,
    float: 'right'
  },
  table: {
    float: 'right'
  },
  iconContent: {
    paddingLeft: 6
  },
  iconHover: {
    cursor: 'pointer'
  }
}

ClosedTableByUser.propTypes = {
  closedCollection: PropTypes.array,
  idList: PropTypes.array,
  handleChange: PropTypes.func,
  searchKey: PropTypes.string,
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(ClosedTableByUser))
