export default {
  couchdb_url: 'https://admin:fC8EU73d@forms.api.tag-ip.com',
  couchdb_roles: '_admin',
  couchdb_userName: 'admin',
  clientId: 'c8c0bac76593c9ccf0991e3d7eec2e0fbe2eec3ecd355151c1b3846f9c8f6350',
  clientSecret: '20ac8f08e97dc228544c69e654d41ff6ce77c2cf888ec39aa7bad8745f5fb254',
  redirect_uri: 'https://forms.tag-ip.com/',
  authRequest: 'https://auth.tag-ip.com/oauth/authorize',
  tokenRequest: 'https://auth.tag-ip.com/oauth/token?client_id=c8c0bac76593c9ccf0991e3d7eec2e0fbe2eec3ecd355151c1b3846f9c8f6350&client_secret=20ac8f08e97dc228544c69e654d41ff6ce77c2cf888ec39aa7bad8745f5fb254',
  graphql_api: 'https://home-legacy.tag-ip.com/api/graphiql',
  csvlink: 'https://csv.reporter.tag-ip.com',
  webdav_url: 'https://hive.tag-ip.com',
  salt_url: 'https://auth.tag-ip.com',
  api_key: 'lU7jMZt9Pi6CpsVd4h44JPeX'
}
