import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { AsYouType } from 'libphonenumber-js'

const styles = theme => ({
  root: {
    width: '100%'
  },
  input: {
    color: 'rgba(0,0,0,0.60)',
    fontSize: 15.8,
    borderColor: 'red'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: ' #BFBFBF !important'
  }
})

class PhoneFieldComponent extends React.Component {
  render () {
    const { classes } = this.props
    if (this.props.hasError && this.props.value === '') {
      return (
        <div>
          <TextField
            error
            label={this.props.label}
            className={classes.root}
            keyboardType='phone-pad'
            value={new AsYouType('MG').input(this.props.value)}
            // onBlur={() => locals.onChange(locals.value.replace(/\s/g, ''))}
            onChangeText={(value) => new AsYouType('MG').input(value)}
            onChange={(event) => this.props.onChange(new AsYouType('MG').input(event.target.value))}
            margin='normal'
            InputLabelProps={{
              style: { color: '#F44335', fontSize: 15.8, fontWeight: 400, fontStyle: 'normal' }
            }}
            variant='outlined'
          />
        </div>
      )
    } else {
      return (
        <div>
          <TextField
            label={this.props.label}
            value={new AsYouType('MG').input(this.props.value)}
            className={classes.root}
            keyboardType='phone-pad'
            onChangeText={(value) => new AsYouType('MG').input(value)}
            onChange={(event) => this.props.onChange(new AsYouType('MG').input(event.target.value))}
            margin='normal'
            InputProps={{
              classes: this.props.classes
            }}
            InputLabelProps={{
              style: { color: 'rgba(0,0,0,0.60)', fontSize: 15.8, fontWeight: 400, fontStyle: 'normal' }
            }}
            variant='outlined'
          />
        </div>
      )
    }
  }
}

PhoneFieldComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  hasError: PropTypes.bool
}

export default withStyles(styles)(PhoneFieldComponent)
