import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectInput from 'react-select'
import '../css/style.css'
import _ from 'lodash'
import {
  withTranslation
} from 'react-i18next'

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    padding: 2,
    fontSize: '14px',
    color: '#212121',
    fontWeight: 500
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#DA262E',
    border: 'none',
    backgroundColor: '#FFFFFF'
  }),
  control: (base, state) => ({
    ...base,
    background: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '14px',
    color: '#212121',
    fontWeight: 500,
    borderColor: 'transparent',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: 'transparent'
    }
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#DA262E' : null
  })
}

class Select extends Component {
  constructor (props) {
    super(props)
    this.state = {
      options: this.props.options,
      selectedOption: this.props.selectedOption
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({
        options: nextProps.options,
        selectedOption: this.getTranslateValue(nextProps.options, nextProps.selectedOption)
      })
    }
  }

  getTranslateValue (options, selectedOption) {
    var element = []
    _.forEach(options, function (option) {
      if (option.value === selectedOption.value) {
        element.push(option)
      }
    })
    return element[0]
  }

  render () {
    const self = this
    const { t } = self.props
    return (
      <div className='container-with-padding'>
        <div className='content-select'>
          <SelectInput
            styles={customStyles}
            placeholder={t('choose')}
            value={self.state.selectedOption}
            onChange={(selected) => self.props.handleChangeFilter(selected)}
            options={self.state.options}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  handleChangeFilter: PropTypes.func,
  selectedOption: PropTypes.object,
  options: PropTypes.array
}

export default (withTranslation()(Select))
