import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from '../i18n'
import { withTranslation } from 'react-i18next'
import { getDoctype } from '../utils/pouchdb'
import _ from 'lodash'
import Select from 'react-select'
import '../css/style.css'

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: '#FFFFFF',
    width: 102,
    cursor: 'pointer',
    fontSize: '13px',
    color: '#414141',
    fontWeight: 500,
    borderColor: 'transparent',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: '#f4f4f4'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: '13px',
    color: '#414141',
    fontWeight: 500,
    width: 100,
    '&:hover': {
      backgroundColor: '#f4f4f4'
    }
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#DA262E',
    border: 'none',
    backgroundColor: '#FFFFFF'
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#DA262E' : null
  })
}

const options = [
  { value: 'fr', label: 'Français' },
  { value: 'en', label: 'English' }
]

class ChooseLanguage extends Component {
  constructor () {
    super()
    this.state = {
      value: localStorage.i18nextLng,
      org: localStorage.getItem('organization'),
      selectedOption: this.getSelectedLanguage()
    }
  }

  getSelectedLanguage () {
    var value = localStorage.getItem('selectedLanguageValue')
    var label = localStorage.getItem('selectedLanguageLabel')
    if (value && label) {
      return (
        {
          value: value,
          label: label
        }
      )
    }
    return (
      {
        value: 'fr',
        label: 'Français'
      }
    )
  }

  async componentDidMount () {
    var languageId = localStorage.i18nextLng
    this.setState({ value: languageId })
    var translationType = await getDoctype(this.state.org)
    if (translationType.docs.length > 0) {
      var dataTranslate = translationType.docs[0]
      dataTranslate._id === 'en' ? _.merge(i18n.options.resources.en.translations, dataTranslate) : _.merge(i18n.options.resources.fr.translations, dataTranslate)
    }
  }

  handleChangeLanguage (selected) {
    localStorage.setItem('selectedLanguageValue', selected.value)
    localStorage.setItem('selectedLanguageLabel', selected.label)
    this.setState({ selectedOption: selected })
    this.props.i18n.changeLanguage(selected.value)
  }

  languageList () {
    const self = this
    return (
      <div className='content-select'>
        <Select
          styles={customStyles}
          placeholder='Choisir'
          components={{
            IndicatorSeparator: () => null
          }}
          value={this.state.selectedOption}
          onChange={(selected) => self.handleChangeLanguage(selected)}
          options={options}
        />
      </div>
    )
  }

  render () {
    return (
      <label>
        {this.languageList()}
      </label>
    )
  }
}

ChooseLanguage.propTypes = {
  i18n: PropTypes.object
}

export default (withTranslation()(ChooseLanguage))
