import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../App.css'
import '../css/style.css'
import t from 'tcomb-form'
import transform from 'tcomb-json-schema'
import AddHeader from '../components/AddHeader'
import { getTemplates } from '../components/Templates/templates'
import { Redirect } from 'react-router-dom'
import {
  addType,
  updateType,
  getDocumentById,
  findListTypeById,
  getListTypeContent
} from '../utils/pouchdb'
import _ from 'lodash'
import async from 'async'
import i18n from '../i18n'
import { Logger } from "react-remote-logger"

const templates = getTemplates()
const Form = t.form.Form

class AddData extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      value: null,
      reference: 'TYPE',
      org: localStorage.getItem('organization'),
      disabled: false,
      addIsClicked: false,
      optionList: null,
      dataGroupName: props.location.state && props.location.state.groupName && props.location.state.groupType === 'data' ? props.location.state.groupName : localStorage.getItem('dataGroupId'),
      dataGroupId: props.location.state && props.location.state.groupId && props.location.state.groupType === 'data' ? props.location.state.groupId : localStorage.getItem('dataGroupId'),
      editedTypeId: this.props.location.state && this.props.location.state.editedTypeId ? this.props.location.state.editedTypeId : null,
      options: {
        fields: {
          name: {
            label: i18n.t('label'),
            template: templates.text
          }
        }
      },
      data: {
        type: 'object',
        properties: {
          name: {
            type: 'string'
          }
        },
        required: [
          'name'
        ]
      }
    }
  }

  async componentDidMount () {
    this._isMounted = true
    if (this._isMounted) {
      var type = await findListTypeById(this.state.org, this.state.dataGroupId)
      if (type) {
        var documentType = this.getReferenceProperties(type.docs[0])
        documentType = documentType.length > 0 ? documentType[0] : null
        if (documentType) {
          var listTypeContent = await getListTypeContent(this.state.org, documentType)
          this.getOptionList(listTypeContent.docs, documentType)
        }
        if (this.state.editedTypeId) {
          var dataOfForm = await getDocumentById(this.state.org, this.state.editedTypeId)
          this.setState({
            value: dataOfForm.docs[0]
          })
        }
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getOptionList (listType, documentType) {
    const self = this
    var options = self.state.options
    var data = self.state.data
    var optionList = []
    async.eachLimit(listType, 1, function (type, callback) {
      optionList.push(type.name)
      callback()
    }, function (err) {
      if (err) {
        console.log(err)
      } else {
        options.fields[documentType] = {}
        data.properties[documentType] = {}
        options.fields[documentType].label = 'Reference'
        options.fields[documentType].template = templates.selectField
        data.properties[documentType].type = 'string'
        data.properties[documentType].enum = optionList
        data.required.push(documentType)
        self.setState({
          optionList: optionList,
          options: options,
          data: data
        })
      }
    })
  }

  getReferenceProperties (elem) {
    const keys = _.filter(Object.keys(elem), function (key) {
      return key !== '_id' && key !== '_rev' && key !== 'type' && key !== 'usage' && key !== 'name'
    })
    return keys
  }

  onSubmit (evt) {
    const self = this
    evt.preventDefault()
    var dataGroupId = self.state.dataGroupId
    const value = self.refs[self.state.reference].getValue()
    if (value) {
      const newValue = Object.assign({}, value)
      self.setState({
        disabled: true
      })
      const attributes = Object.assign(
        {
          type: dataGroupId,
          usage: 'referenceList'
        },
        newValue)
      if (self.state.editedTypeId) {
        updateType(self.state.org, self.state.editedTypeId, attributes).then(function (result) {
          console.log(result)
          Logger({
            'timestamp': (new Date()).toLocaleString(),
            'level': 'debug',
            'user_id': localStorage.user_id,
            'user': localStorage.user_email,
            'organization': localStorage.organization,
            'action': 'PUT',
            'id': self.state.editedTypeId,
            'params': attributes,
            'url': window.location.href,
            'message': `PUT ${window.location.href}`
          })
          self.setState({
            addIsClicked: true
          })
        })
      } else {
        addType(self.state.org, attributes).then(function (result) {
          console.log(result)
          Logger({
            'timestamp': (new Date()).toLocaleString(),
            'level': 'debug',
            'user_id': localStorage.user_id,
            'user': localStorage.user_email,
            'organization': localStorage.organization,
            'action': 'POST',
            'params': attributes,
            'url': window.location.href,
            'message': `POST ${window.location.href}`
          })
          self.setState({
            addIsClicked: true
          })
        })
      }
    }
  }

  onChange (value) {
    console.log("Value: ",value)
    this.setState({
      value: value
    })
  }

  render () {
    var organization = this.props.match.params.org
    if (this.state.org !== organization) {
      document.location.href = '/#/error'
      return null
    }
    if (this.state.addIsClicked) {
      return <Redirect
        to={{
          pathname: `/${this.state.org}/datas`,
          state: {
            dataGroupId: this.state.dataGroupId,
            dataGroupName: this.state.dataGroupName
          }
        }}
      />
    }
    return (
      <div className='App'>
        <form
          onSubmit={(e) => this.onSubmit(e)}
        >
          <AddHeader
            type='submit'
            disabled={this.state.disabled}
            route='datas'
          />
          <div className='container'>
            <div style={styles.content}>
              <div style={styles.formContainer}>
                <span style={styles.title}>{this.state.dataGroupName}</span>
                <div style={styles.fieldContent}>
                  <Form
                    ref={this.state.reference}
                    type={transform(this.state.data)}
                    options={this.state.options}
                    value={this.state.value}
                    onChange={(value) => this.onChange(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    background: '#FFFFFF'
  },
  formContainer: {
    paddingTop: 44,
    width: '100%',
    margin: 'auto',
    maxWidth: 328
  },
  title: {
    color: '#011123',
    fontSize: 18,
    fontWeight: 600
  }
}

AddData.propTypes = {
  location: PropTypes.object,
  groupName: PropTypes.string,
  match: PropTypes.object
}

export default AddData
