import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import SearchBar from './SearchBar'
import folderIcon from '../images/folderIcon.svg'
import FadeLoader from '../components/FadeLoader'
import _ from 'lodash'

class DataContentLeft extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      typeList: null,
      searchKey: null
    }
  }

  componentDidMount () {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        typeList: this.props.typeList
      })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.typeList !== this.props.typeList) {
      this.setState({
        typeList: nextProps.typeList
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    if (!this.state.typeList) {
      return (
        <div className='left-menu-content'>
          <div className='search-content'>
            <SearchBar
              getSearchKey={(key) => this.setState({ searchKey: key })}
            />
          </div>
          <FadeLoader />
        </div>
      )
    }
    var typeList = this.state.typeList
    var searchKey = this.state.searchKey
    if (searchKey) {
      typeList = _.filter(typeList, function (o) { return o.name.toLowerCase().includes(searchKey.toLowerCase()) })
    }
    return (
      <div className='left-menu-content'>
        <div className='search-content'>
          <SearchBar
            getSearchKey={(key) => this.setState({ searchKey: key })}
          />
        </div>
        <table cellSpacing='0' style={styles.table}>
          <tbody>
            {typeList.map((group) =>
              <tr
                key={group._id}
                style={this.props.groupId === group._id ? styles.selectedTr : styles.unSelectedTr}
                tabIndex={group._id}
                onClick={() => { this.props.onClickHandler(group._id, group.name) }}
                onKeyPress={() => {}}
                role='button'
              >
                <td style={styles.tdLeft}>
                  <img src={folderIcon} alt='' />
                </td>
                <td style={styles.tdRight}>
                  {group.name}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

const styles = {
  table: {
    width: '100%'
  },
  selectedTr: {
    height: 41,
    backgroundColor: '#D2D2D2',
    cursor: 'pointer',
    outline: 'none'
  },
  unSelectedTr: {
    backgroundColor: '#FFFFFF',
    height: 41,
    cursor: 'pointer',
    outline: 'none'
  },
  tdLeft: {
    width: 42,
    paddingLeft: 14
  },
  tdRight: {
    fontSize: 15,
    fontWeight: 400,
    color: '#252525'
  }
}

DataContentLeft.propTypes = {
  typeList: PropTypes.array,
  onClickHandler: PropTypes.func,
  groupId: PropTypes.string
}

export default DataContentLeft
