import React from 'react'
import config from '../../config'

class HandleCodeComponent extends React.Component {
  componentDidMount () {
    if (this.hasCode()) {
      this.setToken(this.getCode(), this.getState())
    } else {
      var urlString = window.location.href
      var urlArray = urlString.split('/')
      const organization = urlArray[4]
      if (organization !== '') {
        if (urlArray[5] !== 'closed-collections' &&
          urlArray[5] !== 'users' &&
          urlArray[5] !== 'datas' &&
          urlArray[5] !== 'closed-collection' &&
          urlArray[5] !== 'devices') {
          localStorage.setItem('organization', organization)
          document.location.href = `/#/${organization}/dashboard`
        }
      } else document.location.href = config.salt_url
    }
  }

  hasCode () {
    var urlString = window.location.href
    var url = new URL(urlString)
    if (url.searchParams.get('code')) {
      return true
    }
    return false
  }

  getCode () {
    var urlString = window.location.href
    var url = new URL(urlString)
    return url.searchParams.get('code')
  }

  getState () {
    var urlString = window.location.href
    var url = new URL(urlString)
    return url.searchParams.get('state')
  }

  setToken (code, state) {
    var details = {
      grant_type: 'authorization_code',
      code: code,
      client_id: config.clientId,
      redirect_uri: config.redirect_uri
    }

    var formBody = []
    for (var property in details) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(details[property])
      formBody.push(encodedKey + '=' + encodedValue)
    }
    formBody = formBody.join('&')

    const request = new Request(config.tokenRequest, {
      method: 'POST',
      body: formBody,
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      })
    })
    fetch(request)
      .then(response => response.json())
      .then((token) => {
        localStorage.setItem('token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
        var clientUrl = localStorage.getItem(state)
        if (clientUrl) {
          var urlArray = clientUrl.split('#')
          if (urlArray[1]) {
            document.location.href = '/#' + urlArray[1]
          } else document.location.href = config.salt_url
        } else document.location.href = '/#/error'
      })
      .catch((error) => {
        console.log('catch error', error)
      })
  }

  render () {
    return null
  }
}

export default HandleCodeComponent
