import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import FadeLoader from '../components/FadeLoader'
import { withTranslation } from 'react-i18next'

class ShowClosedCollectionLeft extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      collection: null
    }
  }

  async componentDidMount () {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        collection: this.props.collection
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { t } = this.props
    var collection = this.state.collection
    if (!this.state.collection) {
      return (
        <div className='left-closed-collection-content'>
          <FadeLoader />
        </div>
      )
    }
    return (
      <div className='left-closed-collection-content'>
        <div style={styles.titleContent}>
          <span style={styles.title}>
            {t(`doctype.${collection[0].content.type}.name`, collection[0].content.type).toUpperCase()}
          </span>
        </div>
        {collection.map((value, key) =>
          <div key={value.title}>
            {
              key !== 0
                ? (
                  <div style={styles.labelContent}>
                    <span style={styles.label}>
                      {t(`doctype.${value.title}.name`, value.title).toUpperCase()}
                    </span>
                  </div>
                )
                : null
            }
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  titleContent: {
    marginTop: 16,
    paddingLeft: 10,
    width: 'calc(100% -14px)',
    height: 36
  },
  title: {
    color: '#414141',
    fontSize: 13,
    fontWeight: 600
  },
  labelContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    width: 'calc(100% -14px)',
    height: 36
  },
  label: {
    color: '#252525',
    fontSize: 15,
    fontWeight: 400
  }
}

ShowClosedCollectionLeft.propTypes = {
  collection: PropTypes.array,
  t: PropTypes.func
}

export default (withTranslation()(ShowClosedCollectionLeft))
