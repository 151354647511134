import PouchDB from 'pouchdb'
import PouchdbFind from 'pouchdb-find'
import config from '../config'
PouchDB.plugin(PouchdbFind)

export function getFinalDocumentByOrg(org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.query('jsonapi/final-documents').then(function (res) {
      resolve(res)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getClosedCollectionByOrg (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        validatedAt: { $exists: true }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}


export function getInProgressCollectionByOrg (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        validatedAt: { $exists: false },
        user: { $exists: true },
        ref: { $exists: false }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function countClosedCollectionByOrg (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.query('jsonapi/final-documents').then(function (res) {
      resolve(res.total_rows)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getClosedCollectionByOrgAndPage (org, page, pageSize) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  var skip = (page - 1) * pageSize
  return new Promise((resolve, reject) => {
    db.createIndex({
      index: {
        fields: ['startAt']
      }
    }).then(function (res) {
      db.find({
        selector: {
          validatedAt: { $exists: true }
        },
        sort: [{'startAt': "desc"}],
        skip: skip,
        limit: pageSize
      }).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => {
    console.error('error', error)
  })
}

export function getListTypeContentByPage (org, id, page, pageSize) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  var skip = (page - 1) * pageSize
  return new Promise((resolve, reject) => {
    db.find({
      selector: { type: { $eq: id } },
      skip: skip,
      limit: pageSize
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function countListTypeContentById (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: { type: { $eq: id } },
      limit: 10000
    }).then(function (result) {
      resolve(result.docs.length)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function countSearchedTypeByOrgAndGroup (org, group, searchKey) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.createIndex({
      index: {
        fields: ['name']
      }
    }).then(function (res) {
      db.find({
        selector: {
          type: { $eq: group },
          name: { $regex: `(?i)${searchKey}` }
        },
        limit: 10000
      }).then(function (result) {
        resolve(result.docs.length)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function searchTypeByGroupAndPage (org, group, page, pageSize, searchKey) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  var skip = (page - 1) * pageSize
  return new Promise((resolve, reject) => {
    db.createIndex({
      index: {
        fields: ['name']
      }
    }).then(function (res) {
      db.find({
        selector: {
          type: { $eq: group },
          name: { $regex: `(?i)${searchKey}` }
        },
        skip: skip,
        limit: pageSize
      }).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getInProgressCollectionByOrgAndUser (org, user) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        validatedAt: { $exists: false },
        user: { $eq: user },
        ref: { $exists: false }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getClosedCollectionByOrgAndUser (org, user) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        validatedAt: { $exists: true },
        user: { $eq: user }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getUserByOrg (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        type: { $eq: 'USER' }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function countUserByOrgAndGroup (org, group) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        type: { $eq: 'USER' },
        group: { $eq: group }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result.docs.length)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getUserByGroupAndPage (org, group, page, pageSize) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  var skip = (page - 1) * pageSize
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        type: { $eq: 'USER' },
        group: { $eq: group }
      },
      skip: skip,
      limit: pageSize
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function countSearchedUserByGroup (org, group, searchKey) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.createIndex({
      index: {
        fields: ['name']
      }
    }).then(function (res) {
      db.find({
        selector: {
          type: { $eq: 'USER' },
          group: { $eq: group },
          name: { $regex: `(?i)${searchKey}` }
        },
        limit: 10000
      }).then(function (result) {
        resolve(result.docs.length)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function searchUserByGroupAndPage (org, group, page, pageSize, searchKey) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  var skip = (page - 1) * pageSize
  return new Promise((resolve, reject) => {
    db.createIndex({
      index: {
        fields: ['name']
      }
    }).then(function (res) {
      db.find({
        selector: {
          type: { $eq: 'USER' },
          group: { $eq: group },
          name: { $regex: `(?i)${searchKey}` }
        },
        skip: skip,
        limit: pageSize
      }).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function removeDocument (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.get(id).then(function (doc) {
      doc._deleted = true
      return db.put(doc).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => console.error('error', error))
}

export function getLatestClosedCollectionByOrgAndUser (org, user) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.createIndex({
      index: {
        fields: ['startAt']
      }
    }).then(function (res) {
      db.find({
        selector: {
          validatedAt: { $exists: true },
          user: user
        },
        sort: [
          {
            startAt: 'desc'
          }
        ],
        limit: 1
      }).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function addUser (org, document) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.post(document).then(function (result) {
      document._id = result.id
      document._rev = result.rev
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => console.error('error', error))
}

export function updateUser (org, id, document) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.get(id).then(function (doc) {
      var myDoc = Object.assign({}, doc)
      myDoc._id = doc._id
      myDoc._rev = doc._rev
      for (const key in document) {
        myDoc[key] = document[key]
      }
      return db.put(myDoc).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  }).catch(error => console.error('error', error))
}

export function getListType (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        usage: { $eq: 'reference' },
        _id: { $ne: 'USER' }
      },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getListTypeContent (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: { type: { $eq: id } },
      limit: 10000
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function findListTypeById (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: { type: { $eq: id } },
      limit: 1
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => console.error('error', error))
}

export function addType (org, document) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.post(document).then(function (result) {
      document._id = result.id
      document._rev = result.rev
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  })
}

export function updateType (org, id, document) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.get(id).then(function (doc) {
      var myDoc = Object.assign({}, doc)
      myDoc._id = doc._id
      myDoc._rev = doc._rev
      myDoc.name = document.name
      return db.put(myDoc).then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
    })
  })
}

export function getDocumentById (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        _id: { $eq: id }
      }
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  })
}

export function getDoctype (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        type: { $eq: 'LANGUAGE' }
      }
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  })
}

export function getClosedCollectionById (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.get(id, { attachments: true }
    ).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  }).catch(error => {
    console.error('error', error)
    document.location.href = '/#/error'
  })
}

export function getRelatedListType (org, type, name) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  var selector = {}
  selector[type] = { $eq: name }
  return new Promise((resolve, reject) => {
    db.find({ selector: selector }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  })
}

export function getOptions (org) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        _id: {
          $eq: 'options'
        }
      }
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  })
}

export function getDocumentStructureById (org, id) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        _id: { $eq: id },
        type: { $eq: 'DOCTYPE' }
      }
    }).then(function (result) {
      resolve(result)
    }).catch(function (err) {
      reject(err)
    })
  })
}

export function getSortFields (org, type) {
  var couchdbUrl = config.couchdb_url
  couchdbUrl = couchdbUrl + '/' + org
  var db = new PouchDB(couchdbUrl, { skip_setup: true })
  return new Promise((resolve, reject) => {
    db.find({
      selector: {
        type: {
          $eq: 'SORT_FIELDS'
        }
      }
    }).then(function (result) {
      const docs = result.docs
      const sort_fields = docs.filter(doc => doc.doc_type == type)
      resolve(sort_fields)
    }).catch(function (err) {
      reject(err)
    })
  })
}
