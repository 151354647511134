import React from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'

class DisabledTab extends React.Component {
  render () {
    return (
      <div className='disabled-section'>
        <button
          className='menu-button'
          onClick={this.props.onClick}
        >
          <span className='disabled-label-menu'>{this.props.label}</span>
        </button>
      </div>
    )
  }
}

DisabledTab.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string
}

export default DisabledTab
