import React, { Component } from 'react'
import { FadeLoader } from 'react-spinners'
class FadeLoaderComponent extends Component {
  render () {
    return (
      <div style={styles.container}>
        <FadeLoader
          height={8}
          width={5}
          radius={2}
          margin='2px'
          color='#DA262E'
        />
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'relative',
    marginTop: 80,
    left: '45%'
  }
}

export default FadeLoaderComponent
