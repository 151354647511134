import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import okIcon from '../images/okIcon.svg'
import {
  withTranslation
} from 'react-i18next'

class ProgressBar extends Component {
  getStyleOfNumberCenter () {
    var selectedActivation = this.props.selectedActivation
    var index = this.props.index
    if (index > 1) {
      if (selectedActivation === 'ok') {
        return styles.selectedNumberCenter
      } else {
        return styles.checkedNumberCenter
      }
    } else {
      if (index === 1) {
        return styles.selectedNumberCenter
      }
      return styles.numberCenter
    }
  }

  render () {
    const { t } = this.props
    return (
      <div className='container-with-padding'>
        <div style={styles.content}>
          <div style={styles.hrContent}>
            <hr style={this.props.index > 0 ? styles.hrEnabled : styles.hrDisabled} />
            <hr style={this.props.index > 1 ? styles.hrEnabled : styles.hrDisabled} />
          </div>
          <div style={styles.numberContent}>
            <div style={this.props.index === 0 ? styles.selectedNumberLeft : styles.numberLeft}>
              {this.props.index > 0 ? <img style={styles.icon} src={okIcon} alt='true' /> : <span style={styles.number}>1</span>}
            </div>
            <div style={this.getStyleOfNumberCenter()}>
              {this.props.index > 1 ? <img style={styles.icon} src={okIcon} alt='true' /> : <span style={styles.number}>2</span>}
            </div>
            <div style={this.props.index === 2 ? styles.selectedNumberRight : styles.numberRight}>
              <span style={styles.number}>3</span>
            </div>
          </div>
          <div style={styles.infoContent}>
            <div style={styles.labelLeft}>
              <span style={this.props.index === 0 ? styles.selectedLabel : styles.label}>{t('label')}</span>
            </div>
            <div style={styles.labelCenter}>
              <span style={this.props.index === 1 ? styles.selectedLabel : styles.label}>{t('choice_activation')}</span>
            </div>
            <div style={styles.labelRight}>
              <span style={this.props.index === 2 ? styles.selectedLabel : styles.label}>{t('validation')}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    width: '100%'
  },
  hrContent: {
    display: 'flex'
  },
  hrEnabled: {
    border: '2px solid #0091FF',
    width: '50%'
  },
  hrDisabled: {
    border: '2px solid #CBCBCB',
    width: '50%'
  },
  numberContent: {
    marginTop: -22,
    width: '100%',
    height: 24,
    display: 'flex'
  },
  infoContent: {
    width: '100%',
    height: 24,
    display: 'flex'
  },
  selectedNumberLeft: {
    float: 'left',
    paddingTop: 2,
    width: 24,
    height: 22,
    background: '#0091FF',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000

  },
  numberLeft: {
    float: 'left',
    paddingTop: 2,
    width: 24,
    height: 22,
    background: '#011123',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000

  },
  selectedNumberCenter: {
    float: 'center',
    paddingTop: 2,
    width: 24,
    height: 22,
    margin: 'auto',
    background: '#0091FF',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000
  },
  numberCenter: {
    float: 'center',
    paddingTop: 2,
    width: 24,
    height: 22,
    margin: 'auto',
    background: '#979797',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000
  },
  checkedNumberCenter: {
    float: 'center',
    paddingTop: 2,
    width: 24,
    height: 22,
    margin: 'auto',
    background: '#011123',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000
  },
  selectedNumberRight: {
    float: 'right',
    paddingTop: 2,
    width: 24,
    height: 22,
    background: '#0091FF',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000
  },
  numberRight: {
    float: 'right',
    paddingTop: 2,
    width: 24,
    height: 22,
    background: '#979797',
    borderRadius: 20,
    textAlign: 'center',
    zIndex: 1000
  },
  number: {
    color: '#EEEFF3',
    fontSize: 13,
    fontWeight: 600
  },
  labelLeft: {
    float: 'left',
    paddingTop: 2,
    textAlign: 'center'

  },
  labelCenter: {
    float: 'center',
    paddingTop: 2,
    margin: 'auto',
    textAlign: 'center'
  },
  labelRight: {
    float: 'right',
    paddingTop: 2,
    textAlign: 'center'
  },
  selectedLabel: {
    color: '#0091FF',
    fontSize: 12,
    fontWeight: 500
  },
  label: {
    color: '#9C9C9C',
    fontSize: 12,
    fontWeight: 500
  }
}

ProgressBar.propTypes = {
  index: PropTypes.number,
  selectedActivation: PropTypes.string,
  t: PropTypes.func
}

export default (withTranslation()(ProgressBar))
