import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import {
  refreshToken
} from '../utils/token'
import {
  getUserInfo
} from '../utils/userInfo'
import {
  countUserByOrgAndGroup,
  getUserByGroupAndPage
} from '../utils/pouchdb'
import UserContentLeft from './UserContentLeft'
import UserContentRight from './UserContentRight'
class UserContent extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      userListByGroup: null,
      userGroupNumber: null,
      group: props.userGroupId ? props.userGroupId : 'admin',
      groupList: [
        {
          id: 'admin',
          name: 'Admin'
        },
        {
          id: 'user',
          name: 'User'
        }
      ],
      orgIsChanged: false
    }
  }

  componentDidMount () {
    const self = this
    this._isMounted = true
    var token = localStorage.getItem('token')
    var organization = self.state.org
    if (this._isMounted) {
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    var userListByGroup = await getUserByGroupAndPage(organization, 'admin', 1, 10)
                    var userGroupNumber = await countUserByOrgAndGroup(organization, 'admin')
                    if ((userListByGroup && userGroupNumber) || userListByGroup.docs.length === 0) {
                      self.setState({
                        userListByGroup: userListByGroup.docs,
                        userGroupNumber
                      })
                    }
                  }
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              var userListByGroup = await getUserByGroupAndPage(organization, 'admin', 1, 10)
              var userGroupNumber = await countUserByOrgAndGroup(organization, 'admin')
              if ((userListByGroup && userGroupNumber) || userListByGroup.docs.length === 0) {
                self.setState({
                  userListByGroup: userListByGroup.docs,
                  userGroupNumber
                })
              }
            }
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    const self = this
    if (nextProps.org !== this.props.org) {
      var organization = nextProps.org
      var token = localStorage.getItem('token')
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    var userListByGroup = await getUserByGroupAndPage(organization, 'admin', 1, 10)
                    var userGroupNumber = await countUserByOrgAndGroup(organization, 'admin')
                    if ((userListByGroup && userGroupNumber) || userListByGroup.docs.length === 0) {
                      self.setState({
                        userListByGroup: userListByGroup.docs,
                        org: organization,
                        userGroupNumber,
                        orgIsChanged: true
                      })
                    }
                  } else document.location.href = '/#/error'
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              var userListByGroup = await getUserByGroupAndPage(organization, 'admin', 1, 10)
              var userGroupNumber = await countUserByOrgAndGroup(organization, 'admin')
              if ((userListByGroup && userGroupNumber) || userListByGroup.docs.length === 0) {
                self.setState({
                  userListByGroup: userListByGroup.docs,
                  org: organization,
                  userGroupNumber,
                  orgIsChanged: true
                })
              }
            } else document.location.href = '/#/error'
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async reloadComponent () {
    const self = this
    var userListByGroup = await getUserByGroupAndPage(self.state.org, self.state.group, 1, 10)
    var userGroupNumber = await countUserByOrgAndGroup(self.state.org, self.state.group)
    if (userListByGroup && userGroupNumber) {
      this.setState({
        userListByGroup: userListByGroup.docs,
        userGroupNumber
      })
    }
  }

  onClickHandler (group) {
    this.setState({
      group: group,
      orgIsChanged: false
    })
  }

  getGroup (group, groupList) {
    if (group) {
      if (this.state.orgIsChanged) {
        return groupList[0].id
      }
      return group
    }
    return groupList[0].group
  }

  render () {
    return (
      <div className='container'>
        <div style={styles.content}>
          <UserContentLeft
            onClickHandler={(index) => { this.onClickHandler(index) }}
            groupList={this.state.groupList}
            group={this.getGroup(this.state.group, this.state.groupList)}
          />
          <UserContentRight
            userListByGroup={this.state.userListByGroup}
            userGroupNumber={this.state.userGroupNumber}
            org={this.state.org}
            group={this.getGroup(this.state.group, this.state.groupList)}
            reloadComponent={() => this.reloadComponent()}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%'
  }
}

UserContent.propTypes = {
  userGroupId: PropTypes.string,
  org: PropTypes.string
}

export default UserContent
