import React from 'react'
import '../css/header.css'
import redirectToPiIcon from '../images/redirectToPiIcon.svg'
import config from '../config'
import { withTranslation } from 'react-i18next'

class RedirectToHome extends React.Component {
  render () {
    const { t } = this.props
    return (
      <div className='icon-right-container'>
        <div
          className='container-tooltip'
          style={styles.content}
          tabIndex='0'
          onClick={() => {
            document.location.href = config.salt_url
          }}
          role='button'
          onKeyPress={() => {}}
        >
          <img
            className='img-tooltip'
            style={styles.icon} src={redirectToPiIcon} alt='HOME'
          />
          <span className='tooltiptext'>{t('redirect_to_home')}</span>
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    marginTop: 12,
    paddingTop: 4,
    width: 30,
    height: 30,
    outline: 'none',
    cursor: 'pointer'
  },
  icon: {
    width: 24,
    height: 24
  }
}

export default (withTranslation()(RedirectToHome))
