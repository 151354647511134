import config from '../config'
import { Logger } from "react-remote-logger"

export function exportCsv (org, type) {
  window.location = `${config.csvlink}/${org}/${type}`
}

export function exportPdf (org, id, type) {
  Logger({
    'timestamp': (new Date()).toLocaleString(),
    'level': 'debug',
    'user_id': localStorage.user_id,
    'user': localStorage.user_email,
    'organization': localStorage.organization,
    'action': 'GET',
    'collection': id,
    'url': window.location.href,
    'message': `GET ${window.location.href}`
  })
  
  const instance = org.replace(/-preprod-v\d|-staging-v\d|-v\d|-preprod|-staging/i, '')
  window.open(`https://${instance}.reporter.tag-ip.com/${type}_${id}.pdf`, '_blank')
}