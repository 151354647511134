import React from 'react'
import TextField from '../Components/TextField'
import Password from '../Components/Password'
import Mail from '../Components/Mail'
import SelectField from '../Components/SelectField'
import Phone from '../Components/Phone'

function text (locals) {
  return (
    <div>
      <TextField
        label={locals.label}
        value={locals.value}
        onChange={(value) => locals.onChange(value)}
        hasError={locals.hasError}
      />
    </div>
  )
}

function password (locals) {
  return (
    <div>
      <Password
        label={locals.label}
        value={locals.value}
        onChange={(value) => locals.onChange(value)}
        hasError={locals.hasError}
      />
    </div>
  )
}

function mail (locals) {
  return (
    <div>
      <Mail
        label={locals.label}
        value={locals.value}
        onChange={(value) => locals.onChange(value)}
        hasError={locals.hasError}
      />
    </div>
  )
}

function phone (locals) {
  return (
    <div>
      <Phone
        label={locals.label}
        value={locals.value}
        onChange={(value) => locals.onChange(value)}
        hasError={locals.hasError}
      />
    </div>
  )
}

function selectField (locals) {
  return (
    <div>
      <SelectField
        label={locals.label}
        value={locals.value}
        onChange={(value) => locals.onChange(value)}
        hasError={locals.hasError}
        options={locals.options}
      />
    </div>
  )
}

export function getTemplates () {
  const templates = []
  templates.text = text
  templates.password = password
  templates.mail = mail
  templates.selectField = selectField
  templates.phone = phone
  return templates
}
