import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class ButtonBack extends React.Component {
  render () {
    var org = localStorage.getItem('organization')
    var devices = `/${org}/devices`
    return (
      <div>
        <Link style={styles.link} to={devices}>
          <button
            style={styles.button}
          >
            <span style={styles.label}>{this.props.label}</span>
          </button>
        </Link>
      </div>
    )
  }
}

const styles = {
  link: {
    textDecoration: 'none'
  },
  button: {
    cursor: 'pointer',
    marginTop: 4,
    width: 126,
    height: 32,
    border: '1px solid #DADADA',
    borderRadius: 6,
    outline: 'none'
  },
  label: {
    textAlign: 'center',
    color: '#000000',
    fontSize: 16,
    fontWeight: 500
  }
}

ButtonBack.propTypes = {
  label: PropTypes.string
}

export default ButtonBack
