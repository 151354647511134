import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import '../css/table.css'
import ascendIcon from '../images/ascendingIcon.svg'
import descendIcon from '../images/descendingIcon.svg'
import deleteIcon from '../images/deleteIcon.svg'
import editIcon from '../images/editIcon.svg'
import _ from 'lodash'
import {
  getLatestClosedCollectionByOrgAndUser,
  removeDocument
} from '../utils/pouchdb'
import async from 'async'
import { Redirect } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import { withTranslation } from 'react-i18next'

moment.locale('fr')

class UserListTable extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      userByGroup: props.userByGroup,
      group: props.group,
      newUserListByGroup: null,
      editIsClicked: false,
      userId: null,
      openModal: false,
      selectedId: null,
      valueKey: '',
      isSorted: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        newUserListByGroup: this.state.userByGroup
      })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.userByGroup !== this.props.userByGroup) {
      this.setState({
        org: nextProps.org,
        group: nextProps.group,
        newUserListByGroup: nextProps.userByGroup
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onOpenModal (id) {
    this.setState({
      openModal: true,
      selectedId: id
    })
  }

  onCloseModal () {
    var self = this
    self.setState({ openModal: false })
  }

  capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  sortByColumn (key) {
    const self = this
    const columnState = !this.state[key]
    var myKey = key === 'dateOfLatestCollection' ? [key] : [user => user[key].toLowerCase()]
    this.setState({
      isSorted: true,
      [key]: columnState,
      newUserListByGroup: _.orderBy(
        self.state.newUserListByGroup,
        myKey,
        columnState ? 'asc' : 'desc'
      ),
      valueKey: key
    })
  }

  addLatestCollection (userByGroup) {
    var self = this
    var org = self.state.org
    var newUserListByGroup = []
    async.eachLimit(userByGroup, 1, function (user, callback) {
      getLatestClosedCollectionByOrgAndUser(org, user.name).then(function (result) {
        var newUser = user
        var dateOfLatestCollection
        if (result.docs.length > 0) {
          dateOfLatestCollection = result.docs[0].startAt
          newUser.dateOfLatestCollection = dateOfLatestCollection
          newUserListByGroup.push(newUser)
          callback()
        } else {
          newUserListByGroup.push(newUser)
          callback()
        }
      }).catch(function (err) {
        console.log(err)
      })
    }, function (err) {
      if (err) {
        console.log(err)
      } else {
        self.setState({
          newUserListByGroup: newUserListByGroup
        })
      }
    })
  }

  deleteUser (id) {
    var self = this
    var org = self.state.org
    var urlString = window.location.href
    var urlArray = urlString.split('/')
    localStorage.setItem('currentRoute', urlArray[urlArray.length - 1])
    removeDocument(org, id).then(function (result) {
      console.log(result)
      self.onCloseModal()
      self.props.reloadComponent()
    })
  }

  render () {
    const { t } = this.props
    if (this.state.newUserListByGroup && !this.state.editIsClicked) {
      var newUserListByGroup = this.state.newUserListByGroup
      if (!this.state.isSorted) {
        newUserListByGroup = _.orderBy(
          newUserListByGroup, [user => user.name.toLowerCase()], 'desc'
        )
      }
      return (
        <div style={styles.content}>
          <table cellSpacing='0' className='table'>
            <thead>
              <tr className='head'>
                <td
                  className='td-left-padding td-left-padding-standard td-left head-border td-outline'
                  role='gridcell'
                  onClick={
                    () => this.sortByColumn('name')
                  }
                  tabIndex={0}
                  onKeyPress={() => {}}
                >
                  <span>{this.capitalizeFirstLetter(this.state.group)}</span>
                  {
                    this.state.valueKey === 'name'
                      ? (this.state.name === true
                        ? <img
                          style={styles.icon}
                          src={ascendIcon}
                          alt='true'
                        />
                        : <img
                          style={styles.icon}
                          src={descendIcon}
                          alt='true'
                        />)
                      : <img
                        style={styles.icon}
                        src={descendIcon}
                        alt='true'
                      />
                  }
                </td>
                <td
                  className='td-head-right-standard head-border td-outline'
                  role='gridcell'
                  onClick={
                    () => this.sortByColumn('dateOfLatestCollection')
                  }
                  tabIndex={0}
                  onKeyPress={() => {}}
                >
                  <span>{t('last_collection')}</span>
                  {
                    this.state.valueKey === 'dateOfLatestCollection'
                      ? (this.state.dateOfLatestCollection === true
                        ? <img
                          style={styles.iconRigth}
                          src={ascendIcon}
                          alt='true'
                        />
                        : <img
                          style={styles.iconRigth}
                          src={descendIcon}
                          alt='true'
                        />)
                      : <img
                        style={styles.iconRigth}
                        src={descendIcon}
                        alt='true'
                      />
                  }
                </td>
              </tr>
            </thead>
            <tbody>
              {newUserListByGroup.map((user) =>
                <tr key={user._id} className='tr'>
                  <td className='td td-left-padding-standard td-left td-center'>
                    {user.name}
                  </td>
                  <td className='td td-right-standard hover-button'>
                    <div style={styles.contentButton}>
                      <span className='hover-button--off'>
                        {user.dateOfLatestCollection ? moment.utc(user.dateOfLatestCollection).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm') : '-'}
                      </span>
                      <div className='hover-button--on'>
                        <table cellSpacing='0' style={styles.table}>
                          <tbody>
                            <tr>
                              <td style={styles.iconContent}>
                                <div
                                  style={{ outline: 'none' }}
                                  tabIndex='0'
                                  onClick={() => this.onOpenModal(user._id)}
                                  role='button'
                                  onKeyPress={() => {}}
                                >
                                  <img
                                    style={styles.iconHover}
                                    src={deleteIcon}
                                    alt='true'
                                  />
                                </div>
                              </td>
                              <td style={styles.iconContent}>
                                <div
                                  style={{ outline: 'none' }}
                                  tabIndex='0'
                                  onClick={() => this.setState({
                                    editIsClicked: true,
                                    userId: user._id
                                  })}
                                  role='button'
                                  onKeyPress={() => {}}
                                >
                                  <img
                                    src={editIcon}
                                    style={styles.iconHover}
                                    alt='true'
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div>
            <Modal
              styles={bg}
              open={this.state.openModal}
              onClose={() => this.onCloseModal()}
              showCloseIcon={false}
              center
              focusTrapped={false}
            >
              <h2 style={styles.modalTitle}>{t('deleting_user')}</h2>
              <p style={styles.modalText}>{t('remove_user')}</p>
              <div>
                <button style={styles.modalButtonLeft} onClick={() => this.onCloseModal()}><span>{t('cancel')}</span></button>
                <button style={styles.modalButtonRight} onClick={() => this.deleteUser(this.state.selectedId)}>{t('delete')}</button>
              </div>
            </Modal>
          </div>
        </div>
      )
    }
    if (this.state.editIsClicked) {
      localStorage.setItem('organization', this.state.org)
      localStorage.setItem('userGroupId', this.state.group)
      return <Redirect
        to={{
          pathname: `/${this.state.org}/add-user`,
          state: {
            groupId: this.state.group,
            groupType: 'user',
            editedUserId: this.state.userId
          }
        }}
      />
    }
    return null
  }
}

const bg = {
  modal: {
    background: '#EBEBEB',
    borderRadius: '6px',
    color: '#272D31'
  }
}

const styles = {
  content: {
    paddingTop: 14,
    width: '100%'
  },
  contentButton: {
    width: 120,
    float: 'right'
  },
  icon: {
    float: 'right',
    marginTop: 6,
    marginRight: 4,
    marginLeft: 12
  },
  iconRigth: {
    float: 'right',
    marginTop: 6,
    marginLeft: 12
  },
  table: {
    float: 'right'
  },
  iconContent: {
    paddingLeft: 6
  },
  iconHover: {
    cursor: 'pointer'
  },
  modalTitle: {
    fontSize: 14,
    marginTop: '-10px',
    textAlign: 'center'
  },
  modalText: {
    fontSize: '12px'
  },
  modalButtonLeft: {
    float: 'left',
    width: '100px',
    borderWidth: '0px',
    height: '24px',
    borderRadius: '3px',
    outline: 'none'
  },
  modalButtonRight: {
    backgroundColor: '#DA262E',
    borderWidth: '0px',
    float: 'right',
    width: '100px',
    height: '24px',
    borderRadius: '3px',
    outline: 'none'
  }
}

UserListTable.propTypes = {
  userByGroup: PropTypes.array,
  group: PropTypes.string,
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(UserListTable))
