import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import emptyUserIcon from '../images/emptyUserIcon.svg'
import FadeLoader from '../components/FadeLoader'

class UserContentLeft extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      groupList: null
    }
  }

  componentDidMount () {
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        groupList: this.props.groupList
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    if (!this.state.groupList) {
      return <FadeLoader />
    }
    return (
      <div className='left-menu-content'>
        <table cellSpacing='0' style={styles.table}>
          <tbody>
            {this.state.groupList.map((group) =>
              <tr
                key={group.id}
                style={this.props.group === group.id ? styles.selectedTr : styles.unSelectedTr}
                tabIndex={group.id}
                onClick={() => { this.props.onClickHandler(group.id) }}
                onKeyPress={() => {}}
                role='button'
              >
                <td style={styles.tdLeft}>
                  <img src={emptyUserIcon} alt='USER' />
                </td>
                <td style={styles.tdRight}>
                  {group.name}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

const styles = {
  table: {
    width: '100%'
  },
  selectedTr: {
    height: 41,
    backgroundColor: '#D2D2D2',
    cursor: 'pointer',
    outline: 'none'
  },
  unSelectedTr: {
    backgroundColor: '#FFFFFF',
    height: 41,
    cursor: 'pointer',
    outline: 'none'
  },
  tdLeft: {
    width: 42,
    paddingLeft: 14
  },
  tdRight: {
    fontSize: 15,
    fontWeight: 400,
    color: '#252525'
  }
}

UserContentLeft.propTypes = {
  onClickHandler: PropTypes.func,
  group: PropTypes.string,
  groupList: PropTypes.array
}

export default UserContentLeft
