import React from 'react'
import PropTypes from 'prop-types'

class Disconnection extends React.Component {
  render () {
    return (
      <div className='disabled-section' style={{ marginLeft: 28 }}>
        <button
          className='menu-button'
          onClick={this.props.onClick}
        >
          <span className='disabled-label-menu'>{this.props.label}</span>
        </button>
      </div>
    )
  }
}

Disconnection.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
}

export default Disconnection
