import config from '../config'

export function getUserInfo (token) {
  var opt = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      Authorization: `bearer ${token}`
    })
  }
  return new Promise((resolve, reject) => {
    fetch(`${config.salt_url}/userinfo`, opt)
      .then(function (result) {
        resolve(result)
      }).catch(function (err) {
        reject(err)
      })
      .catch(function (err) {
        reject(err)
      })
  }).catch(error => {
    console.error('error', error)
  })
}
