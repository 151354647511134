import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    width: '100%'
  },
  input: {
    color: 'rgba(0,0,0,0.60)',
    fontSize: 15.8,
    borderColor: 'red'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: ' #BFBFBF !important'
  }
})

class MailComponent extends React.Component {
  isValidateEmail (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  render () {
    const { classes } = this.props
    if ((this.props.hasError && this.props.value === '') || (this.props.value !== '' && !this.isValidateEmail(this.props.value))) {
      return (
        <div>
          <TextField
            error
            label={this.props.label}
            value={this.props.value}
            className={classes.root}
            onChange={(event) => this.props.onChange(event.target.value)}
            margin='normal'
            helperText='Mail invalide'
            InputLabelProps={{
              style: { color: '#F44335', fontSize: 15.8, fontWeight: 400, fontStyle: 'normal' }
            }}
            variant='outlined'
          />
        </div>
      )
    } else {
      return (
        <div>
          <TextField
            label={this.props.label}
            value={this.props.value}
            className={classes.root}
            onChange={(event) => this.props.onChange(event.target.value)}
            margin='normal'
            InputProps={{
              classes: this.props.classes
            }}
            InputLabelProps={{
              style: { color: 'rgba(0,0,0,0.60)', fontSize: 15.8, fontWeight: 400, fontStyle: 'normal' }
            }}
            variant='outlined'
          />
        </div>
      )
    }
  }
}

MailComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  hasError: PropTypes.bool
}

export default withStyles(styles)(MailComponent)
