import gql from 'graphql-tag'

export const DEVICE_BY_ORG = gql`
  query Device($org: String!){
    devicesByCollection(collection: $org){
      id
      label
      collection
      isActive
      metadata{
        deviceId
        deviceType
      }
    }
  }
`

export const DEVICE_BY_ORG_AND_PAGE = gql`
  query Device($org: String!, $offset: Int, $limit: Int){
    devicesByCollection(collection: $org, offset: $offset, limit: $limit){
      id
      label
      collection
      isActive
      metadata{
        deviceId
        deviceType
      }
    }
  }
`

export const DEVICE_BY_ID = gql`
  query Device($id: ID!){
    device(id: $id){
      id
      label
      collection
    }
  }
`

export const QR_CODE_BY_ID = gql`
  query QrCode($id: ID!){
    qrcode(id: $id){
      id
      data
    }
  }
`

export const ACCESS_CODE_BY_ID = gql`
  query AccessCode($id: ID!){
    accessCode(id: $id){
      id
      data
    }
  }
`

export const ADD_DEVICE = gql`
  mutation($org: String!, $isActive: Boolean, $label: String!){
    createDevice(collection: $org, isActive: $isActive, label: $label) {
      id
    }
  }
`

export const UPDATE_DEVICE = gql`
  mutation($id: ID!, $label: String!){
    updateDevice(id: $id, params:{label: $label}) {
      id
    }
  }
`

export const DELETE_DEVICE = gql`
  mutation($id: ID!){
    deleteDevice(id: $id) {
      id
    }
  }
`

export const SEND_MAIL = gql`
  mutation($id: String!, $mailAddress: String!, $collection: String!, $locale: String!){
    sendQrcodeByMail(deviceId:$id, recipient:$mailAddress, collection: $collection, locale: $locale)
  }
`

export const SEND_ACCESS_CODE_MAIL = gql`
  mutation($id: String!, $mailAddress: String!, $collection: String!, $locale: String!){
    sendAccessCodeByMail(deviceId:$id, recipient:$mailAddress, collection: $collection, locale: $locale)
  }
`
