import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../App.css'
import '../css/style.css'
import t from 'tcomb-form'
import transform from 'tcomb-json-schema'
import AddHeader from '../components/AddHeader'
import { getTemplates } from '../components/Templates/templates'
import bcrypt from 'bcryptjs'
import {
  addUser,
  updateUser,
  getDocumentById
} from '../utils/pouchdb'
import { Redirect } from 'react-router-dom'
import i18n from '../i18n'
import { Logger } from "react-remote-logger"

const templates = getTemplates()
const Form = t.form.Form

class AddUser extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      value: null,
      reference: 'USER',
      org: localStorage.getItem('organization'),
      disabled: false,
      addIsClicked: false,
      userGroupId: props.location.state && props.location.state.groupId && props.location.state.groupType === 'user' ? props.location.state.groupId : localStorage.getItem('userGroupId'),
      editedUserId: this.props.location.state && this.props.location.state.editedUserId ? this.props.location.state.editedUserId : null,
      options: {
        fields: {
          name: {
            label: i18n.t('name'),
            template: templates.text
          },
          firstName: {
            label: i18n.t('firstname'),
            template: templates.text
          },
          email: {
            label: i18n.t('email'),
            template: templates.mail
          },
          address: {
            label: i18n.t('address'),
            template: templates.text
          },
          phoneNumber: {
            label: i18n.t('phone_number'),
            template: templates.phone
          },
          // login: {
          //   label: i18n.t('login'),
          //   template: templates.text
          // },
          hashPassword: {
            label: i18n.t('password'),
            template: templates.password
          },
        }
      },
      data: {
        type: 'object',
        properties: {
          name: {
            type: 'string'
          },
          firstName: {
            type: 'string'
          },
          email: {
            type: 'string'
          },
          phoneNumber: {
            type: 'string'
          },
          address: {
            type: 'string'
          },
          // login: {
          //   type: 'string'
          // },
          hashPassword: {
            type: 'string'
          }
        },  
        required: [
          'name',
          'firstName',
          'login',
          'hashPassword'
        ]
      }
    }
  }

  async componentDidMount () {
    this._isMounted = true
    if (this._isMounted && this.state.editedUserId) {
      var dataOfForm = await getDocumentById(this.state.org, this.state.editedUserId)
      this.setState({
        value: dataOfForm.docs[0],
        options: {
          fields: {
            name: {
              label: i18n.t('name'),
              template: templates.text
            },
            firstName: {
              label: i18n.t('firstname'),
              template: templates.text
            },
            email: {
              label: i18n.t('email'),
              template: templates.mail
            },
            address: {
              label: i18n.t('address'),
              template: templates.text
            },
            phoneNumber: {
              label: i18n.t('phone_number'),
              template: templates.phone
            }
          }
        },
        data: {
          type: 'object',
          properties: {
            name: {
              type: 'string'
            },
            firstName: {
              type: 'string'
            },
            email: {
              type: 'string'
            },
            phoneNumber: {
              type: 'string'
            },
            address: {
              type: 'string'
            }
          }
        }
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onSubmit (evt) {
    var self = this
    evt.preventDefault()
    const value = this.refs[this.state.reference].getValue()
    if (value) {
      const newValue = Object.assign({}, value)
      if (!self.state.editedUserId) {
        newValue.hashPassword = self.hashPassword(newValue.hashPassword)
      }
      self.setState({
        disabled: true
      })
      const attributes = Object.assign(
        {
          type: 'USER',
          usage: 'referenceList',
          group: self.state.userGroupId,
          login: newValue['name'].toLowerCase()
        },
        newValue)
      if (self.state.editedUserId) {
        updateUser(self.state.org, self.state.editedUserId, attributes).then(function (result) {
          console.log(result)
          Logger({
            'timestamp': (new Date()).toLocaleString(),
            'level': 'debug',
            'user_id': localStorage.user_id,
            'user': localStorage.user_email,
            'organization': localStorage.organization,
            'action': 'PUT',
            'id': self.state.editedUserId,
            'params': attributes,
            'url': window.location.href,
            'message': `PUT ${window.location.href}`
          })
          self.setState({
            addIsClicked: true
          })
        })
      } else {
        addUser(self.state.org, attributes).then(function (result) {
          console.log(result)
          Logger({
            'timestamp': (new Date()).toLocaleString(),
            'level': 'debug',
            'user_id': localStorage.user_id,
            'user': localStorage.user_email,
            'organization': localStorage.organization,
            'action': 'POST',
            'params': attributes,
            'url': window.location.href,
            'message': `POST ${window.location.href}`
          })
          self.setState({
            addIsClicked: true
          })
        })
      }
    }
  }

  onChange (value) {
    console.log("Value: ",value)
    this.setState({
      value: value
    })
  }

  capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  hashPassword (password) {
    var salt = bcrypt.genSaltSync(10)
    var hash = bcrypt.hashSync(password, salt)
    return hash
  }

  render () {
    var organization = this.props.match.params.org
    if (this.state.org !== organization) {
      document.location.href = '/#/error'
      return null
    }
    if (this.state.addIsClicked) {
      return <Redirect
        to={{
          pathname: `/${this.state.org}/users`,
          state: {
            userGroupId: this.state.userGroupId
          }
        }}
      />
    }
    return (
      <div className='App'>
        <form
          onSubmit={(e) => this.onSubmit(e)}
        >
          <AddHeader
            type='submit'
            disabled={this.state.disabled}
            route='users'
          />
          <div className='container'>
            <div style={styles.content}>
              <div style={styles.formContainer}>
                <span style={styles.title}>{this.capitalizeFirstLetter(this.state.userGroupId)}</span>
                <div style={styles.fieldContent}>
                  <Form
                    ref={this.state.reference}
                    type={transform(this.state.data)}
                    options={this.state.options}
                    value={this.state.value}
                    onChange={(value) => this.onChange(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    background: '#FFFFFF'
  },
  formContainer: {
    paddingTop: 44,
    width: '100%',
    margin: 'auto',
    maxWidth: 328
  },
  title: {
    color: '#011123',
    fontSize: 18,
    fontWeight: 600
  }
}

AddUser.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
}

export default AddUser
