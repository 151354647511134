import config from '../config'

export function refreshToken (refreshToken) {
  const tokenRequest = `${config.salt_url}/oauth/token?client_id=${config.clientId}&client_secret=${config.clientSecret}&grant_type=refresh_token&redirect_uri=${encodeURIComponent(config.redirect_uri)}&scope=offline_access%20openid&refresh_token=${refreshToken}`
  const request = new Request(tokenRequest, {
    method: 'POST',
    body: {},
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    })
  })
  var promise = fetch(request)
    .then(response => response.json())
    .then((token) => {
      localStorage.setItem('token', token.access_token)
      localStorage.setItem('refresh_token', token.refresh_token)
    })
    .catch((error) => {
      console.log('catch error', error)
    })
  return promise
}
