import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import '../css/table.css'
import ascendIcon from '../images/ascendingIcon.svg'
import descendIcon from '../images/descendingIcon.svg'
import deleteIcon from '../images/deleteIcon.svg'
import editIcon from '../images/editIcon.svg'
import qrIcon from '../images/qrIcon.svg'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { client } from '../graphql/client'
import {
  DELETE_DEVICE
} from '../query/device'
import Modal from 'react-responsive-modal'
import { withTranslation } from 'react-i18next'
import { Logger } from "react-remote-logger"

moment.locale('fr')

class DeviceTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      org: props.org,
      devices: props.devices,
      valueKey: '',
      isSorted: false,
      activateDevice: false,
      openModal: false,
      selectedId: null,
      editIsClicked: false,
      deviceId: null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.devices !== this.props.devices) {
      this.setState({
        org: nextProps.org,
        devices: nextProps.devices
      })
    }
  }

  sortByColumn (key) {
    const self = this
    const columnState = !this.state[key]
    this.setState({
      isSorted: true,
      [key]: columnState,
      devices: _.orderBy(
        self.state.devices,
        [device => device[key].toLowerCase()],
        columnState ? 'asc' : 'desc'
      ),
      valueKey: key
    })
  }

  activateDevice (id) {
    this.setState({
      activateDevice: true,
      deviceId: id
    })
  }

  onOpenModal (id) {
    this.setState({
      openModal: true,
      selectedId: id
    })
  }

  onCloseModal () {
    var self = this
    self.setState({ openModal: false })
  }

  deleteDevice (id) {
    var self = this
    client
      .mutate({
        mutation: DELETE_DEVICE,
        variables: { id: id }
      })
      .then(result => {
        Logger({
          'timestamp': (new Date()).toLocaleString(),
          'level': 'debug',
          'user_id': localStorage.user_id,
          'user': localStorage.user_email,
          'organization': localStorage.organization,
          'action': 'DELETE',
          'id': id,
          'url': window.location.href,
          'message': `DELETE ${window.location.href}`
        })
        self.onCloseModal()
        self.props.reloadComponent()
      }).catch(error => console.log(error))
  }

  render () {
    const { t } = this.props
    var searchKey = this.props.searchKey
    if (this.state.activateDevice) {
      return <Redirect
        to={{
          pathname: `/${this.state.org}/add-device`,
          state: {
            activationIndex: 1,
            deviceToActivateId: this.state.deviceId
          }
        }}
      />
    }
    if (this.state.devices && !this.state.editIsClicked) {
      var devices = this.state.devices
      if (searchKey) {
        devices = _.filter(devices, function (o) { return o.label.toLowerCase().includes(searchKey.toLowerCase()) })
      }
      if (!this.state.isSorted) {
        devices = _.orderBy(
          devices, [device => device.label.toLowerCase()], 'desc'
        )
      }
      return (
        <div style={styles.content}>
          <table cellSpacing='0' className='table'>
            <thead>
              <tr className='head'>
                <td
                  className='td-left-padding-standard td-left head-border td-outline'
                  role='gridcell'
                  onClick={
                    () => this.sortByColumn('label')
                  }
                  tabIndex={0}
                  onKeyPress={() => {}}
                >
                  <span>{t('label')}</span>
                  {
                    this.state.valueKey === 'label'
                      ? (this.state.label === true
                        ? <img
                          style={styles.icon}
                          src={ascendIcon}
                          alt='true'
                        />
                        : <img
                          style={styles.icon}
                          src={descendIcon}
                          alt='true'
                        />)
                      : <img
                        style={styles.icon}
                        src={descendIcon}
                        alt='true'
                      />
                  }
                </td>
                <td className='td-head-right-standard head-border'>
                  <span style={styles.stateLabel}>{t('state')}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) =>
                <tr key={device.id} className='tr'>
                  <td className='td td-left-padding-standard td-left td-standard'>
                    {device.label}
                  </td>
                  <td className='td td-right-standard hover-button'>
                    <div style={styles.contentButton}>
                      <span className='hover-button--off'>
                        {device.isActive ? t('activated') : t('wait_activation')}
                      </span>
                      <div className='hover-button--on'>
                        <table cellSpacing='0' style={styles.table}>
                          <tbody>
                            <tr>
                              {
                                !device.isActive
                                  ? (
                                    <td style={styles.iconContent}>
                                      <div
                                        style={{ outline: 'none' }}
                                        tabIndex='0'
                                        onClick={() => this.activateDevice(device.id)}
                                        role='button'
                                        onKeyPress={() => {}}
                                      >
                                        <img
                                          style={styles.iconHover}
                                          src={qrIcon}
                                          alt='true'
                                        />
                                      </div>
                                    </td>
                                  )
                                  : null
                              }
                              <td style={styles.iconContent}>
                                <div
                                  style={{ outline: 'none' }}
                                  tabIndex='0'
                                  onClick={() => this.setState({
                                    editIsClicked: true,
                                    deviceId: device.id
                                  })}
                                  role='button'
                                  onKeyPress={() => {}}
                                >
                                  <img
                                    style={styles.iconHover}
                                    src={editIcon}
                                    alt='true'
                                  />
                                </div>
                              </td>
                              <td style={styles.iconContent}>
                                <div
                                  style={{ outline: 'none' }}
                                  tabIndex='0'
                                  onClick={() => this.onOpenModal(device.id)}
                                  role='button'
                                  onKeyPress={() => {}}
                                >
                                  <img
                                    src={deleteIcon}
                                    style={styles.iconHover}
                                    alt='true'
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div>
            <Modal
              styles={bg}
              open={this.state.openModal}
              onClose={() => this.onCloseModal()}
              showCloseIcon={false}
              center
              focusTrapped={false}
            >
              <h2 style={styles.modalTitle}>{t('deleting_device')}</h2>
              <p style={styles.modalText}>{t('desactive_&_delete')}</p>
              <div>
                <button style={styles.modalButtonLeft} onClick={() => this.onCloseModal()}><span>{t('cancel')}</span></button>
                <button style={styles.modalButtonRight} onClick={() => this.deleteDevice(this.state.selectedId)}>{t('delete')}</button>
              </div>
            </Modal>
          </div>
        </div>
      )
    }
    if (this.state.editIsClicked) {
      localStorage.setItem('organization', this.state.org)
      return <Redirect
        to={{
          pathname: `/${this.state.org}/edit-device/${this.state.deviceId}`,
          state: {
            editedDeviceId: this.state.deviceId
          }
        }}
      />
    }
  }
}

const bg = {
  modal: {
    background: '#EBEBEB',
    borderRadius: '6px',
    color: '#272D31'
  }
}

const styles = {
  content: {
    paddingTop: 24,
    width: '100%'
  },
  contentButton: {
    width: 150,
    float: 'right'
  },
  icon: {
    float: 'right',
    marginTop: 6,
    marginRight: 14,
    marginLeft: 12
  },
  iconRigth: {
    float: 'right',
    marginTop: 6,
    marginLeft: 12
  },
  table: {
    float: 'right'
  },
  iconContent: {
    paddingLeft: 6
  },
  iconHover: {
    cursor: 'pointer'
  },
  stateLabel: {
    float: 'right',
    paddingRight: 22
  },
  modalTitle: {
    fontSize: 14,
    marginTop: '-10px',
    textAlign: 'center'
  },
  modalText: {
    fontSize: '12px'
  },
  modalButtonLeft: {
    cursor: 'pointer',
    float: 'left',
    width: '100px',
    borderWidth: '0px',
    height: '24px',
    borderRadius: '3px',
    outline: 'none'
  },
  modalButtonRight: {
    cursor: 'pointer',
    backgroundColor: '#DA262E',
    borderWidth: '0px',
    float: 'right',
    width: '100px',
    height: '24px',
    borderRadius: '3px',
    outline: 'none'
  }
}

DeviceTable.propTypes = {
  devices: PropTypes.array,
  searchKey: PropTypes.string,
  t: PropTypes.func
}

export default (withTranslation()(DeviceTable))
