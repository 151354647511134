import React from 'react'
import PropTypes from 'prop-types'

class EmptyList extends React.Component {
  render () {
    return (
      <div style={styles.content}>
        {this.props.text}
      </div>
    )
  }
}

const styles = {
  content: {
    width: '100%',
    height: '120px',
    lineHeight: '120px',
    textAlign: 'center',
    fontSize: '20px',
    color: 'red'
  }
}

EmptyList.propTypes = {
  text: PropTypes.string
}

export default EmptyList
