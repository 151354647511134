import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import GridContent from './GridContent'
import Grid from '@material-ui/core/Grid'
import inProgressIcon from '../images/inProgressIcon.svg'
// import alertIcon from '../images/alertIcon.svg'
import closedIcon from '../images/closedIcon.svg'
import userIcon from '../images/userIcon.svg'
import {
  refreshToken
} from '../utils/token'
import {
  getUserInfo
} from '../utils/userInfo'
import {
  getInProgressCollectionByOrg,
  getFinalDocumentByOrg,
  getUserByOrg
} from '../utils/pouchdb'
import FadeLoader from '../components/FadeLoader'
import { withTranslation } from 'react-i18next'

class DashboardContentLeft extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      inProgressCollectionList: null,
      closedCollectionList: null,
      userList: null
    }
  }

  componentDidMount () {
    const self = this
    var token = localStorage.getItem('token')
    var organization = this.state.org
    this._isMounted = true
    if (this._isMounted) {
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    var inProgressCollectionList = await getInProgressCollectionByOrg(organization)
                    var closedCollectionList = await getFinalDocumentByOrg(organization)
                    var userList = await getUserByOrg(organization)
                    if (inProgressCollectionList && closedCollectionList && userList) {
                      self.setState({
                        inProgressCollectionList: inProgressCollectionList.docs,
                        closedCollectionList: closedCollectionList,
                        userList: userList.docs
                      })
                    }
                  }
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              var inProgressCollectionList = await getInProgressCollectionByOrg(organization)
              var closedCollectionList = await getFinalDocumentByOrg(organization)
              var userList = await getUserByOrg(organization)
              if (inProgressCollectionList && closedCollectionList && userList) {
                self.setState({
                  inProgressCollectionList: inProgressCollectionList.docs,
                  closedCollectionList: closedCollectionList,
                  userList: userList.docs
                })
              }
            }
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const self = this
    if (nextProps.org !== this.props.org) {
      var organization = nextProps.org
      var token = localStorage.getItem('token')
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    var inProgressCollectionList = await getInProgressCollectionByOrg(organization)
                    var closedCollectionList = await getFinalDocumentByOrg(organization)
                    var userList = await getUserByOrg(organization)
                    if (inProgressCollectionList && closedCollectionList && userList) {
                      self.setState({
                        org: organization,
                        inProgressCollectionList: inProgressCollectionList.docs,
                        closedCollectionList: closedCollectionList,
                        userList: userList.docs
                      })
                    }
                  } else document.location.href = '/#/error'
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              var inProgressCollectionList = await getInProgressCollectionByOrg(organization)
              var closedCollectionList = await getFinalDocumentByOrg(organization)
              var userList = await getUserByOrg(organization)
              if (inProgressCollectionList && closedCollectionList && userList) {
                self.setState({
                  org: organization,
                  inProgressCollectionList: inProgressCollectionList.docs,
                  closedCollectionList: closedCollectionList,
                  userList: userList.docs
                })
              }
            } else document.location.href = '/#/error'
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getInProgressCollectionContent (inProgressCollectionList) {
    const { t } = this.props
    if (inProgressCollectionList && Array.isArray(inProgressCollectionList)) {
      return (
        <GridContent
          img={inProgressIcon}
          number={inProgressCollectionList.length}
          label={t('inprogress_collections')}
        />
      )
    }
    return <FadeLoader />
  }

  getClosedCollectionContent (closedCollectionList) {
    const { t } = this.props
    if (closedCollectionList) {
      return (
        <GridContent
          img={closedIcon}
          number={closedCollectionList.total_rows}
          label={t('closed_collections')}
          onClick={() => { document.location.href = `/#/${this.state.org}/closed-collections` }}
          gridId='closedCollection'
        />
      )
    }
    return <FadeLoader />
  }

  getUserContent (userList) {
    const { t } = this.props
    if (userList && Array.isArray(userList)) {
      return (
        <GridContent
          img={userIcon}
          number={userList.length}
          label={t('registered_users')}
          onClick={() => { document.location.href = `/#/${this.state.org}/users` }}
          gridId='user'
        />
      )
    }
    return <FadeLoader />
  }

  render () {
    const { t } = this.props
    return (
      <div className='left-dashboard-content'>
        <div>
          <div>
            <span className='subtitle'>{t('collections')}</span>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                {this.getInProgressCollectionContent(this.state.inProgressCollectionList)}
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                {this.getClosedCollectionContent(this.state.closedCollectionList)}
              </Grid>
              {/* <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                <GridContent
                  img={alertIcon}
                  number={25}
                  label='Alertes'
                />
              </Grid> */}
            </Grid>
          </div>
          <div style={styles.nextGridContainer}>
            <span className='subtitle'>{t('users')}</span>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                {this.getUserContent(this.state.userList)}
              </Grid>
              {/* <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                <GridContent
                  img={userIcon}
                  number={15}
                  label='Meilleur du mois'
                  name='Christophe'
                />
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                <GridContent
                  img={userIcon}
                  number={14}
                  label='Le + de collecte fait:'
                  name='Tamby'
                />
              </Grid> */}
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  nextGridContainer: {
    marginTop: 30
  }
}

DashboardContentLeft.propTypes = {
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(DashboardContentLeft))
