import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import SearchBar from './SearchBar'
import AddButton from './AddButton'
import UserListTable from './UserListTable'
import async from 'async'
import {
  withTranslation
} from 'react-i18next'
import {
  countUserByOrgAndGroup,
  getUserByGroupAndPage,
  getLatestClosedCollectionByOrgAndUser,
  countSearchedUserByGroup,
  searchUserByGroupAndPage
} from '../utils/pouchdb'
import FadeLoader from '../components/FadeLoader'
import Pagination from './Pagination'
import EmptyList from './EmptyList'

class UserContentRight extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      group: props.group,
      searchKey: null,
      userGroupNumber: null,
      userByGroup: null,
      selectedPage: 1,
      selectedPageSize: 10,
      loaded: false
    }
  }

  componentDidMount () {
    const self = this
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        userByGroup: self.addLatestCollection(self.props.userListByGroup),
        userGroupNumber: self.props.userGroupNumber
      })
    }
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    const self = this
    if (nextProps.group !== this.props.group || nextProps.userListByGroup !== this.props.userListByGroup) {
      countUserByOrgAndGroup(nextProps.org, nextProps.group).then(function (userGroupNumber) {
        self.setState({
          org: nextProps.org,
          group: nextProps.group,
          userGroupNumber,
          loaded: false,
          selectedPage: 1,
          selectedPageSize: 10
        })
        getUserByGroupAndPage(nextProps.org, nextProps.group, 1, 10).then(function (userByGroup) {
          self.addLatestCollection(userByGroup.docs)
        })
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  addLatestCollection (userByGroup) {
    var self = this
    var org = self.state.org
    var newUserListByGroup = []
    async.eachLimit(userByGroup, 1, function (user, callback) {
      getLatestClosedCollectionByOrgAndUser(org, user.name).then(function (result) {
        var newUser = user
        var dateOfLatestCollection
        if (result.docs.length > 0) {
          dateOfLatestCollection = result.docs[0].startAt
          newUser.dateOfLatestCollection = dateOfLatestCollection
          newUserListByGroup.push(newUser)
          callback()
        } else {
          newUserListByGroup.push(newUser)
          callback()
        }
      }).catch(function (err) {
        console.log(err)
      })
    }, function (err) {
      if (err) {
        console.log(err)
      } else {
        self.setState({
          userByGroup: newUserListByGroup,
          loaded: true
        })
      }
    })
  }

  setPage (page) {
    const self = this
    var searchKey = self.state.searchKey
    self.setState({
      selectedPage: page
    })
    if (searchKey && searchKey !== '') {
      searchUserByGroupAndPage(self.state.org, self.state.group, page, self.state.selectedPageSize, searchKey).then(function (user) {
        self.addLatestCollection(user.docs)
      })
    } else {
      getUserByGroupAndPage(self.state.org, self.state.group, page, self.state.selectedPageSize).then(function (userByGroup) {
        self.addLatestCollection(userByGroup.docs)
      })
    }
  }

  setPageSize (pageSize, page) {
    const self = this
    var searchKey = self.state.searchKey
    self.setState({
      selectedPageSize: pageSize,
      selectedPage: page
    })
    if (searchKey && searchKey !== '') {
      searchUserByGroupAndPage(self.state.org, self.state.group, page, pageSize, searchKey).then(function (user) {
        self.addLatestCollection(user.docs)
      })
    } else {
      getUserByGroupAndPage(self.state.org, self.state.group, page, pageSize).then(function (userByGroup) {
        self.addLatestCollection(userByGroup.docs)
      })
    }
  }

  searchUser (searchKey, pageSize) {
    const self = this
    self.setState({
      searchKey: searchKey
    })
    if (searchKey !== '') {
      countSearchedUserByGroup(self.state.org, self.state.group, searchKey).then(function (userGroupNumber) {
        self.setState({
          selectedPage: 1,
          userGroupNumber,
          loaded: false
        })
        searchUserByGroupAndPage(self.state.org, self.state.group, 1, pageSize, searchKey).then(function (user) {
          self.addLatestCollection(user.docs)
        })
      })
    } else {
      countUserByOrgAndGroup(self.state.org, self.state.group).then(function (userGroupNumber) {
        self.setState({
          selectedPage: 1,
          userGroupNumber,
          loaded: false
        })
        getUserByGroupAndPage(self.state.org, self.state.group, 1, pageSize).then(function (userByGroup) {
          self.addLatestCollection(userByGroup.docs)
        })
      })
    }
  }

  render () {
    const { t } = this.props
    if ((!this.state.userByGroup && !this.state.userGroupNumber) || !this.state.loaded) {
      return (
        <div className='right-standard-content'>
          <div style={styles.content}>
            <SearchBar
              getSearchKey={(key) => this.searchUser(key, this.state.selectedPageSize)}
            />
            <div className='add-button-container'>
              <div style={styles.buttonContent}>
                <AddButton
                  org={this.state.org}
                  group={this.state.group}
                  groupType='user'
                />
              </div>
            </div>
            <FadeLoader />
          </div>
        </div>
      )
    }
    return (
      <div className='right-standard-content'>
        <div style={styles.content}>
          <SearchBar
            getSearchKey={(key) => this.searchUser(key, this.state.selectedPageSize)}
          />
          <div className='add-button-container'>
            <div style={styles.buttonContent}>
              <AddButton
                org={this.state.org}
                group={this.state.group}
                groupType='user'
              />
            </div>
          </div>
          <UserListTable
            org={this.state.org}
            userByGroup={this.state.userByGroup}
            group={this.state.group}
            reloadComponent={() => this.props.reloadComponent()}
          />
          {
            this.state.userByGroup.length === 0
              ? (<EmptyList text={t('empty_group_list')} />)
              : null
          }
          {this.state.loaded && this.state.userGroupNumber > 10
            ? (
              <Pagination
                dataLength={this.state.userGroupNumber}
                setPage={(page) => { this.setPage(page) }}
                setPageSize={(pageSize, page) => { this.setPageSize(pageSize, page) }}
                selectedPage={this.state.selectedPage}
                selectedPageSize={this.state.selectedPageSize}
              />
            )
            : null}
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    paddingTop: 16,
    height: 'calc(100% - 16px)',
    width: '100%',
    maxWidth: 766,
    minWidth: 360
  },
  buttonContent: {
    float: 'right'
  }
}

UserContentRight.propTypes = {
  userListByGroup: PropTypes.array,
  group: PropTypes.string,
  org: PropTypes.string,
  reloadComponent: PropTypes.func
}

export default (withTranslation()(UserContentRight))
