import React, { Component } from 'react'
import '../App.css'
import Header from '../components/Header'

class InProgressCollection extends Component {
  render () {
    return (
      <div className='App'>
        <Header
          enabled='inProgressCollection'
        />
      </div>
    )
  }
}

export default InProgressCollection
