import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import ShowClosedCollectionLeft from './ShowClosedCollectionLeft'
import ShowClosedCollectionRight from './ShowClosedCollectionRight'
import {
  refreshToken
} from '../utils/token'
import {
  getUserInfo
} from '../utils/userInfo'
import {
  getDocumentStructureById,
  getClosedCollectionById
} from '../utils/pouchdb'
import _ from 'lodash'

class ShowClosedCollectionContent extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      selectedClosedCollectionId: props.selectedClosedCollectionId,
      org: props.org,
      oldOrg: props.org,
      data: null
    }
  }

  async componentDidMount () {
    const self = this
    var token = localStorage.getItem('token')
    var organization = this.state.org
    this._isMounted = true
    if (this._isMounted) {
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    const closedColllectionSelected = await getClosedCollectionById(organization, self.state.selectedClosedCollectionId)
                    if (closedColllectionSelected) {
                      var documentStructure = await getDocumentStructureById(organization, closedColllectionSelected.type)
                      var documentIds = []
                      if (documentStructure) {
                        var newDocumentStructure = _.filter(documentStructure.docs[0].properties, function (o) { return o.template === 'embedsOne' })
                        _.forEach(newDocumentStructure, function (value, key) {
                          documentIds.push(value.reference)
                        })
                        self.updateDataStructure(closedColllectionSelected, documentIds)
                      }
                    }
                  }
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              const closedColllectionSelected = await getClosedCollectionById(organization, self.state.selectedClosedCollectionId)
              if (closedColllectionSelected) {
                var documentStructure = await getDocumentStructureById(organization, closedColllectionSelected.type)
                var documentIds = []
                if (documentStructure) {
                  var newDocumentStructure = _.filter(documentStructure.docs[0].properties, function (o) { return o.template === 'embedsOne' })
                  _.forEach(newDocumentStructure, function (value, key) {
                    documentIds.push(value.reference)
                  })
                  self.updateDataStructure(closedColllectionSelected, documentIds)
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.org !== this.props.org) {
      this.setState({
        org: nextProps.org
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  retrieveAttachment (value, attachments) {
    const imgList = []
    if (Array.isArray(value.img)) {
      _.forEach(value.img, function (img) {
        const attachment = attachments[img]
        if (attachment) {
          imgList.push(`data:${attachment.content_type};base64,` + attachment.data)
        }
      })
    } else {
      const attachment = attachments[value.img]
      if (attachment) {
        imgList.push(`data:${attachment.content_type};base64,` + attachment.data)
      }
    }
    return imgList
  }

  normalizePositionOfValidationDocument(data) {
    const d = Object.assign({}, data)
    if (d.hasOwnProperty('endingPosition')) {
      d['signature'] = d.hasOwnProperty('signature') ? Object.assign(d['signature'], {'position': d['endingPosition']}) : {'position': d['endingPosition']}
      delete d.endingPosition
    }
    return d
  }

  updateDataStructure (data, documentIds) {
    data = this.normalizePositionOfValidationDocument(data)
    const self = this
    var dataArray = [
      {
        title: 'DESCRIPTION',
        content: {}
      }
    ]
    for (var key in data) {
      var element = {}
      element.content = {}
      if (key !== '_attachments' && key !== 'position' && typeof (data[key]) === 'object') {
        element.title = key
        for (var k in data[key]) {
          const value = data[key][k]
          if (value && value.type === 'ATTACHMENT') {
            element.content[k] = this.retrieveAttachment(value, data._attachments)
          } else {
            if (Array.isArray(value) && typeof (value[0]) === 'object' && value[0] !== null) {
              if (value[0].type === 'image/png' || value[0].type === 'image/jpeg') {
                element.content[k] = value
              } else {
                const attr = []
                _.forEach(value, function (elem) {
                  if (typeof (elem) === 'object' && elem !== null) {
                    const val = {}
                    for (var i in elem) {
                      if (elem[i] && elem[i].type === 'ATTACHMENT') {
                        val[i] = self.retrieveAttachment(elem[i], data._attachments)
                      } else {
                        val[i] = elem[i]
                      }
                    }
                    attr.push(val)
                  }
                })
                element.content[k] = attr
              }
            } else if (Array.isArray(value)) {
              element.content[k] = value
            } else if (typeof (value) === 'object' && value !== null) {
              const attr = {}
              for (var myKey in value) {
                if (value[myKey] && value[myKey].type === 'ATTACHMENT') {
                  attr[myKey] = this.retrieveAttachment(value[myKey], data._attachments)
                } else if (!(Array.isArray(value[myKey])) && typeof (value[myKey]) === 'object' && value[myKey] !== null) {
                  attr[myKey] = {}
                  for (var l in value[myKey]) {
                    if (value[myKey][l] && value[myKey][l].type === 'ATTACHMENT') {
                      attr[myKey][l] = this.retrieveAttachment(value[myKey][l], data._attachments)
                    } else {
                      if (Array.isArray(value[myKey]) && typeof (value[myKey][0]) === 'object' && value[myKey][0] !== null) {
                        if (value[myKey][0].type === 'image/png' || value[myKey][0].type === 'image/jpeg') {
                          attr[myKey] = value[myKey]
                        } else {
                          attr[myKey][l] = value[myKey][l]
                        }
                      } else {
                        attr[myKey][l] = value[myKey][l]
                      }
                    }
                  }
                } else {
                  attr[myKey] = value[myKey]
                }
              }
              element.content[k] = attr
            } else {
              element.content[k] = value
            }
          }
        }
        if (Object.keys(element.content ).length > 0) {
          dataArray.push(element)
        }
       
      } else {
        if (key !== '_id' && key !== '_rev' && key !== '_attachments') {
          dataArray[0].content[key] = data[key]
        }
      }
    }
    this.setState({
      data: dataArray
    })
    // self.verifyRankOfStep(dataArray, documentIds)
  }

  verifyRankOfStep (dataArray, documentIds) {
    var data = []
    data[0] = dataArray[0]
    data[dataArray.length - 1] = dataArray[dataArray.length - 1]
    for (var i = 0; i < documentIds.length; i++) {
      if (documentIds[i] !== dataArray[i + 1]) {
        var value = _.filter(dataArray, function (o) { return o.title === documentIds[i] })
        data[i + 1] = value[0]
      }
    }
    this.setState({
      data: data
    })
  }

  render () {
    if (this.state.org !== this.state.oldOrg) {
      document.location.href = '/#/error'
      return null
    }
    if (this.state.data) {
      return (
        <div className='container'>
          <div style={styles.content}>
            <ShowClosedCollectionLeft
              collection={this.state.data}
            />
            <ShowClosedCollectionRight
              collection={this.state.data}
            />
          </div>
        </div>
      )
    }
    return null
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%'
  }
}

ShowClosedCollectionContent.propTypes = {
  selectedClosedCollectionId: PropTypes.string,
  org: PropTypes.string
}

export default ShowClosedCollectionContent
