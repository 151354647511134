import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 24
  },
  select: {
    '&:before': {
      borderColor: '#BFBFBF'
    },
    '&:after': {
      borderColor: '#BFBFBF'
    }
  },
  greyColor: {
    color: 'rgba(0,0,0,0.60)'
  },
  redColor: {
    color: '#F44335'
  },
  formControl: {
    width: '100%'
  }
})

class TextFieldComponent extends React.Component {
  getOptions () {
    var options = this.props.options
    var newOptions = []
    for (var i = 1; i < options.length; i++) {
      var newOption = {}
      newOption.value = options[i].value
      newOption.label = options[i].text
      newOptions.push(newOption)
    }
    return newOptions
  }

  render () {
    const options = this.getOptions()
    const { classes } = this.props
    if (this.props.hasError && this.props.value === '') {
      return (
        <div className={classes.root}>
          <FormControl error className={classes.formControl}>
            <Select
              native
              classes={{
                root: classes.redColor,
                icon: classes.redColor
              }}
              value={this.props.value}
              onChange={(evt) => this.props.onChange(evt.target.value)}
            >
              <option value='' />
              {options.map((option) =>
                <option key={option.value} value={option.value}>{option.label}</option>
              )}
            </Select>
          </FormControl>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <FormControl className={classes.formControl}>
            <Select
              native
              classes={{
                root: classes.greyColor
              }}
              className={classes.select}
              value={this.props.value}
              onChange={(evt) => this.props.onChange(evt.target.value)}
            >
              <option value='none'>
                {this.props.label}
              </option>
              {options.map((option) =>
                <option key={option.value} value={option.value}>{option.label}</option>
              )}
            </Select>
          </FormControl>
        </div>
      )
    }
  }
}

TextFieldComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  options: PropTypes.array
}

export default withStyles(styles)(TextFieldComponent)
