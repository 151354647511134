import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import '../css/table.css'
import ascendIcon from '../images/ascendingIcon.svg'
import descendIcon from '../images/descendingIcon.svg'
import showIcon from '../images/showIcon.svg'
import pdfIcon from '../images/pdfIcon.svg'
import Checkbox from '@material-ui/core/Checkbox'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { exportPdf } from '../utils/exporter'
import { Logger } from "react-remote-logger"

moment.locale('fr')

class ClosedCollectionTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      org: props.org,
      closedCollection: props.closedCollection,
      valueKey: '',
      isSorted: false,
      selectedClosedCollectionId: null,
      selectedClosedCollectionType: null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.closedCollection !== this.props.closedCollection) {
      this.setState({
        org: nextProps.org,
        closedCollection: nextProps.closedCollection
      })
    }
  }

  idIsInList (collections) {
    var self = this
    var isInList = true
    _.forEach(collections, function (collection) {
      if (self.props.idList.includes(collection._id)) {
        isInList = true && isInList
      } else {
        isInList = false && isInList
      }
    })
    return isInList
  }

  sort(collections, key) {
    const { t } = this.props
    return _.sortBy(
      collections,
      [collection =>  t(`doctype.${collection[key]}.name`, collection[key])]
    )
  }

  sortByColumn (key) {
    const self = this
    const columnState = !this.state[key]
    this.setState({
      isSorted: true,
      [key]: columnState,
      closedCollection: columnState ? this.sort(self.state.closedCollection, key) : this.sort(self.state.closedCollection, key).reverse(),
      valueKey: key
    })
  }

  showCollection (id, type) {
    Logger({
      'timestamp': (new Date()).toLocaleString(),
      'level': 'debug',
      'user_id': localStorage.user_id,
      'user': localStorage.user_email,
      'organization': localStorage.organization,
      'action': 'GET',
      'collection': id,
      'url': window.location.href,
      'message': `GET ${window.location.href}`
    })
    this.setState({
      selectedClosedCollectionId: id,
      selectedClosedCollectionType: type
    })
  }

  render () {
    const { t } = this.props
    var closedCollection = this.state.closedCollection
    var selectedClosedCollectionId = this.state.selectedClosedCollectionId
    var selectedClosedCollectionType = this.state.selectedClosedCollectionType
    if (selectedClosedCollectionId) {
      localStorage.setItem('organization', this.state.org)
      localStorage.setItem('selectedClosedCollectionId', selectedClosedCollectionId)
      localStorage.setItem('selectedClosedCollectionType', selectedClosedCollectionType)
      return <Redirect
        to={{
          pathname: `/${this.state.org}/closed-collection/${selectedClosedCollectionId}`,
          state: {
            selectedClosedCollectionId: selectedClosedCollectionId,
            selectedClosedCollectionType: selectedClosedCollectionType
          }
        }}
      />
    }
    if (!this.state.isSorted) {
      closedCollection = this.sort(closedCollection, 'startAt').reverse()
    }
    return (
      <div style={styles.content}>
        <table cellSpacing='0' className='table'>
          <thead>
            <tr className='head'>
              <td className='head-border'>
                <Checkbox
                  onChange={(e) => this.props.handleChange(e, closedCollection)}
                  color='default'
                  value='all'
                  checked={this.props.idList.length >= closedCollection.length && this.idIsInList(closedCollection)}
                />
              </td>
              <td
                className='td-left-padding td-left head-border td-outline'
                role='gridcell'
                onClick={
                  () => this.sortByColumn('type')
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <span>{t('collections')}</span>
                {
                  this.state.valueKey === 'type'
                    ? (this.state.type === true
                      ? <img
                        style={styles.icon}
                        src={ascendIcon}
                        alt='true'
                      />
                      : <img
                        style={styles.icon}
                        src={descendIcon}
                        alt='true'
                      />)
                    : <img
                      style={styles.icon}
                      src={descendIcon}
                      alt='true'
                    />
                }
              </td>
              <td
                className='td-left-padding head-center head-border td-outline'
                role='gridcell'
                onClick={
                  () => this.sortByColumn('user')
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <span>{t('users')}</span>
                {
                  this.state.valueKey === 'user'
                    ? (this.state.user === true
                      ? <img
                        style={styles.icon}
                        src={ascendIcon}
                        alt='true'
                      />
                      : <img
                        style={styles.icon}
                        src={descendIcon}
                        alt='true'
                      />)
                    : <img
                      style={styles.icon}
                      src={descendIcon}
                      alt='true'
                    />
                }
              </td>
              <td
                className='td-head-right head-border td-outline'
                role='gridcell'
                onClick={
                  () => this.sortByColumn('startAt')
                }
                tabIndex={0}
                onKeyPress={() => {}}
              >
                <span>{t('creation')}</span>
                {
                  this.state.valueKey === 'startAt'
                    ? (this.state.startAt === true
                      ? <img
                        style={styles.iconRigth}
                        src={ascendIcon}
                        alt='true'
                      />
                      : <img
                        style={styles.iconRigth}
                        src={descendIcon}
                        alt='true'
                      />)
                    : <img
                      style={styles.iconRigth}
                      src={descendIcon}
                      alt='true'
                    />
                }
              </td>
            </tr>
          </thead>
          <tbody>
            {closedCollection.map((collection) =>
              <tr
                key={collection._id}
                className='tr'
              >
                <td className='td'>
                  <Checkbox
                    onChange={(e) => this.props.handleChange(e)}
                    color='default'
                    value={collection._id}
                    checked={this.props.idList.includes(collection._id)}
                  />
                </td>
                <td
                  className='td td-left-padding td-left td-standard'
                >
                  {t(`doctype.${collection.type}.name`, collection.type)}
                </td>
                <td
                  className='td td-left-padding td-center'
                >
                  {collection.user}
                </td>
                {/* <td
                  className='td td-right clickable-td'
                  onClick={() => this.showCollection(collection._id)}
                  tabIndex='0'
                  role='gridcell'
                  onKeyPress={() => {}}
                >
                  {moment.utc(collection.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}
                </td> */}
                <td className='td td-right-standard hover-button'>
                  <div style={styles.contentButton}>
                    <span className='hover-button--off'>
                      {moment.utc(collection.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}
                    </span>
                    <div className='hover-button--on'>
                      <table cellSpacing='0' style={styles.table}>
                        <tbody>
                          <tr>
                            <td style={styles.iconContent}>
                              <div
                                style={{ outline: 'none' }}
                                tabIndex='0'
                                onClick={() => this.showCollection(collection._id, collection.type)}
                                role='button'
                                onKeyPress={() => {}}
                              >
                                <img
                                  style={styles.iconHover}
                                  src={showIcon}
                                  alt='true'
                                />
                              </div>
                            </td>
                            <td style={styles.iconContent}>
                              <div
                                style={{ outline: 'none' }}
                                tabIndex='0'
                                onClick={() =>
                                  exportPdf(this.state.org, collection._id, collection.type.toLowerCase())
                                }
                                role='button'
                                onKeyPress={() => {}}
                              >
                                <img
                                  src={pdfIcon}
                                  style={styles.iconHover}
                                  alt='true'
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

const styles = {
  content: {
    paddingTop: 24,
    width: '100%',
    marginBottom: 10
  },
  icon: {
    float: 'right',
    marginTop: 6,
    marginRight: 4,
    marginLeft: 12
  },
  iconRigth: {
    float: 'right',
    marginTop: 6,
    marginLeft: 12
  },
  contentButton: {
    width: 80,
    float: 'right'
  },
  table: {
    float: 'right'
  },
  iconContent: {
    paddingLeft: 6
  },
  iconHover: {
    cursor: 'pointer'
  }
}

ClosedCollectionTable.propTypes = {
  closedCollection: PropTypes.array,
  idList: PropTypes.array,
  handleChange: PropTypes.func,
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(ClosedCollectionTable))
