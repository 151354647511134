import React, { Component } from 'react'
import '../css/style.css'
import PropTypes from 'prop-types'
import searchIcon from '../images/searchIcon.svg'

class SearchBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchKey: ''
    }
  }

  search () {
    this.props.getSearchKey(this.state.searchKey)
  }

  handleChange (e) {
    this.setState({
      searchKey: e.target.value
    })
    this.props.getSearchKey(e.target.value)
  }

  render () {
    return (
      <div className='container-with-padding'>
        <div style={styles.content}>
          <div
            style={styles.btnSearchBar}
            onClick={() => this.search()}
            tabIndex='0'
            className='button-add-user'
            role='button'
            onKeyPress={() => {}}
          >
            <img style={styles.icon} src={searchIcon} alt='true' />
          </div>
          <div style={styles.inputContent}>
            <input
              style={styles.inputSearchBar}
              type='text'
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    width: '100%'
  },
  inputContent: {
    width: '100%',
    height: 33,
    float: 'rigth'
  },
  btnSearchBar: {
    width: 40,
    float: 'right',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    cursor: 'pointer',
    background: '#EBEBEB',
    height: 34,
    outline: 'none'
  },
  inputSearchBar: {
    width: 'calc(100% - 56px)',
    height: 32,
    paddingLeft: 14,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontSize: 14,
    border: 'none',
    background: '#EBEBEB',
    outline: 'none'
  },
  icon: {
    marginTop: 8,
    marginLeft: 8
  }
}

SearchBar.propTypes = {
  getSearchKey: PropTypes.func
}

export default SearchBar
