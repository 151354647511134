import React from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'

class EnabledTab extends React.Component {
  render () {
    return (
      <div className='enabled-section'>
        <button className='menu-button'>
          <span className='enabled-label-menu'>{this.props.label}</span>
        </button>
      </div>
    )
  }
}

EnabledTab.propTypes = {
  label: PropTypes.string
}

export default EnabledTab
