import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import SearchBar from './SearchBar'
import AddButton from './AddButton'
import DeviceTable from './DeviceTable'
import Pagination from './Pagination'
import _ from 'lodash'
import {
  DEVICE_BY_ORG,
  DEVICE_BY_ORG_AND_PAGE
} from '../query/device'
import { client } from '../graphql/client'
import FadeLoader from '../components/FadeLoader'
import { withTranslation } from 'react-i18next'

class DeviceContent extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      devices: null,
      deviceNumber: null,
      activeDevice: 0,
      searchKey: null,
      selectedPage: 1,
      selectedPageSize: 10
    }
  }

  componentDidMount () {
    this._isMounted = true
    if (this._isMounted) {
      this.countActiveDevice(this.state.org)
      this.countDeviceList(this.state.org)
      this.getDeviceListByPage(this.state.org, this.state.selectedPage - 1, this.state.selectedPageSize)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const self = this
    if (nextProps.org !== self.props.org) {
      self.setState({
        org: nextProps.org,
        devices: null
      })
      self.countActiveDevice(nextProps.org)
      self.countDeviceList(nextProps.org)
      self.getDeviceListByPage(nextProps.org, self.state.selectedPage - 1, self.state.selectedPageSize)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getDeviceListByPage (org, offset, limit) {
    client
      .query({
        query: DEVICE_BY_ORG_AND_PAGE,
        variables: { org: org, offset: offset, limit: limit },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        var devices = result.data.devicesByCollection
        this.setState({
          devices: devices
        })
      }).catch(error => console.log(error))
  }

  searchDeviceList (org, page, pageSize, searchKey) {
    client
      .query({
        query: DEVICE_BY_ORG,
        variables: { org: org },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        var devices = result.data.devicesByCollection
        devices = _.filter(devices, function (o) { return o.label.toLowerCase().includes(searchKey.toLowerCase()) })
        var deviceNumber = devices.length
        var firstIndex = (page - 1) * pageSize
        var lastIndex = page * pageSize
        var newDevices = devices.slice(firstIndex, lastIndex)
        this.setState({
          deviceNumber: deviceNumber,
          devices: newDevices
        })
      }).catch(error => console.log(error))
  }

  countDeviceList (org) {
    client
      .query({
        query: DEVICE_BY_ORG,
        variables: { org: org },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        var devices = result.data.devicesByCollection
        this.setState({
          deviceNumber: devices.length
        })
      }).catch(error => console.log(error))
  }

  reloadComponent () {
    this.countDeviceList(this.state.org)
    this.getDeviceListByPage(this.state.org, this.state.selectedPage - 1, this.state.selectedPageSize)
  }

  countActiveDevice (org) {
    client
      .query({
        query: DEVICE_BY_ORG,
        variables: { org: org },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        var devices = result.data.devicesByCollection
        var activeDevice = _.filter(devices, function (o) { return o.isActive })
        this.setState({
          activeDevice: activeDevice.length
        })
      }).catch(error => console.log(error))
  }

  setPage (page) {
    const self = this
    var searchKey = self.state.searchKey
    var offset = (page - 1) * self.state.selectedPageSize
    self.setState({
      selectedPage: page
    })
    if (searchKey && searchKey !== '') {
      self.searchDeviceList(self.state.org, page, self.state.selectedPageSize, searchKey)
    } else {
      self.getDeviceListByPage(self.state.org, offset, self.state.selectedPageSize)
    }
  }

  setPageSize (pageSize, page) {
    const self = this
    var searchKey = self.state.searchKey
    var offset = (page - 1) * pageSize
    self.setState({
      selectedPageSize: pageSize,
      selectedPage: page
    })
    if (searchKey && searchKey !== '') {
      self.searchDeviceList(self.state.org, page, pageSize, searchKey)
    } else {
      self.getDeviceListByPage(self.state.org, offset, pageSize)
    }
  }

  searchDevice (searchKey, pageSize) {
    const self = this
    self.setState({
      searchKey: searchKey,
      selectedPage: 1
    })
    if (searchKey !== '') {
      self.searchDeviceList(self.state.org, 1, pageSize, searchKey)
    } else {
      var offset = 0
      self.countDeviceList(this.state.org)
      self.getDeviceListByPage(self.state.org, offset, pageSize)
    }
  }

  render () {
    const { t } = this.props
    if (!this.state.devices || !this.state.deviceNumber) {
      if (this.state.devices && this.state.devices.length === 0) {
        return (
          <div className='container'>
            <div style={styles.content}>
              <div style={styles.deviceContent}>
                <SearchBar getSearchKey={(key) => this.searchDevice(key, this.state.selectedPageSize)} />
                <div className='add-button-container'>
                  <div style={styles.buttonContent}>
                    <AddButton
                      org={this.state.org}
                      groupType='device'
                    />
                  </div>
                </div>
                <div className='device-number'>
                  <span>{t('activated_device')}: {this.state.activeDevice}</span>
                </div>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div className='container'>
          <div style={styles.content}>
            <div style={styles.deviceContent}>
              <SearchBar getSearchKey={(key) => this.searchDevice(key, this.state.selectedPageSize)} />
              <div className='add-button-container'>
                <div style={styles.buttonContent}>
                  <AddButton
                    org={this.state.org}
                    groupType='device'
                  />
                </div>
              </div>
              <div className='device-number'>
                <span>{t('activated_device')}: {this.state.activeDevice}</span>
              </div>
              <FadeLoader />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='container'>
        <div style={styles.content}>
          <div style={styles.deviceContent}>
            {/* <SearchBar getSearchKey={(key) => this.setState({ searchKey: key })} /> */}
            <SearchBar getSearchKey={(key) => this.searchDevice(key, this.state.selectedPageSize)} />
            <div className='add-button-container'>
              <div style={styles.buttonContent}>
                <AddButton
                  org={this.state.org}
                  groupType='device'
                />
              </div>
            </div>
            <div className='device-number'>
              <span>{t('activated_device')}: {this.state.activeDevice}</span>
            </div>
            <DeviceTable
              org={this.state.org}
              devices={this.state.devices}
              // searchKey={this.state.searchKey}
              reloadComponent={() => this.reloadComponent()}
            />
            {this.state.deviceNumber > 10
              ? (
                <Pagination
                  dataLength={this.state.deviceNumber}
                  setPage={(page) => { this.setPage(page) }}
                  setPageSize={(pageSize, page) => { this.setPageSize(pageSize, page) }}
                  selectedPage={this.state.selectedPage}
                  selectedPageSize={this.state.selectedPageSize}
                />
              )
              : null}
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto'
  },
  deviceContent: {
    paddingTop: 24,
    width: '100%',
    margin: 'auto',
    maxWidth: 766,
    minWidth: 360
  },
  buttonContent: {
    float: 'right'
  }
}

DeviceContent.propTypes = {
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(DeviceContent))
