import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import DataContentLeft from './DataContentLeft'
import DataContentRight from './DataContentRight'
import {
  refreshToken
} from '../utils/token'
import {
  getUserInfo
} from '../utils/userInfo'
import {
  getListType
} from '../utils/pouchdb'

class DataContent extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      typeList: null,
      groupId: props.dataGroupId ? props.dataGroupId : null,
      groupName: props.dataGroupName ? props.dataGroupName : null,
      orgIsChanged: false
    }
  }

  componentDidMount () {
    const self = this
    var token = localStorage.getItem('token')
    var organization = this.state.org
    this._isMounted = true
    if (this._isMounted) {
      getUserInfo(token)
        .then(response => response.json())
        .then((data) => {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then((data) => {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    getListType(organization)
                      .then(typeList => {
                        self.setState({
                          typeList: typeList.docs
                        })
                      })
                  }
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              getListType(organization)
                .then(typeList => {
                  self.setState({
                    typeList: typeList.docs
                  })
                })
            }
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.org !== this.props.org) {
      var organization = nextProps.org
      var token = localStorage.getItem('token')
      getUserInfo(token)
        .then(response => response.json())
        .then((data) => {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then((data) => {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    getListType(organization)
                      .then(typeList => {
                        this.setState({
                          typeList: typeList.docs,
                          org: nextProps.org,
                          orgIsChanged: true
                        })
                      })
                  } else document.location.href = '/#/error'
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              getListType(organization)
                .then(typeList => {
                  this.setState({
                    typeList: typeList.docs,
                    org: nextProps.org,
                    orgIsChanged: true
                  })
                })
            } else document.location.href = '/#/error'
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onClickHandler (groupId, groupName) {
    this.setState({
      groupId: groupId,
      groupName: groupName,
      orgIsChanged: false
    })
  }

  getGroupId (groupId, typeList) {
    if (groupId) {
      if (this.state.orgIsChanged) {
        return typeList[0]._id
      }
      return groupId
    }
    return typeList[0]._id
  }

  getGroupName (groupName, typeList) {
    if (groupName) {
      if (this.state.orgIsChanged) {
        return typeList[0].name
      }
      return groupName
    }
    return typeList[0].name
  }

  render () {
    if (!this.state.typeList) {
      return null
    }
    return (
      <div className='container'>
        <div style={styles.content}>
          <DataContentLeft
            typeList={this.state.typeList}
            onClickHandler={(groupId, groupName) => { this.onClickHandler(groupId, groupName) }}
            groupId={this.getGroupId(this.state.groupId, this.state.typeList)}
          />
          <DataContentRight
            org={this.state.org}
            groupId={this.getGroupId(this.state.groupId, this.state.typeList)}
            groupName={this.getGroupName(this.state.groupName, this.state.typeList)}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%'
  }
}

DataContent.propTypes = {
  dataGroupId: PropTypes.string,
  dataGroupName: PropTypes.string,
  org: PropTypes.string
}

export default DataContent
