import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'

class GridContent extends Component {
  render () {
    return (
      <div
        className='grid-content'
        onClick={this.props.onClick}
        tabIndex={this.props.gridId}
        role='button'
        onKeyPress={() => {}}
      >
        <div>
          <img style={styles.icon} src={this.props.img} alt='' /><br />
          <span style={styles.number}>{this.props.number}</span>
          <div style={styles.labelContent}>
            <span style={styles.label}>{this.props.label}</span><br />
            <span style={styles.name}>{this.props.name}</span>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  icon: {
    width: 23,
    height: 27,
    marginTop: 24,
    marginLeft: 20,
    marginBottom: 28
  },
  number: {
    marginLeft: 20,
    color: '#414141',
    fontweight: 300,
    fontSize: 40
  },
  labelContent: {
    marginTop: 12
  },
  label: {
    marginLeft: 20,
    color: '#414141',
    fontSize: 16,
    fontweight: 400
  },
  name: {
    marginLeft: 20,
    color: '#414141',
    fontSize: 16,
    fontweight: 400
  }
}

GridContent.propTypes = {
  img: PropTypes.string,
  number: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  gridId: PropTypes.string
}

export default GridContent
