import React from 'react'
import PropTypes from 'prop-types'
import config from '../../config'
import { v4 as uuidv4 } from 'uuid'

class Login extends React.Component {
  componentDidMount () {
    if (!this.props.location.state) {
      window.location.replace(config.redirect_uri)
    } else {
      var state = uuidv4()
      localStorage.setItem(state, this.props.location.state.referrer)
      const authenticationRequest = `${config.authRequest}?response_type=code&client_id=${config.clientId}&redirect_uri=${encodeURIComponent(config.redirect_uri)}&state=${state}`
      window.location.replace(authenticationRequest)
    }
  }

  render () {
    return null
  }
}

Login.propTypes = {
  location: PropTypes.object
}

export default Login
