import React from 'react'
import L from 'leaflet'
import {
  Map,
  TileLayer,
  LayersControl,
  Marker
} from 'react-leaflet'
import PropTypes from 'prop-types'
import 'leaflet/dist/leaflet.css'
import './../css/mapStyle.css'
import mapMarker from '../images/mapMarker.png'

const { BaseLayer } = LayersControl
const tileProvider =
  {
    id: 1,
    name: 'Humanitarian OSM',
    visible: true,
    url: 'https://tiles.tag-ip.com/osm_hot/{z}/{x}/{y}.png'
  }

class Position extends React.Component {
  getTileProviderRender () {
    return (
      <BaseLayer name={tileProvider.name} checked={tileProvider.visible} key={tileProvider.id}>
        <TileLayer
          url={tileProvider.url}
        />
      </BaseLayer>
    )
  }

  render () {
    var position = typeof this.props.position !== 'string' ? this.props.position : {latitude: this.props.position.split(',')[0], longitude: this.props.position.split(',')[1]}
    return (
      <div>
        <div style={styles.contentMap}>
          <div id='map' style={styles.map}>
            <Map
              ref='map'
              center={[position.latitude, position.longitude]} zoom={16}
              zoomControl={false}
              minZoom={1}
              maxZoom={18}
            >
              <LayersControl position='bottomleft'>
                {this.getTileProviderRender()}
              </LayersControl>
              <Marker
                position={[position.latitude, position.longitude]}
                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${position.latitude},${position.longitude}`, '_blank')}
                icon={
                  L.icon(
                    {
                      iconUrl: `${mapMarker}`,
                      iconSize: [20, 20],
                      iconAnchor: [10, 10]
                    }
                  )
                }
              />
            </Map>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  contentMap: {
    height: '58px',
    width: '100%'
  },
  map: {
    marginTop: '8px',
    height: '58px',
    borderRadius: '2px',
    border: 'none'
  }
}

Position.propTypes = {
  position: PropTypes.object
}

export default Position
