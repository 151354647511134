import React from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import disabledPreviousIcon from '../images/disabledPreviousIcon.svg'
import disabledNextIcon from '../images/disabledNextIcon.svg'
import disabledStartIcon from '../images/disabledStartIcon.svg'
import disabledEndIcon from '../images/disabledEndIcon.svg'
import enabledPreviousIcon from '../images/enabledPreviousIcon.svg'
import enabledNextIcon from '../images/enabledNextIcon.svg'
import enabledStartIcon from '../images/enabledStartIcon.svg'
import enabledEndIcon from '../images/enabledEndIcon.svg'
import { withTranslation } from 'react-i18next'

const propTypes = {
  t: PropTypes.func,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  dataLength: PropTypes.number,
  selectedPage: PropTypes.number,
  selectedPageSize: PropTypes.number
}

class Pagination extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedPageSize: 10,
      selectedPageNumber: 1,
      dataLength: props.dataLength
    }
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.dataLength !== this.props.dataLength || nextProps.selectedPage !== this.props.selectedPage) {
      this.setState({
        selectedPageNumber: nextProps.selectedPage,
        selectedPageSize: nextProps.selectedPageSize,
        dataLength: nextProps.dataLength
      })
    }
  }

  setPage (page) {
    this.setState({
      selectedPageNumber: page
    })
    this.props.setPage(page)
  }

  setPageSize (pageSize) {
    this.setState({
      selectedPageSize: pageSize,
      selectedPageNumber: 1
    })
    this.props.setPageSize(pageSize, 1)
  }

  getMaxPage () {
    var dataLength = this.state.dataLength
    var selectedPageSize = this.state.selectedPageSize
    var maxPage
    if (dataLength > selectedPageSize) {
      maxPage = dataLength / selectedPageSize
      if (Number(maxPage) === maxPage && maxPage % 1 !== 0) {
        maxPage = parseInt(dataLength / selectedPageSize) + 1
      }
    } else {
      maxPage = 1
    }
    return maxPage
  }

  createPageList (firstIndex, size) {
    var pageList = []
    for (var i = firstIndex; i <= size; ++i) {
      pageList[i - 1] = i
    }
    return pageList
  }

  getPageListToShow (maxPage) {
    var selectedPage = this.state.selectedPageNumber
    var pageList
    var numberListPage = maxPage / 10
    var myMaxPage = maxPage < 10 ? maxPage : 10
    pageList = this.createPageList(1, myMaxPage)
    if (numberListPage > 1 && selectedPage > 10) {
      pageList = this.createPageList(selectedPage - 9, selectedPage)
    }
    return pageList
  }

  pageContent () {
    var pageList = this.getPageListToShow(this.getMaxPage())
    return (
      <div>
        <table style={styles.tableCenter}>
          <tbody>
            <tr>
              {pageList.map((page, index) =>
                <td
                  key={index}
                  className='pagination-page-font-size'
                  style={this.state.selectedPageNumber === page ? styles.selectedPage : styles.unselectedPage}
                  tabIndex={index}
                  onClick={() => {
                    if (this.state.selectedPageNumber !== page) {
                      this.setPage(page)
                    }
                  }}
                  role='gridcell'
                  onKeyPress={() => {}}
                >
                  {page}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTableCenter () {
    return (
      <div>
        <table style={styles.tableCenter}>
          <tbody>
            <tr>
              <td
                style={styles.previousContent}
                onClick={() => {
                  if (this.state.selectedPageNumber > 1) {
                    this.setPage(this.state.selectedPageNumber - 1)
                  }
                }}
                role='gridcell'
                onKeyPress={() => {}}
              >
                {this.state.selectedPageNumber > 1
                  ? (
                    <img
                      style={styles.img}
                      src={enabledPreviousIcon}
                      alt='true'
                    />
                  )
                  : (
                    <img
                      style={styles.img}
                      src={disabledPreviousIcon}
                      alt='true'
                    />
                  )}
              </td>

              <td style={styles.pageContent}>
                {this.pageContent()}
              </td>

              <td
                style={styles.nextContent}
                onClick={() => {
                  if (this.state.selectedPageNumber < this.getMaxPage()) {
                    this.setPage(this.state.selectedPageNumber + 1)
                  }
                }}
                role='gridcell'
                onKeyPress={() => {}}
              >
                {this.state.selectedPageNumber < this.getMaxPage()
                  ? (
                    <img
                      style={styles.img}
                      src={enabledNextIcon}
                      alt='true'
                    />
                  )
                  : (
                    <img
                      style={styles.img}
                      src={disabledNextIcon}
                      alt='true'
                    />
                  )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable () {
    const { t } = this.props
    return (
      <div>
        <table
          cellSpacing='0'
          cellPadding='0'
          style={styles.table}
        >
          <tbody>
            <tr>
              <td style={styles.tdLeft}>
                <div
                  style={{ outline: 'none' }}
                  tabIndex='0'
                  onClick={() => {
                    if (this.state.selectedPageNumber > 1) {
                      this.setPage(1)
                    }
                  }}
                  role='button'
                  onKeyPress={() => {}}
                >
                  {this.state.selectedPageNumber > 1
                    ? (
                      <img
                        style={styles.img}
                        src={enabledStartIcon}
                        alt='true'
                      />
                    )
                    : (
                      <img
                        style={styles.img}
                        src={disabledStartIcon}
                        alt='true'
                      />
                    )}
                </div>
              </td>

              <td style={styles.tdCenter}>
                {this.renderTableCenter()}
              </td>

              <td style={styles.tdRight}>
                <div style={styles.tdRightContent}>
                  <div className='pagination-page-font-size' style={styles.labelStateContent}>
                    {
                      this.state.selectedPageNumber === 1
                        ? <span>{this.state.selectedPageNumber}-{this.state.selectedPageSize} {t('on')} {this.state.dataLength}</span>
                        : <span>{((this.state.selectedPageNumber - 1) * this.state.selectedPageSize) + 1}-{this.state.selectedPageSize * this.state.selectedPageNumber} {t('on')} {this.state.dataLength}</span>
                    }
                  </div>
                  <div
                    style={{ outline: 'none' }}
                    tabIndex='0'
                    onClick={() => {
                      if (this.state.selectedPageNumber < this.getMaxPage()) {
                        this.setPage(this.getMaxPage())
                      }
                    }}
                    role='button'
                    onKeyPress={() => {}}
                  >
                    {this.state.selectedPageNumber < this.getMaxPage()
                      ? (
                        <img
                          style={styles.img}
                          src={enabledEndIcon}
                          alt='true'
                        />
                      )
                      : (
                        <img
                          style={styles.img}
                          src={disabledEndIcon}
                          alt='true'
                        />
                      )}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderPageSizeCenter () {
    return (
      <div>
        <table
          cellSpacing='0'
          cellPadding='0'
          style={styles.tableCenter}
        >
          <tbody>
            <tr>
              {this.state.selectedPageSize === 10
                ? (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.selectedPageSizeLeft}
                  >
                    10
                  </td>
                )
                : (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.pageSizeLeft}
                    tabIndex='0'
                    onClick={() => {
                      this.setPageSize(10)
                    }}
                    role='gridcell'
                    onKeyPress={() => {}}
                  >
                    10
                  </td>
                )}

              {this.state.selectedPageSize === 20
                ? (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.selectedPageSizeCenter}
                  >
                    20
                  </td>
                )
                : (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.pageSizeCenter}
                    tabIndex='0'
                    onClick={() => {
                      this.setPageSize(20)
                    }}
                    role='gridcell'
                    onKeyPress={() => {}}
                  >
                    20
                  </td>
                )}

              {this.state.selectedPageSize === 50
                ? (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.selectedPageSizeCenter}
                  >
                    50
                  </td>
                )
                : (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.pageSizeCenter}
                    tabIndex='0'
                    onClick={() => {
                      this.setPageSize(50)
                    }}
                    role='gridcell'
                    onKeyPress={() => {}}
                  >
                    50
                  </td>
                )}

              {this.state.selectedPageSize === 100
                ? (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.selectedPageSizeRight}
                  >
                    100
                  </td>
                )
                : (
                  <td
                    className='pagination-page-size-font-size'
                    style={styles.pageSizeRight}
                    tabIndex='0'
                    onClick={() => {
                      this.setPageSize(100)
                    }}
                    role='gridcell'
                    onKeyPress={() => {}}
                  >
                    100
                  </td>
                )}

            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderPageSize () {
    const { t } = this.props
    return (
      <div>
        <table
          cellSpacing='0'
          cellPadding='0'
          style={styles.table}
        >
          <tbody>
            <tr>
              <td style={styles.tdLeft} />
              <td style={styles.tdCenter}>
                {this.renderPageSizeCenter()}
              </td>
              <td style={styles.tdPageSizeRight}>
                <span className='pagination-page-font-size' style={styles.labelPageSize}>
                  {t('items_per_page')}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render () {
    return (
      <div className='pagination-container' style={styles.container}>
        <hr style={styles.hr} />
        {this.renderTable()}
        {this.renderPageSize()}
      </div>
    )
  }
}

const styles = {
  container: {
    height: 112,
    marginBottom: 10
  },
  hr: {
    border: '1px solid #4A4A4A',
    marginTop: 32,
    width: 'calc(100% - 2px)'
  },
  table: {
    marginTop: 10,
    width: '100%',
    height: 40
  },
  tr: {
    width: '100%'
  },
  tdLeft: {
    width: '30%',
    textAlign: 'left'
  },
  tdCenter: {
    width: '40%',
    textAlign: 'center'
  },
  tdRight: {
    width: '30%',
    textAlign: 'right'
  },
  previousContent: {
    width: '15%',
    minWidth: 27,
    textAlign: 'left'
  },
  pageContent: {
    width: '70%'
  },
  selectedPage: {
    borderBottom: '2px solid #E02020',
    color: '#414141',
    fontWeight: 600,
    cursor: 'pointer',
    outline: 'none'
  },
  unselectedPage: {
    color: '#414141',
    fontWeight: 400,
    cursor: 'pointer',
    outline: 'none'
  },
  nextContent: {
    width: '15%',
    minWidth: 27,
    textAlign: 'right'
  },
  tdRightContent: {
    display: 'flex',
    float: 'right',
    height: 32
  },
  img: {
    cursor: 'pointer',
    outline: 'none',
    width: 27,
    height: 28
  },
  tableCenter: {
    height: 32,
    width: '100%'
  },
  pageSizeLeft: {
    border: '1px solid #979797',
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    width: '25%',
    color: '#95B3C8',
    fontWeight: 500,
    cursor: 'pointer',
    outline: 'none'
  },
  pageSizeCenter: {
    borderTop: '1px solid #979797',
    borderBottom: '1px solid #979797',
    borderRight: '1px solid #979797',
    width: '25%',
    color: '#95B3C8',
    fontWeight: 500,
    cursor: 'pointer',
    outline: 'none'
  },
  pageSizeRight: {
    borderTop: '1px solid #979797',
    borderBottom: '1px solid #979797',
    borderRight: '1px solid #979797',
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    width: '25%',
    color: '#95B3C8 ',
    fontWeight: 500,
    cursor: 'pointer',
    outline: 'none'
  },
  selectedPageSizeLeft: {
    border: '1px solid #979797',
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    width: '25%',
    backgroundColor: '#748591',
    color: '#272D31',
    fontWeight: 600,
    cursor: 'pointer',
    outline: 'none'
  },
  selectedPageSizeCenter: {
    borderTop: '1px solid #979797',
    borderBottom: '1px solid #979797',
    borderRight: '1px solid #979797',
    width: '25%',
    backgroundColor: '#748591',
    color: '#272D31',
    fontWeight: 600,
    cursor: 'pointer',
    outline: 'none'
  },
  selectedPageSizeRight: {
    borderTop: '1px solid #979797',
    borderBottom: '1px solid #979797',
    borderRight: '1px solid #979797',
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    width: '25%',
    backgroundColor: '#748591',
    color: '#272D31',
    fontWeight: 600,
    cursor: 'pointer',
    outline: 'none'
  },
  tdPageSizeRight: {
    width: '30%',
    textAlign: 'left'
  },
  labelStateContent: {
    textAlign: 'center',
    paddingTop: 6,
    paddingRight: 20,
    color: '#484848',
    fontWeight: 500
  },
  labelPageSize: {
    marginLeft: 10,
    color: '#797979',
    fontWeight: 500
  }

}

Pagination.propTypes = propTypes

export default (withTranslation()(Pagination))
