import React from 'react'
import PropTypes from 'prop-types'
import '../css/header.css'
import backIcon from '../images/backIcon.svg'
import downloadIcon from '../images/downloadIcon.svg'
import { Redirect } from 'react-router-dom'
import { exportPdf } from '../utils/exporter'

class ShowClosedCollectionHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      org: localStorage.getItem('organization'),
      backIsClicked: false
    }
  }

  uploadPdf (id, type) {
    exportPdf(this.state.org, id, type.toLowerCase())
  }

  render () {
    if (this.state.backIsClicked) {
      localStorage.setItem('isBackedOnShow', 'ok')
      var closedCollections = `/${this.state.org}/closed-collections`
      return (
        <Redirect to={closedCollections} />
      )
    }
    return (
      <header className='header-closed-collection'>
        <div style={styles.content}>
          <div
            className='left-header-content'
            tabIndex='0'
            onClick={() => {
              this.setState({ backIsClicked: true })
            }}
            role='button'
            onKeyPress={() => {}}
          >
            <img
              src={backIcon}
              alt=''
              style={styles.icon}
            />
          </div>
          <div className='right-header-content'>
            <span style={styles.title}>Consultation</span>
            <div
              style={styles.pdfButtonContent}
            >
              <button
                onClick={() => this.uploadPdf(this.props.selectedClosedCollectionId, this.props.selectedClosedCollectionType)}
                style={styles.pdfButton}
              >
                <span>PDF</span>
                <img
                  src={downloadIcon}
                  alt=''
                  style={styles.downloadIcon}
                />
              </button>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

const styles = {
  content: {
    paddingTop: 10,
    height: 'calc(100% - 8px)'
  },
  icon: {
    float: 'left'
  },
  title: {
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 600,
    color: ' #414141'
  },
  pdfButtonContent: {
    marginTop: -6,
    marginRight: 20,
    float: 'right'
  },
  pdfButton: {
    paddingLeft: 14,
    textAlign: 'left',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E02020',
    borderRadius: 18,
    width: 76,
    height: 30,
    color: '#DA262E',
    fontWeight: 400,
    fontSize: 16,
    outline: 'none',
    cursor: 'pointer'
  },
  downloadIcon: {
    marginRight: 5,
    marginLeft: 2,
    float: 'right'
  }
}

ShowClosedCollectionHeader.propTypes = {
  selectedClosedCollectionId: PropTypes.string
}

export default ShowClosedCollectionHeader
