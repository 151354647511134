import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import config from '../config'

const httpLink = createHttpLink({
  uri: config.graphql_api
})

const authLink = setContext((_, { headers }) => {
  const key = config.api_key
  return {
    headers: {
      ...headers,
      'x-api-key': key
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})
