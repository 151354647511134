import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../App.css'
import Header from '../components/Header'
import DataContent from '../components/DataContent'
import { Logger } from "react-remote-logger"

class Data extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dataGroupName: props.location.state && props.location.state.dataGroupName ? props.location.state.dataGroupName : null,
      dataGroupId: props.location.state && props.location.state.dataGroupId ? props.location.state.dataGroupId : null
    }
  }

  componentDidMount() {
    Logger({
      'timestamp': (new Date()).toLocaleString(),
      'level': 'info',
      'user_id': localStorage.user_id,
      'user': localStorage.user_email,
      'organization': localStorage.organization,
      'action': 'GET',
      'url': window.location.href,
      'message': `GET ${window.location.href}`
    })
  }

  render () {
    return (
      <div className='App'>
        <Header
          enabled='data'
          org={this.props.match.params.org}
        />
        <DataContent
          dataGroupId={this.state.dataGroupId}
          dataGroupName={this.state.dataGroupName}
          org={this.props.match.params.org}
        />
      </div>
    )
  }
}

Data.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
}

export default Data
