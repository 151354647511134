import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import DashboardContentLeft from './DashboardContentLeft'
// import DashboardContentRight from './DashboardContentRight'

class DashboardContent extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.org !== this.props.org) {
      this.setState({
        org: nextProps.org
      })
    }
  }

  render () {
    return (
      <div className='container'>
        <div style={styles.content}>
          <DashboardContentLeft
            org={this.state.org}
          />
          {/* <DashboardContentRight /> */}
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%'
  }
}

DashboardContent.propTypes = {
  org: PropTypes.string
}

export default DashboardContent
