import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import '../css/style.css'
import Grid from '@material-ui/core/Grid'
import FadeLoader from '../components/FadeLoader'
import Position from '../components/Position'
import _ from 'lodash'
import validDataUrl from 'valid-data-url'
import Modal from 'react-responsive-modal'
import { getOptions } from '../utils/pouchdb'
import { withTranslation } from 'react-i18next'
import config from '../config'
import i18n from '../i18n'

moment.locale('fr')

class ShowClosedCollectionRight extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      collection: null,
      openModal: false,
      org: localStorage.getItem('organization'),
      imgSrc: null,
      options: null,
      selectedLanguageValue: 'fr'
    }
  }

  async componentDidMount() {
    var optionsList = await getOptions(this.state.org)
    this._isMounted = true
    if (this._isMounted) {
      this.setState({
        collection: this.props.collection,
        options: optionsList.docs[0]
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onOpenModal(value) {
    this.setState({
      openModal: true,
      imgSrc: value
    })
  }

  onCloseModal() {
    var self = this
    self.setState({
      openModal: false,
      imgSrc: null
    })
  }

  showAttachment(key, value) {
    this.onOpenModal(value)
  }

  showImage(key, value) {
    this.onOpenModal(this.getAbsoluteFilePath(value.path, value.filename))
  }

  getAbsoluteFilePath(relativePath, filename) {
    return `${config.webdav_url}/${relativePath}/${filename}`
  }

  renderImage(fielId, content) {
    return (
      <div className='img-content'>
        <span style={fielId.indexOf('visa') > -1 ? styles.labelImg : styles.label}>{this.getLabel(this.state.options, fielId)}</span><br />
        <div style={styles.imgList}>
          {content.map((value, key) => (
            <div
              key={key}
              style={styles.imgSection}
              onClick={() => this.showImage(key, value)}
              tabIndex='0'
              role='button'
              onKeyPress={() => { }}
            >
              <img
                key={key}
                style={styles.img}
                src={this.getAbsoluteFilePath(value.path, value.filename)}
                alt=''
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderAttachment(fielId, content) {
    return (
      <div className='img-content'>
        <span style={fielId.indexOf('visa') > -1 ? styles.labelImg : styles.label}>{this.getLabel(this.state.options, fielId)}</span><br />
        <div style={styles.imgList}>
          {content.map((value, key) => (
            <div
              key={key}
              style={styles.imgSection}
              onClick={() => this.showAttachment(key, value)}
              tabIndex='0'
              role='button'
              onKeyPress={() => { }}
            >
              <img
                key={key}
                style={styles.img}
                src={value}
                alt=''
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  embedsManyContent(fielId, content) {
    const self = this
    const views = []
    _.forEach(content, function (value, key) {
      if (typeof (value) === 'string' || Number.isInteger(value) || (!isNaN(value) && value.toString().indexOf('.') !== -1)) {
        views.push(
          <div key={key} style={styles.embedsManyRenderValue}>
            <Grid container spacing={2}> 
              {self.renderValue(key, value)}
            </Grid>
          </div>
        )
      } else {
        if (Array.isArray(value)) {
          if (typeof (value[0]) === 'object' && (value[0].type === 'image/png' || value[0].type === 'image/jpeg')) {
            views.push(
              <div key={key}>
                <div key={key}>
                  <div style={styles.embedsManyImg}>
                    {self.renderImage(key, value)}
                  </div>
                </div>
              </div>)
          }
        } else if (typeof (value) === 'object') {
          if (typeof (value[0]) === 'string' && validDataUrl(value[0])) {
            views.push(
              <div key={key}>
                <div key={key}>
                  <div style={styles.embedsManyImg}>
                    {self.renderImage(key, value)}
                  </div>
                </div>
              </div>
            )
          } else {
            views.push(
               <Grid container spacing={2}>
                {self.renderObject(key, value)}
              </Grid>
            )
          }
        } else {
          for (var k in value) {
            if (Array.isArray(value[k])) {
              views.push(self.renderList(fielId, value[k]))
            }
          }
        }
      }
    })
    return views
  }

  renderList(fielId, content) {
    const views = []
    if (typeof (content[0]) === 'object' && (content[0].type === 'image/png' || content[0].type === 'image/jpeg')) {
      views.push(
        <div key={fielId}>
          <div key={fielId}>
            <div style={styles.imgContainer}>
              {this.renderImage(fielId, content)}
            </div>
          </div>
        </div>)
    } else if (typeof (content[0]) === 'string' && validDataUrl(content[0])) {
      views.push(
        <div key={fielId}>
          <div key={fielId}>
            <div style={styles.imgContainer}>
              {this.renderAttachment(fielId, content)}
            </div>
          </div>
        </div>
      )
    } else {
      const self = this
      var myContent
      if (typeof (content[0]) === 'object') {
        myContent = content
        for (let i = 0; i < myContent.length; i++) {
          if (self.getType(this.state.options, fielId) === 'embedsMany') {
            views.push(
              <div key={i} style={styles.embedsManyContent}>
                {/* <Grid key={i} container spacing={7}> */}
                  {self.embedsManyContent(fielId, myContent[i])}
                {/* </Grid> */}
              </div>
            )
          } else {
            _.forEach(myContent[i], function (value, key) {
              if (typeof (value) === 'string' || Number.isInteger(value)) {
                views.push(
                  <Grid key={key + i} item xs={6} sm={3} md={3} lg={3} xl={3}>
                    {self.renderValue(key, value)}
                  </Grid>
                )
              } else {
                for (var k in value) {
                  if (Array.isArray(value[k])) {
                    views.push(self.renderList(fielId, value[k]))
                  }
                }
              }
            })
          }
        }
      } else {
        myContent = content
        if (Array.isArray(myContent)) {
          views.push(
            <div key={fielId} style={{ marginTop: 8 }}>
              <Grid key={fielId} >
                {this.renderCheckbox(fielId, myContent)}
              </Grid>
            </div>
          )
        } else {
          _.forEach(myContent, function (value, key) {
            if (typeof (value) === 'string' || Number.isInteger(value)) {
              views.push(
                <Grid key={key} item xs={6} sm={3} md={3} lg={3} xl={3}>
                  {self.renderValue(key, value)}
                </Grid>
              )
            } else {
              if (typeof (value) === 'object') {
                views.push(
                  <Grid key={key} item xs={6} sm={3} md={3} lg={3} xl={3}>
                    {self.renderValue(key, value.toString())}
                  </Grid>
                )
              } else {
                for (var k in value) {
                  if (Array.isArray(value[k])) {
                    views.push(self.renderList(fielId, value[k]))
                  }
                }
              }
            }
          })
        }
      }
    }
    return views
  }

  renderObject(fielId, content, innerCount) {
    const { t } = this.props
    const views = []
    for (var key in content) {
      if (Array.isArray(content[key]) || String(parseInt(key)) === key) {
        views.push(
          <div key={key} className='block' style={{ marginTop: 12 }}>
            <Grid container spacing={2}>
              {this.renderList(key, content[key])}
            </Grid>
          </div>
        )
      } else if (typeof (content[key]) === 'object') {
        innerCount = innerCount + 1
        if (this.getType(this.state.options, key) === 'checkbox') {
          views.push(
            <Grid key={key}>
              {this.renderCheckbox(key, content[key])}
            </Grid>
          )
        } else {
          views.push(
            <div
              key={key}
              className='block'
              // style={{ paddingLeft: 5, marginLeft: innerCount === 1 ? 154 : 147, marginBottom: 12, borderLeft: '1px #88BBEA solid' }}
              style={{ marginTop: 12, marginBottom: 12 }}
            >
              {/* <span style={{ color: '#E02020', fontSize: 12, fontWeight: 400 }}>
                {this.getLabel(this.state.options, key)}
              </span><br /> */}
              <Grid container spacing={2}>
                {this.renderObject(fielId, content[key], innerCount)}
              </Grid>
            </div>
          )
        }
      } else {
        views.push(
          <Grid key={key} style={{ marginTop: 12, minWidth: 150 }}>
            {this.renderValue(key, content[key])}
          </Grid>
        )
      }
    }
    return views
  }

  renderCheckbox(key, content) {
    var selectedLanguageValue = localStorage.getItem('selectedLanguageValue') ? localStorage.getItem('selectedLanguageValue') : this.state.selectedLanguageValue
    var value = []
    if (content) {
      for (var k in content) {
        value.push(
          <div key={k}>
            <span key={k} style={styles.value}>
              {Number.isInteger(parseInt(content[k]))
                ? content[k].toLocaleString(selectedLanguageValue)
                : this.maybeTranslate(content[k])}
            </span>
          </div>
        )
      }
    } else {
      var k = 0;
      value.push(
        <div key={k}>
          <span key={k} style={styles.value}>
            {this.maybeTranslate(content)}
          </span>
        </div>
      )
    }
    return (
      <div
        key={key}
      >
        <span key={key} style={styles.label}>
          {this.getLabel(this.state.options, key)}
        </span><br />
        {value}
      </div>
    )
  }

  maybeTranslate(value) {
    var booleans = ['true', 'false']
    if (typeof (value) === 'boolean') {
      return i18n.t(value)
    }
    if (booleans.indexOf(value) > -1) {
      return i18n.t(value)
    }
    if (value === null || value === 'null') {
      return i18n.t("null")
    }
    return value
  }

  renderValue(key, value) {
    if (!['_rev', 'startAt', 'type', 'latitude', 'longitude', 'validatedAt'].includes(key)) {
      var selectedLanguageValue = localStorage.getItem('selectedLanguageValue') ? localStorage.getItem('selectedLanguageValue') : this.state.selectedLanguageValue
      if (typeof (value) === 'string' && value.indexOf(';') > -1) {
        value = value.split(';')
        var newValue = []
        for (var k in value) {
          var v = value[k]
          newValue.push(
            <div key={k}>
              <span key={k} style={styles.value}>
                {Number.isInteger(parseInt(v))
                  ? parseInt(v).toLocaleString(selectedLanguageValue)
                  : this.maybeTranslate(v)}
              </span><br />
            </div>
          )
        }
        return (
          <div
            key={key}
          >
            <span key={key} style={styles.label}>
              {this.getLabel(this.state.options, key)}
            </span><br />
            {newValue}
          </div>
        )
      }
      return (
        <div
          key={key}
        >
          <span style={styles.label}>
            {this.getLabel(this.state.options, key)}
            {/* {key} */}
          </span><br />
          <div
            title={value && Number.isInteger(parseInt(value))
              ? value.toLocaleString(selectedLanguageValue)
              : this.maybeTranslate(value)}
            style={styles.value}
          >
            {value && Number.isInteger(parseInt(value))
              ? value.toLocaleString(selectedLanguageValue)
              : this.maybeTranslate(value)}
          </div>
        </div>
      )
    }
  }

  renderSection(key, section) {
    const { t } = this.props
    if (!['_rev', 'startAt', 'type', 'latitude', 'longitude', 'validatedAt'].includes(key)) {
      var innerCount = 0
      if (Array.isArray(section)) {
        return (
          <div key={key} className='block'>
            <div style={styles.blockAlign}>
              {/* {typeof (section[0]) === 'object' ? <div><span style={styles.labelBlockAlign}>{this.getLabel(this.state.options, key)}</span><br /></div> : null} */}
              <Grid container spacing={2}>
                {this.renderList(key, section)}
              </Grid>
            </div>
          </div>
        )
      } else if (typeof (section) === 'object') {
        if (section && section.hasOwnProperty('type') && section.type === 'POSITION') {
          return (
            <Grid item xs={6} sm={3} md={3} lg={3} xl={3} key={key}>
            <span style={styles.label}>{t('position')}</span><br />
            <Position position={section} />
          </Grid>
          )
        }
        return (
          <div key={key} className='block'>
            <div style={styles.blockAlign}>
              {/* <span style={styles.labelBlockAlign}>{this.getLabel(this.state.options, key)}</span><br /> */}
              <Grid container spacing={2}>
                {this.renderObject(key, section, innerCount)}
              </Grid>
            </div>
          </div>
        )
      } else {
        return (
          <Grid key={key} item xs={6} sm={3} md={3} lg={3} xl={3}>
            {this.renderValue(key, section)}
          </Grid>
        )
      }
    }
  }

  getType(dataOptions, key) {
    var fieldType = null
    _.forEach(dataOptions, function (element, value) {
      var fields = element.fields
      _.forEach(fields, function (title, index) {
        if (index === key) {
          fieldType = title.template
        }
      })
    })
    return fieldType
  }

  getLabel(dataOptions, key) {
    const { t } = this.props
    var fieldLabel = null
    _.forEach(dataOptions, function (element, value) {
      var fields = element.fields
      _.forEach(fields, function (title, index) {
        if (index === key) {
          fieldLabel = t(`${title.id}.${title.label}.label`, title.label)
        }
      })
    })
    if (key == 'validatedAt') {
      fieldLabel = t('validated_on')
    }
    if (key == '_id') {
      fieldLabel = 'Identifiant'
    }
    if (key == 'user') {
      fieldLabel = t('user')
    }
    if (!fieldLabel) {
      fieldLabel = t(`doctype.${key}.name`, key) ? t(`doctype.${key}.name`, key) : key
    }
    return fieldLabel
  }

  getListContent(section) {
    const { t } = this.props
    var views = []
    if (section.content && section.content.hasOwnProperty('type') && section.content.type === 'POSITION') {
      views.push(
        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
        <span style={styles.label}>{t('position')}</span><br />
        <Position position={section.content} />
      </Grid>
      )
    } else {
      for (var i in section.content) {
        views.push(this.renderSection(i, section.content[i]))
      }
    }
    return views
  }

  renderStep(item) {
    const { t } = this.props
    return (
      <div>
        <div className='subtitle-content'>
          <span title={t(`doctype.${item.title}.name`, item.title).toUpperCase()} style={styles.subtitle}>{t(`doctype.${item.title}.name`, item.title).toUpperCase()}</span>
        </div>
        <div className='grid-content-margin'>
          <Grid container spacing={0}>
            {this.getListContent(item)}
          </Grid>
        </div>
        {item.title !== 'SIGNATURE' ? <div className='grid-hr' /> : null}
      </div>
    )
  }

  renderCollection(collection) {
    const { t } = this.props
    return (
      <div>
        {collection.map((item, key) => (
          <div key={key}>
            {
              key !== 0
                ? (
                  <div>
                    {this.renderStep(item)}
                  </div>
                )
                : null
            }
          </div>
        ))}
        <div style={styles.hr} />
        <div style={styles.footer}>
          <span style={styles.subtitle}>{t(`doctype.${collection[0].content.type}.name`, collection[0].content.type).toUpperCase()}: </span>
          <span style={styles.text}>{moment.utc(collection[0].content.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY')}</span>
        </div>
      </div>
    )
  }

  renderContent() {
    return (
      <div>
        {this.renderCollection(this.state.collection)}
      </div>
    )
  }

  renderDescription(collection) {
    const { t } = this.props
    return (
      <div>
        {collection.map((item, key) => (
          <div key={key}>
            {
              key !== 0
                ? (
                  <div>
                    {this.renderStep(item)}
                  </div>
                )
                : null
            }
          </div>
        ))}
        <div style={styles.hr} />
        <div style={styles.footer}>
          <span style={styles.subtitle}>{t(`doctype.${collection[0].content.type}.name`, collection[0].content.type).toUpperCase()}: </span>
          <span style={styles.text}>{moment.utc(collection[0].content.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY')}</span>
        </div>
      </div>
    )
  }

  renderHeaderValue(value) {
    if (typeof (value) === 'string' && value.indexOf(';') > -1) {
      value = value.split(';')
      var newValue = []
      for (var k in value) {
        var v = value[k]
        newValue.push(
          <div key={k}>
            <span key={k} style={styles.value}>
              {Number.isInteger(parseInt(v))
                ? parseInt(v)
                : v}
            </span><br />
          </div>
        )
      }
      return (
        <div>
          {newValue}
        </div>
      )
    }
    return (
      <div style={styles.value}>{value}</div> 
    )
  }

  render() {
    const { t } = this.props
    var collection = this.state.collection
    if (!this.state.collection) {
      return (
        <div className='right-standard-content'>
          <div style={styles.content}>
            <FadeLoader />
          </div>
        </div>
      )
    }
    return (
      <div className='right-standard-content'>
        <div style={styles.content}>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={styles.titleContent}>
                <span title={t(`doctype.${collection[0].content.type}.name`, collection[0].content.type).toUpperCase()} style={styles.title}>{t(`doctype.${collection[0].content.type}.name`, collection[0].content.type).toUpperCase()}</span>
              </div>
              <span className='collection-date'>Du {moment.utc(collection[0].content.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY')}</span>
            </div>
            <div className='grid-header-margin'>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <span style={styles.label}>{t('beginning_intervention')}</span><br />
                  <span style={styles.value}>{moment.utc(collection[0].content.startAt).tz('Indian/Antananarivo').format('HH:mm')}</span>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <span style={styles.label}>{t('user')}</span><br />
                  <div title={collection[0].content.user} style={styles.value}>{collection[0].content.user}</div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <span style={styles.label}>{t('validated_on')}</span><br />
                  <span style={styles.value}>{moment.utc(collection[0].content.validatedAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm')}</span>
                </Grid>
                {
                  collection[0].content.customer
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('customer')}</span><br />
                        <div style={styles.value}>{collection[0].content.customer}</div>
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.vehicle_registration_number
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('vehicle_registration_number')}</span><br />
                        <div style={styles.value}>{collection[0].content.vehicle_registration_number}</div>
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.tracker_registration_number
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('tracker_registration_number')}</span><br />
                        <div style={styles.value}>{collection[0].content.tracker_registration_number}</div>
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.position
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('position')}</span><br />
                        <Position position={collection[0].content.position} />
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.assessment_on
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('assessment_on')}</span><br />
                        <span style={styles.value}>{collection[0].content.assessment_on}</span>
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.assessment_start_at
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('assessment_start_at')}</span><br />
                        <span style={styles.value}>{collection[0].content.assessment_start_at}</span>
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.assessment_period
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('assessment_period')}</span><br />
                        <span style={styles.value}>{collection[0].content.assessment_period}</span>
                      </Grid>
                    )
                    : null
                }
                {
                  collection[0].content.station_name
                    ? (
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <span style={styles.label}>{t('station_name')}</span><br />
                        {this.renderHeaderValue(collection[0].content.station_name)}
                      </Grid>
                    )
                    : null
                }
              </Grid>
            </div>
            <div className='grid-hr' />
          </div>
          {this.renderContent()}
          <div>
            <Modal
              styles={bg}
              open={this.state.openModal}
              onClose={() => this.onCloseModal()}
              showCloseIcon={false}
              center
              focusTrapped={false}
            >
              <div style={styles.modalButtonContent}>
                <img
                  style={styles.imgToShow}
                  src={this.state.imgSrc}
                  alt=''
                />
              </div>
              <div style={styles.modalButtonContent}>
                <div
                  style={styles.modalButton}
                  onClick={() => this.onCloseModal()}
                  tabIndex='0'
                  role='button'
                  onKeyPress={() => { }}
                >
                  <span>{t('close').toUpperCase()}</span>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const bg = {
  modal: {
    background: '#EBEBEB',
    borderRadius: '6px',
    color: '#272D31'
  }
}

const styles = {
  content: {
    paddingTop: 16,
    paddingLeft: 10,
    paddingRight: 10,
    height: 'calc(100% - 16px)',
    width: 'calc(100% - 20)',
    maxWidth: 766,
    minWidth: 360
  },
  titleContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 184,
    maxWidth: 184,
    paddingRight: 10
  },
  embedsManyContent: {
    paddingLeft: 10,
    marginTop: 10
  },
  embedsManyRenderValue: {
    paddingLeft: 0,
    marginTop: 13
  },
  embedsManyImg: {
    marginLeft: 8,
    paddingLeft: 20,
    marginBottom: 30,
    width: 'calc(100% - 16px)'
  },
  hr: {
    width: '100%',
    marginTop: 20,
    borderBottom: '1px solid #CBCBCC'
  },
  title: {
    color: '#414141',
    fontSize: 13,
    fontWeight: 600
  },
  subtitle: {
    color: '#E02020',
    fontSize: 13,
    fontWeight: 600
  },
  footer: {
    marginTop: 10,
    float: 'right'
  },
  text: {
    color: '#001124',
    fontSize: 15,
    fontWeight: 400
  },
  blockAlign: {
    marginBottom: 12
  },
  labelBlockAlign: {
    color: '#E02020',
    marginLeft: '-8px',
    fontSize: 13,
    fontWeight: 600
  },
  label: {
    color: '#001124',
    fontSize: 10,
    fontWeight: 600,
    width: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  labelImg: {
    color: '#001124',
    fontSize: 10,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '-8px'
  },
  value: {
    color: '#001124',
    fontSize: 10,
    fontWeight: 400,
    marginTop: 4,
    width: 140,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  imgList: {
    overflowX: 'auto',
    display: 'flex'
  },
  imgContainer: {
    marginTop: 3,
    // marginLeft: 8,
    width: 'calc(100% - 16px)'
  },
  imgSection: {
    marginTop: 10,
    marginRight: 14,
    width: 114,
    minWidth: 112,
    height: 120,
    textAlign: 'center',
    backgroundColor: '#E4E4E4',
    borderRadius: 4,
    outline: 'none',
    cursor: 'pointer'
  },
  img: {
    maxWidth: 94,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    // width: '100%',
    height: 80
  },
  modalButtonContent: {
    width: '100%'
  },
  modalButton: {
    cursor: 'pointer',
    paddingTop: 4,
    textAlign: 'center',
    width: 100,
    margin: 'auto',
    border: '1px solid #DADADA',
    height: 24,
    borderRadius: 6,
    outline: 'none'
  },
  imgToShow: {
    // width: 300,
    height: 300
  }

}

ShowClosedCollectionRight.propTypes = {
  collection: PropTypes.array,
  t: PropTypes.func
}

export default (withTranslation()(ShowClosedCollectionRight))
