import React from 'react'
import ReactDOM from 'react-dom'
import { Route, HashRouter as Router, Redirect } from 'react-router-dom'
import 'typeface-roboto'
import './index.css'
import Dashboard from './routes/Dashboard'
import ClosedCollection from './routes/ClosedCollection'
import InProgressCollection from './routes/InProgressCollection'
import User from './routes/User'
import Data from './routes/Data'
import Device from './routes/Device'
import AddUser from './routes/AddUser'
import AddData from './routes/AddData'
import AddDevice from './routes/AddDevice'
import OrgError from './routes/Error'
import ShowClosedCollection from './routes/ShowClosedCollection'
import Login from './components/Authentication/Login'
import HandleCode from './components/Authentication/HandleCode'
// import * as serviceWorker from './serviceWorker';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined'
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { referrer: window.location.href } }} />
    )}
  />
)

const routing = (
  <Router>
    <div>
      <Route path='/login' component={Login} />
      <Route path='/' component={HandleCode} />
      <PrivateRoute path='/error' component={OrgError} />
      <PrivateRoute path='/:org/dashboard' component={Dashboard} />
      <PrivateRoute path='/:org/closed-collections' component={ClosedCollection} />
      <PrivateRoute path='/:org/in-progress-collections' component={InProgressCollection} />
      <PrivateRoute path='/:org/users' component={User} />
      <PrivateRoute path='/:org/datas' component={Data} />
      <PrivateRoute path='/:org/devices' component={Device} />
      <PrivateRoute path='/:org/add-user' component={AddUser} />
      <PrivateRoute path='/:org/add-data' component={AddData} />
      <PrivateRoute path='/:org/add-device' component={AddDevice} />
      <PrivateRoute path='/:org/edit-device/:editedDeviceId' component={AddDevice} />
      <PrivateRoute path='/:org/closed-collection/:selectedClosedCollectionId' component={ShowClosedCollection} />
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
