import React, { Component } from 'react'
import '../App.css'
import './../css/style.css'
import PropTypes from 'prop-types'
import Header from '../components/Header'
import ButtonSave from '../components/ButtonSave'
import ButtonBack from '../components/ButtonBack'
import ProgressBar from '../components/ProgressBar'
import t from 'tcomb-form'
import transform from 'tcomb-json-schema'
import { getTemplates } from '../components/Templates/templates'
import Radio from '@material-ui/core/Radio'
import Modal from 'react-responsive-modal'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import {
  ADD_DEVICE,
  UPDATE_DEVICE,
  DEVICE_BY_ID,
  QR_CODE_BY_ID,
  SEND_MAIL,
  ACCESS_CODE_BY_ID,
  SEND_ACCESS_CODE_MAIL
} from '../query/device'
import { client } from '../graphql/client'
import { withTranslation } from 'react-i18next'
import i18n from '../i18n'
import { Logger } from "react-remote-logger"

const templates = getTemplates()
const Form = t.form.Form

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      deviceValue: null,
      mailValue: null,
      nextDisabled: false,
      sendDisabled: false,
      selectedValue: null,
      deviceReference: 'DEVICE',
      mailReference: 'MAIL',
      createdDeviceId: null,
      editIsFinished: false,
      editedDeviceId: this.props.location.state && this.props.location.state.editedDeviceId ? this.props.location.state.editedDeviceId : null,
      deviceToActivateId: this.props.location.state && this.props.location.state.deviceToActivateId ? this.props.location.state.deviceToActivateId : null,
      index: props.location.state && props.location.state.activationIndex ? props.location.state.activationIndex : 0,
      openModal: false,
      backIsClicked: false,
      org: localStorage.getItem('organization'),
      qrCode: null,
      code: null,
      deviceOptions: {
        fields: {
          label: {
            label: i18n.t('label'),
            template: templates.text
          }
        }
      },
      deviceData: {
        type: 'object',
        properties: {
          label: {
            type: 'string'
          }
        },
        required: [
          'label'
        ]
      },
      mailOptions: {
        fields: {
          mailAddress: {
            label: 'Mail',
            template: templates.mail
          }
        }
      },
      mailData: {
        type: 'object',
        properties: {
          mailAddress: {
            type: 'string'
          }
        },
        required: [
          'mailAddress'
        ]
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
    if (this._isMounted && this.state.editedDeviceId) {
      client
        .query({
          query: DEVICE_BY_ID,
          variables: { id: this.state.editedDeviceId },
          fetchPolicy: 'network-only'
        })
        .then(result => {
          var device = result.data.device
          this.setState({
            deviceValue: device
          })
        }).catch(error => console.log(error))
    }
    if (this._isMounted && this.state.deviceToActivateId) {
      this.getQrCodeById(this.state.deviceToActivateId)
      this.getAccessCodeById(this.state.deviceToActivateId)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getQrCodeById(id) {
    client
      .query({
        query: QR_CODE_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        var qrCode = 'data:image/png;base64,' + result.data.qrcode.data
        this.setState({
          qrCode: qrCode
        })
      }).catch(error => console.log(error))
  }

  getAccessCodeById(id) {
    client
      .query({
        query: ACCESS_CODE_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        console.log("accessCode", result)
        var code = result.data.accessCode.data
        this.setState({
          code: code
        })
      }).catch(error => console.log(error))
  }

  onOpenModal(id) {
    this.setState({
      openModal: true,
      selectedId: id
    })
  }

  onCloseModal() {
    var self = this
    self.setState({ openModal: false })
  }

  onSubmit(evt) {
    const self = this
    evt.preventDefault()
    const value = self.refs[self.state.deviceReference].getValue()
    if (value) {
      if (self.state.editedDeviceId) {
        client
          .mutate({
            mutation: UPDATE_DEVICE,
            variables: { id: self.state.editedDeviceId, label: value.label }
          })
          .then(result => {
            Logger({
              'timestamp': (new Date()).toLocaleString(),
              'level': 'debug',
              'user_id': localStorage.user_id,
              'user': localStorage.user_email,
              'organization': localStorage.organization,
              'action': 'PUT',
              'id': self.state.editedDeviceId,
              'params': { label: value.label },
              'url': window.location.href,
              'message': `PUT ${window.location.href}`
            })
            self.setState({
              editIsFinished: true
            })
          }).catch(error => console.log(error))
      } else {
        client
          .mutate({
            mutation: ADD_DEVICE,
            variables: { org: self.state.org, isActive: false, label: value.label }
          })
          .then(result => {
            Logger({
              'timestamp': (new Date()).toLocaleString(),
              'level': 'debug',
              'user_id': localStorage.user_id,
              'user': localStorage.user_email,
              'organization': localStorage.organization,
              'action': 'POST',
              'params': { isActive: false, label: value.label },
              'url': window.location.href,
              'message': `POST ${window.location.href}`
            })
            self.getQrCodeById(result.data.createDevice.id)
            self.getAccessCodeById(result.data.createDevice.id)
            self.setState({
              nextDisabled: true,
              index: 1,
              createdDeviceId: result.data.createDevice.id
            })
          }).catch(error => console.log(error))
      }
    }
  }

  sendMail(evt) {
    const self = this
    evt.preventDefault()
    self.setState({
      sendDisabled: true
    })
    const value = self.refs[self.state.mailReference].getValue()
    if (value) {
      var deviceId = self.state.createdDeviceId ? self.state.createdDeviceId : self.state.deviceToActivateId
      client
        .mutate({
          mutation: SEND_MAIL,
          variables: { id: deviceId, mailAddress: value.mailAddress, collection: self.state.org, locale: localStorage.getItem('selectedLanguageValue') ? localStorage.getItem('selectedLanguageValue') : 'fr' }
        })
        .then(result => {
          self.setState({
            backIsClicked: true
          })
        }).catch(error => console.log(error))
    }
  }

  sendAccessCodeMail(evt) {
    const self = this
    evt.preventDefault()
    self.setState({
      sendDisabled: true
    })
    const value = self.refs[self.state.mailReference].getValue()
    if (value) {
      var deviceId = self.state.createdDeviceId ? self.state.createdDeviceId : self.state.deviceToActivateId
      client
        .mutate({
          mutation: SEND_ACCESS_CODE_MAIL,
          variables: { id: deviceId, mailAddress: value.mailAddress, collection: self.state.org, locale: localStorage.getItem('selectedLanguageValue') ? localStorage.getItem('selectedLanguageValue') : 'fr' }
        })
        .then(result => {
          self.setState({
            backIsClicked: true
          })
        }).catch(error => console.log(error))
    }
  }

  onChangeDevice(value) {
    console.log('Value: ', value)
    this.setState({
      deviceValue: value
    })
  }

  onChangeMail(value) {
    console.log('Value: ', value)
    this.setState({
      mailValue: value
    })
  }

  handleChange(e) {
    this.setState({
      selectedValue: e.target.value
    })
  }

  activate() {
    const self = this
    var selectedValue = self.state.selectedValue
    if (selectedValue) {
      if (selectedValue === 'ok' || selectedValue === 'send_mail' || selectedValue === 'send_access_code_mail') {
        self.setState({
          index: 2
        })
      }
      if (selectedValue === 'later') {
        self.setState({
          backIsClicked: true
        })
      }
    } else {
      self.setState({
        openModal: true
      })
    }
  }

  getFormContent() {
    const { t } = this.props
    return (
      <div>
        <span className='title-font-size' style={styles.title}>
          {this.state.editedDeviceId ? t('change_label_device') : t('enquire_label_device')}
        </span>
        <form
          onSubmit={(e) => this.onSubmit(e)}
        >
          <div style={styles.fieldContent}>
            <Form
              ref={this.state.deviceReference}
              type={transform(this.state.deviceData)}
              options={this.state.deviceOptions}
              value={this.state.deviceValue}
              onChange={(value) => this.onChangeDevice(value)}
            />
            <div style={styles.buttonContainer}>
              <div style={styles.buttonContent}>
                <div>
                  <ButtonBack
                    label={t('cancel')}
                  />
                </div>
                <div style={styles.saveContent}>
                  <ButtonSave
                    label={this.state.editedDeviceId ? t('validate') : t('next')}
                    type='submit'
                    disabled={this.state.nextDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  getRadioContent() {
    const { classes, t } = this.props
    return (
      <div>
        <span className='title-font-size' style={styles.title}>{t('activate_device_want')}</span><br />
        <div style={styles.containerRadio}>
          <div style={styles.subtitleContent}>
            <span className='subtitle-font-size' style={styles.subtitle}>{t('device_with_scan_qr')}</span>
            <div className='radio-content-margin' style={styles.radioContent}>
              <Radio
                checked={this.state.selectedValue === 'ok'}
                onChange={(e) => this.handleChange(e)}
                value='ok'
                color='default'
                name='radio-button-demo'
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
              <span className='label-radio-font-size' style={this.state.selectedValue === 'ok' ? styles.selectedSubtitle : styles.subtitle}>{t('device_with_me')}</span><br />
              <Radio
                checked={this.state.selectedValue === 'send_mail'}
                onChange={(e) => this.handleChange(e)}
                value='send_mail'
                color='default'
                name='radio-button-demo'
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
              <span className='label-radio-font-size' style={this.state.selectedValue === 'send_mail' ? styles.selectedSubtitle : styles.subtitle}>{t('activation_qr_code')}</span><br />
              <Radio
                checked={this.state.selectedValue === 'send_access_code_mail'}
                onChange={(e) => this.handleChange(e)}
                value='send_access_code_mail'
                color='default'
                name='radio-button-demo'
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
              <span className='label-radio-font-size' style={this.state.selectedValue === 'send_access_code_mail' ? styles.selectedSubtitle : styles.subtitle}>{t('activation_access_code')}</span><br />
              <Radio
                checked={this.state.selectedValue === 'later'}
                onChange={(e) => this.handleChange(e)}
                value='later'
                color='default'
                name='radio-button-demo'
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }}
              />
              <span className='label-radio-font-size' style={this.state.selectedValue === 'later' ? styles.selectedSubtitle : styles.subtitle}>{t('no_later')}</span>
            </div>
            <div style={styles.buttonActivationContent}>
              <button
                style={styles.nextButton}
                onClick={() => this.activate()}
              >
                <span style={styles.labelNextButton}>{t('next')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getActivationContent() {
    const { t } = this.props
    const self = this
    var selectedValue = self.state.selectedValue
    if (selectedValue === 'ok') {
      return (
        <div>
          <span className='title-font-size' style={styles.title}>{t('scan_qr_code')}</span><br />
          <div style={styles.containerRadio}>
            <div style={styles.subtitleContent}>
              <span className='subtitle-font-size' style={styles.subtitle}>{t('leave_assistant')}</span>
              <div style={styles.qrCodeContent}>
                <img style={styles.icon} src={self.state.qrCode} alt='true' />
              </div>
              <div style={styles.buttonExitContent}>
                <button
                  style={styles.nextButton}
                  onClick={() => this.setState({
                    backIsClicked: true
                  })}
                >
                  <span style={styles.labelNextButton}>{t('quit')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (selectedValue === 'send_mail' || selectedValue === 'send_access_code_mail') {
      return (
        <div>
          <span className='title-font-size' style={styles.title}>{t('inform_mail')}</span>
          <div style={styles.subtitleContent}>
            <span className='subtitle-font-size' style={styles.subtitle}>{selectedValue === 'send_mail' ? t('scan_tablet') : t('enter_code')}</span>
          </div>
          <form
            onSubmit={(e) => {
              selectedValue === 'send_mail' ? this.sendMail(e) : this.sendAccessCodeMail(e)
            }}
          >
            <div style={styles.fieldContent}>
              <Form
                ref={this.state.mailReference}
                type={transform(this.state.mailData)}
                options={this.state.mailOptions}
                value={this.state.mailValue}
                onChange={(value) => this.onChangeMail(value)}
              />
              <div style={styles.buttonContainer}>
                <div style={styles.mailButtonContent}>
                  <div>
                    <ButtonBack
                      label={t('cancel')}
                    />
                  </div>
                  <div style={styles.saveContent}>
                    <ButtonSave
                      label='Envoyer'
                      type='submit'
                      disabled={this.state.sendDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    }
  }

  getStepContent(index) {
    let view
    switch (index) {
      case 0:
        view = this.getFormContent()
        break
      case 1:
        view = this.getRadioContent()
        break
      case 2:
        view = this.getActivationContent()
        break
      default:
        view = null
    }
    return view
  }

  render() {
    var organization = this.props.match.params.org
    if (this.state.org !== organization) {
      document.location.href = '/#/error'
      return null
    }
    if (this.state.backIsClicked || this.state.editIsFinished) {
      var devices = `/${this.state.org}/devices`
      return (
        <Redirect to={devices} />
      )
    }
    return (
      <div className='App'>
        <Header
          enabled='device'
          org={this.state.org}
        />
        <div className='container'>
          <div style={styles.content}>
            <div style={styles.progressContent}>
              {!this.state.editedDeviceId
                ? (
                  <ProgressBar
                    index={this.state.index}
                    selectedActivation={this.state.selectedValue}
                  />
                )
                : null}
            </div>
            <div style={styles.formContainer}>
              <div style={styles.formContent}>
                {this.getStepContent(this.state.index)}
              </div>
            </div>
          </div>
          <div>
            <Modal
              styles={bg}
              open={this.state.openModal}
              onClose={() => this.onCloseModal()}
              showCloseIcon={false}
              center
              focusTrapped={false}
            >
              <h2 style={styles.modalTitle}>Choisir une étape</h2>
              <p style={styles.modalText}>
                Veuillez sélectionnner pour passer à l'étape suivante
              </p>
              <div style={styles.modalButtonContent}>
                <div
                  style={styles.modalButton}
                  onClick={() => this.onCloseModal()}
                  tabIndex='0'
                  role='button'
                  onKeyPress={() => { }}
                >
                  <span>OK</span>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const materialStyles = theme => ({
  root: {
    color: '#535353'
  },
  checked: {
    '&$checked': {
      color: '#011123'
    }
  }
})

const bg = {
  modal: {
    background: '#EBEBEB',
    borderRadius: '6px',
    color: '#272D31'
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto'
  },
  progressContent: {
    paddingTop: 24,
    width: '100%',
    margin: 'auto',
    maxWidth: 766,
    minWidth: 360
  },
  formContainer: {
    paddingTop: 30,
    width: '100%',
    margin: 'auto',
    maxWidth: 766,
    minWidth: 360,
    height: 550
  },
  formContent: {
    paddingTop: 22,
    textAlign: 'center',
    width: 'calc(100% -20px)',
    height: 528,

    maxWidth: 766,
    minWidth: 360,
    marginLeft: 10,
    marginRight: 10,

    background: '#FFFFFF'
  },
  title: {
    color: '#011123',
    fontWeight: 600
  },
  containerRadio: {
    width: '100%'
  },
  subtitleContent: {
    width: '100%',
    maxWidth: 474,
    minWidth: 360,
    margin: 'auto',
    paddingTop: 14
  },
  subtitle: {
    color: '#535353',
    fontWeight: 600
  },
  fieldContent: {
    paddingTop: 12,
    width: 'calc(100% - 88)',
    paddingLeft: 44,
    paddingRight: 44
  },
  buttonContainer: {
    width: '100%'
  },
  buttonContent: {
    paddingTop: 350,
    width: 280,
    textAlign: 'center',
    margin: 'auto',
    display: 'flex'
  },
  mailButtonContent: {
    paddingTop: 320,
    width: 280,
    textAlign: 'center',
    margin: 'auto',
    display: 'flex'
  },
  saveContent: {
    paddingLeft: 26
  },
  radioContent: {
    paddingTop: 44,
    width: '100%',
    textAlign: 'left',
    height: 180,
    maxHeight: 180
  },
  qrCodeContent: {
    paddingTop: 54,
    textAlign: 'center',
    height: 180,
    maxHeight: 180
  },
  buttonActivationContent: {
    paddingTop: 188
  },
  buttonExitContent: {
    paddingTop: 178
  },
  nextButton: {
    cursor: 'pointer',
    marginTop: 4,
    width: 127,
    height: 32,
    borderRadius: 6,
    border: 'none',
    backgroundColor: '#0091FF',
    outline: 'none'
  },
  labelNextButton: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 500
  },
  modalTitle: {
    fontSize: 14,
    marginTop: '-10px',
    textAlign: 'center'
  },
  modalText: {
    fontSize: 12
  },
  modalButtonContent: {
    width: '100%'
  },
  modalButton: {
    cursor: 'pointer',
    paddingTop: 4,
    textAlign: 'center',
    width: 100,
    margin: 'auto',
    border: '1px solid #DADADA',
    height: 24,
    borderRadius: 6,
    outline: 'none'
  },
  selectedSubtitle: {
    color: '#011123',
    fontWeight: 600
  },
  icon: {
    width: 152,
    height: 152
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func,
  match: PropTypes.object
}

export default withTranslation()(withStyles(materialStyles)(Dashboard))
