import React from 'react'
import '../css/style.css'
import logo from '../images/logoIcon.svg'

class Logo extends React.Component {
  render () {
    return (
      <div style={styles.container}>
        <div
          style={styles.content}
        >
          <img src={logo} alt='FORMS-ADMIN-V2' />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: 136,
    height: 58,
    float: 'left',
    position: 'fixed'
  },
  content: {
    marginTop: 16,
    marginLeft: 44,
    backgroundColor: 'Transparent',
    border: 'none',
    width: 112,
    height: 43
  }
}

export default Logo
