import React from 'react'
import PropTypes from 'prop-types'

class EnabledButtonSave extends React.Component {
  render () {
    return (
      <div>
        {this.props.disabled
          ? <button
            disabled
            style={styles.buttonDisabled}
            type={this.props.type}>
            <span style={styles.label}>{this.props.label}</span>
          </button>
          : <button
            style={styles.button}
            type={this.props.type}>
            <span style={styles.label}>{this.props.label}</span>
          </button>
        }
      </div>
    )
  }
}

const styles = {
  button: {
    cursor: 'pointer',
    marginTop: 4,
    width: 127,
    height: 32,
    borderRadius: 6,
    border: 'none',
    backgroundColor: '#0091FF',
    outline: 'none'
  },
  buttonDisabled: {
    cursor: 'pointer',
    marginTop: 4,
    width: 127,
    height: 32,
    border: 'none',
    borderRadius: 6,
    backgroundColor: 'grey',
    outline: 'none'
  },
  label: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 500
  }
}

EnabledButtonSave.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
}

export default EnabledButtonSave
