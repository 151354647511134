import React from 'react'
import PropTypes from 'prop-types'
import '../css/header.css'
import Logo from './Logo'
// import UserLogo from './UserLogo'
import EnabledTab from './EnabledTab'
import DisabledTab from './DisabledTab'
import ChooseLanguage from './ChooseLanguage'
import Disconnection from './Disconnection'
import {
  withTranslation
} from 'react-i18next'
import config from '../config'
import RedirectToHome from './RedirectToHome'

class Header extends React.Component {
  checkDashboardTab () {
    if (this.props.enabled === 'dashboard') {
      return (
        <EnabledTab
          label='Dashboard'
        />
      )
    }
    return (
      <DisabledTab
        label='Dashboard'
      />
    )
  }

  checkClosedCollectionTab () {
    const { t } = this.props
    if (this.props.enabled === 'closedCollection') {
      return (
        <EnabledTab
          label={t('closed_collections')}
        />
      )
    }
    return (
      <DisabledTab
        label={t('closed_collections')}
      />
    )
  }

  checkInProgressCollectionTab () {
    if (this.props.enabled === 'inProgressCollection') {
      return (
        <EnabledTab
          label='Collectes en cours'
        />
      )
    }
    return (
      <DisabledTab
        label='Collectes en cours'
      />
    )
  }

  checkUserTab () {
    const { t } = this.props
    if (this.props.enabled === 'user') {
      return (
        <EnabledTab
          label={t('users')}
        />
      )
    }
    return (
      <DisabledTab
        label={t('users')}
      />
    )
  }

  checkDataTab () {
    if (this.props.enabled === 'data') {
      return (
        <EnabledTab
          label='Data'
        />
      )
    }
    return (
      <DisabledTab
        label='Data'
      />
    )
  }

  checkDeviceTab () {
    const { t } = this.props
    if (this.props.enabled === 'device') {
      return (
        <EnabledTab
          label={t('devices')}
        />
      )
    }
    return (
      <DisabledTab
        label={t('devices')}
      />
    )
  }

  checkDisconnectionTab () {
    const { t } = this.props
    return (
      <Disconnection
        label={t('logout')}
        onClick={() => this.disconnect()}
      />
    )
  }

  disconnect () {
    var token = localStorage.getItem('token')
    fetch(`${config.salt_url}/oauth/revoke?client_id=${config.clientId}&token=${token}`, { method: 'post' })
      .then(response => response.json())
      .then(data => {
        localStorage.clear()
        window.location.replace(`${config.salt_url}/users/log_out`)
      })
      .catch(error => console.error('error ', error))
  }

  render () {
    var org = this.props.org
    var closedCollections = `/#/${org}/closed-collections`
    var dashboard = `/#/${org}/dashboard`
    var users = `/#/${org}/users`
    var datas = `/#/${org}/datas`
    var devices = `/#/${org}/devices`
    return (
      <header className='header'>
        <Logo />
        <input className='menu-btn' type='checkbox' id='menu-btn' />
        <label className='menu-icon' htmlFor='menu-btn'><span className='navicon' /></label>
        <div className='menu-language'>
          <ChooseLanguage />
        </div>
        <ul className='menu'>
          <li>
            <a href={dashboard}>
              {this.checkDashboardTab()}
            </a>
          </li>
          <li>
            <a href={closedCollections}>
              {this.checkClosedCollectionTab()}
            </a>
          </li>
          {/* <li>
            <a href='#in-progress-collections'>
              {this.checkInProgressCollectionTab()}
            </a>
          </li> */}
          <li>
            <a href={users}>
              {this.checkUserTab()}
            </a>
          </li>
          <li>
            <a href={datas}>
              {this.checkDataTab()}
            </a>
          </li>
          <li>
            <a href={devices}>
              {this.checkDeviceTab()}
            </a>
          </li>
          <li>
            {this.checkDisconnectionTab()}
            {/* <UserLogo /> */}
          </li>
          <li>
            <RedirectToHome />
          </li>
        </ul>
      </header>
    )
  }
}

Header.propTypes = {
  enabled: PropTypes.string,
  org: PropTypes.string,
  t: PropTypes.func
}

export default (withTranslation()(Header))
