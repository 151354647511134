import i18n from 'i18next'
import {
  initReactI18next
} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import translationEn from './locales/en/translation.json'
import translationFr from './locales/fr/translation.json'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: localStorage.i18nextLng,
    fallbackLng: 'fr', // use en if detected lng is not available
    load: 'languageOnly',
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        translations: translationEn
      },
      fr: {
        translations: translationFr
      }
    },
    // have a common namespace used around the full app
    namespaces: ['translations'],
    defaultNS: 'translations',
    fallbackNS: 'translations',
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded'
    }

  })

export default i18n
