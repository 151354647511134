import React from 'react'
import PropTypes from 'prop-types'
import '../css/header.css'
import backIcon from '../images/backIcon.svg'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class AddHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      org: localStorage.getItem('organization'),
      backIsClicked: false
    }
  }

  render () {
    const { t } = this.props
    if (this.state.backIsClicked) {
      var route = `/${this.state.org}/${this.props.route}`
      return (
        <Redirect to={route} />
      )
    }
    return (
      <header className='header-add-user'>
        <div>
          <div
            style={styles.contentLeft}
            tabIndex='0'
            onClick={() => {
              this.setState({ backIsClicked: true })
            }}
            role='button'
            onKeyPress={() => {}}
          >
            <span style={styles.label}>{t('quit')}</span>
            <img
              src={backIcon}
              alt=''
              style={styles.icon}
            />
          </div>
        </div>
        <div>
          <button type={this.props.type} disabled={this.props.disabled} className='button-add-user' style={styles.buttonRight}>
            <span>{t('validate')}</span>
          </button>
          <div
            tabIndex='0'
            className='button-add-user'
            style={styles.buttonLeft}
            onClick={() => {
              this.setState({ backIsClicked: true })
            }}
            role='button'
            onKeyPress={() => {}}
          >
            <span>{t('cancel')}</span>
          </div>
        </div>
      </header>
    )
  }
}

const styles = {
  contentLeft: {
    float: 'left',
    width: 90,
    height: 24,
    marginLeft: 20,
    outline: 'none',
    cursor: 'pointer'
  },
  label: {
    marginTop: 4,
    color: '#414141',
    fontWeight: 400,
    fontSize: 15,
    float: 'right'
  },
  icon: {
    marginTop: 2,
    float: 'left'
  },
  buttonLeft: {
    float: 'right',
    textAlign: 'center',
    border: '1px solid #979797',
    height: 20,
    marginRight: 20,
    borderRadius: 20,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 400,
    color: '#979797',
    paddingTop: 3
  },
  buttonRight: {
    backgroundColor: '#979797',
    marginRight: 24,
    borderWidth: 0,
    float: 'right',
    height: 24,
    borderRadius: 20,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 400,
    color: '#FFFFFF'
  }
}

AddHeader.propTypes = {
  type: PropTypes.string,
  route: PropTypes.string,
  disabled: PropTypes.bool,
  t: PropTypes.func
}

export default (withTranslation()(AddHeader))
