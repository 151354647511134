import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../App.css'
import Header from '../components/Header'
import DashboardContent from '../components/DashboardContent'
import { Logger } from "react-remote-logger"

class Dashboard extends Component {
  componentDidMount() {
    Logger({
      'timestamp': (new Date()).toLocaleString(),
      'level': 'info',
      'user_id': localStorage.user_id,
      'user': localStorage.user_email,
      'organization': localStorage.organization,
      'action': 'GET',
      'url': window.location.href,
      'message': `GET ${window.location.href}`
    })
  }
  
  render() {
    return (
      <div className='App'>
        <Header
          enabled='dashboard'
          org={this.props.match.params.org}
        />
        <DashboardContent
          org={this.props.match.params.org}
        />
      </div>
    )
  }
}

Dashboard.propTypes = {
  match: PropTypes.object
}

export default Dashboard
