import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../css/style.css'
import SearchBar from './SearchBar'
import ClosedCollectionContentHeader from './ClosedCollectionContentHeader'
import ClosedCollectionTable from './ClosedCollectionTable'
import ClosedTableByUser from './ClosedTableByUser'
import ClosedTableByCollection from './ClosedTableByCollection'
import Pagination from './Pagination'
import _ from 'lodash'

import {
  refreshToken
} from '../utils/token'
import {
  getUserInfo
} from '../utils/userInfo'
import {
  getClosedCollectionByOrg,
  countClosedCollectionByOrg,
  getClosedCollectionByOrgAndPage
} from '../utils/pouchdb'
import FadeLoader from '../components/FadeLoader'
import i18n from '../i18n'
import {
  withTranslation
} from 'react-i18next'
import EmptyList from './EmptyList'
import moment from 'moment-timezone'

class ClosedCollectionContent extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.state = {
      org: props.org,
      selectedOption: {
        value: (localStorage.getItem('isBackedOnShow') && localStorage.getItem('selectedFilter')) ? localStorage.getItem('selectedFilter') : 'all',
        label: i18n.t('journal')
      },
      closedCollection: null,
      closedCollectionNumber: null,
      allClosedCollectionNumber: null,
      idList: [],
      searchKey: null,
      selectedPage: 1,
      selectedPageSize: 10,
      loaded: false
    }
  }

  componentDidMount () {
    const self = this
    localStorage.removeItem('isBackedOnShow')
    var token = localStorage.getItem('token')
    var organization = this.state.org
    this._isMounted = true
    if (this._isMounted) {
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    var closedCollection = await getClosedCollectionByOrgAndPage(organization, self.state.selectedPage, self.state.selectedPageSize)
                    if (self.state.selectedOption.value !== 'all') {
                      closedCollection = await getClosedCollectionByOrg(organization)
                    }
                    var closedCollectionNumber = await countClosedCollectionByOrg(organization)
                    if ((closedCollection && closedCollectionNumber) || closedCollection.docs.length === 0) {
                      self.setState({
                        loaded: true,
                        closedCollection: closedCollection.docs,
                        allClosedCollectionNumber: closedCollectionNumber,
                        closedCollectionNumber
                      })
                    }
                  }
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              var closedCollection = await getClosedCollectionByOrgAndPage(organization, self.state.selectedPage, self.state.selectedPageSize)
              if (self.state.selectedOption.value !== 'all') {
                closedCollection = await getClosedCollectionByOrg(organization)
              }
              var closedCollectionNumber = await countClosedCollectionByOrg(organization)
              if ((closedCollection && closedCollectionNumber) || closedCollection.docs.length === 0) {
                self.setState({
                  loaded: true,
                  closedCollection: closedCollection.docs,
                  allClosedCollectionNumber: closedCollectionNumber,
                  closedCollectionNumber
                })
              }
            }
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const self = this
    if (nextProps.org !== this.props.org) {
      var organization = nextProps.org
      var token = localStorage.getItem('token')
      this.setState({
        org: organization,
        idList: [],
        closedCollection: null
      })
      getUserInfo(token)
        .then(response => response.json())
        .then(async function (data) {
          if (data.error && data.error.code === 401) {
            refreshToken(localStorage.getItem('refresh_token')).then(function () {
              var newToken = localStorage.getItem('token')
              getUserInfo(newToken)
                .then(response => response.json())
                .then(async function (data) {
                  localStorage.setItem('user_email', data.data.attributes.email)
                  localStorage.setItem('user_id', data.data.attributes.sub)
                  var organizations = data.data.attributes.forms
                  if (organizations.includes(organization)) {
                    localStorage.setItem('organization', organization)
                    var closedCollection = await getClosedCollectionByOrgAndPage(organization, self.state.selectedPage, self.state.selectedPageSize)
                    var closedCollectionNumber = await countClosedCollectionByOrg(organization)
                    if ((closedCollection && closedCollectionNumber) || closedCollection.docs.length === 0) {
                      self.setState({
                        loaded: true,
                        closedCollection: closedCollection.docs,
                        allClosedCollectionNumber: closedCollectionNumber,
                        closedCollectionNumber
                      })
                    }
                  } else document.location.href = '/#/error'
                })
                .catch((error) => {
                  console.log('error: ', error)
                })
            })
          } else {
            localStorage.setItem('user_email', data.data.attributes.email)
            localStorage.setItem('user_id', data.data.attributes.sub)
            var organizations = data.data.attributes.forms
            if (organizations.includes(organization)) {
              localStorage.setItem('organization', organization)
              var closedCollection = await getClosedCollectionByOrgAndPage(organization, self.state.selectedPage, self.state.selectedPageSize)
              var closedCollectionNumber = await countClosedCollectionByOrg(organization)
              if ((closedCollection && closedCollectionNumber) || closedCollection.docs.length === 0) {
                self.setState({
                  loaded: true,
                  closedCollection: closedCollection.docs,
                  allClosedCollectionNumber: closedCollectionNumber,
                  closedCollectionNumber
                })
              }
            } else document.location.href = '/#/error'
          }
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getIdList (collections) {
    var idList = []
    _.forEach(collections, function (collection) {
      idList.push(collection._id)
    })
    return idList
  }

  handleChange (e, collections) {
    var self = this
    var idList = self.state.idList
    if (e.target.value === 'all' && e.target.checked) {
      this.setState({
        idList: self.getIdList(collections)
      })
    } else {
      if (e.target.value === 'all' && !e.target.checked) {
        idList = []
      } else {
        if (e.target.checked) {
          idList.push(e.target.value)
        } else {
          const index = idList.indexOf(e.target.value)
          if (index > -1) {
            idList.splice(index, 1)
          }
        }
      }
      this.setState({
        idList: idList
      })
    }
  }

  getCollectionByCollection (closedCollection) {
    var collectionByCollection = _.chain(closedCollection)
      .groupBy('type')
      .map((value, key) => ({ type: key, collections: value }))
      .value()
    return collectionByCollection
  }

  getCollectionByUser (closedCollection) {
    var collectionByUser = _.chain(closedCollection)
      .groupBy('user')
      .map((value, key) => ({ user: key, collections: value }))
      .value()
    return collectionByUser
  }

  handleChangeFilter (selected) {
    const self = this
    localStorage.setItem('selectedFilter', selected.value)
    const { t } = this.props
    var searchKey = self.state.searchKey
    var page = 1
    var pageSize = 10
    self.setState({ selectedOption: selected })
    if (selected.value !== 'all') {
      self.setState({
        loaded: false
      })
      getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
        self.setState({
          loaded: true,
          closedCollection: closedCollection.docs
        })
      })
    } else {
      self.setState({
        loaded: false,
        selectedPageSize: pageSize,
        selectedPage: page
      })
      if (searchKey && searchKey !== '') {
        getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
          closedCollection = _.filter(closedCollection.docs, function (o) { return t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) })
          var closedCollectionNumber = closedCollection.length
          var firstIndex = (page - 1) * pageSize
          var lastIndex = page * pageSize
          var newClosedCollection = closedCollection.slice(firstIndex, lastIndex)
          self.setState({
            loaded: true,
            closedCollectionNumber: closedCollectionNumber,
            closedCollection: newClosedCollection
          })
        })
      } else {
        getClosedCollectionByOrgAndPage(self.state.org, page, pageSize).then(function (closedCollection) {
          self.setState({
            loaded: true,
            closedCollection: closedCollection.docs
          })
        })
      }
    }
  }

  setPage (page) {
    const self = this
    const { t } = this.props
    var searchKey = self.state.searchKey
    self.setState({
      selectedPage: page,
      idList: []
    })
    if (searchKey && searchKey !== '') {
      getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
        closedCollection = _.filter(closedCollection.docs, function (o) { return t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) })
        var closedCollectionNumber = closedCollection.length
        var pageSize = self.state.selectedPageSize
        var firstIndex = (page - 1) * pageSize
        var lastIndex = page * pageSize
        var newClosedCollection = closedCollection.slice(firstIndex, lastIndex)
        self.setState({
          closedCollectionNumber: closedCollectionNumber,
          closedCollection: newClosedCollection
        })
      })
    } else {
      getClosedCollectionByOrgAndPage(self.state.org, page, self.state.selectedPageSize).then(function (closedCollection) {
        self.setState({
          closedCollection: closedCollection.docs
        })
      })
    }
  }

  setPageSize (pageSize, page) {
    const self = this
    const { t } = this.props
    var searchKey = self.state.searchKey
    self.setState({
      selectedPageSize: pageSize,
      selectedPage: page,
      idList: []
    })
    if (searchKey && searchKey !== '') {
      getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
        closedCollection = _.filter(closedCollection.docs, function (o) { return t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) })
        var closedCollectionNumber = closedCollection.length
        var firstIndex = (page - 1) * pageSize
        var lastIndex = page * pageSize
        var newClosedCollection = closedCollection.slice(firstIndex, lastIndex)
        self.setState({
          closedCollectionNumber: closedCollectionNumber,
          closedCollection: newClosedCollection
        })
      })
    } else {
      getClosedCollectionByOrgAndPage(self.state.org, page, pageSize).then(function (closedCollection) {
        self.setState({
          closedCollection: closedCollection.docs
        })
      })
    }
  }

  searchClosedCollection (searchKey, pageSize) {
    const self = this
    const { t } = this.props
    var selected = self.state.selectedOption.value
    self.setState({
      searchKey: searchKey
    })
    if (searchKey !== '') {
      if (selected === 'all') {
        getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
          closedCollection = _.filter(closedCollection.docs, function (o) { 
            return t(`doctype.${o.type}.name`).toLowerCase().includes(searchKey.toLowerCase()) || o.user.toLowerCase().includes(searchKey.toLowerCase()) || moment.utc(o.startAt).tz('Indian/Antananarivo').format('DD/MM/YYYY HH:mm').includes(searchKey)
          })
          var closedCollectionNumber = closedCollection.length
          var firstIndex = 0
          var lastIndex = pageSize
          var newClosedCollection = closedCollection.slice(firstIndex, lastIndex)
          self.setState({
            selectedPage: 1,
            closedCollectionNumber: closedCollectionNumber,
            closedCollection: newClosedCollection
          })
        })
      } else {
        getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
          self.setState({
            closedCollection: closedCollection.docs
          })
        })
      }
    } else {
      if (selected === 'all') {
        self.setState({
          loaded: false
        })
        countClosedCollectionByOrg(self.state.org).then(function (closedCollectionNumber) {
          getClosedCollectionByOrgAndPage(self.state.org, 1, pageSize).then(function (closedCollection) {
            self.setState({
              selectedPage: 1,
              loaded: true,
              closedCollectionNumber,
              closedCollection: closedCollection.docs
            })
          })
        })
      } else {
        getClosedCollectionByOrg(self.state.org).then(function (closedCollection) {
          self.setState({
            closedCollection: closedCollection.docs
          })
        })
      }
    }
  }

  renderTableContent (searchKey, closedCollection) {
    const self = this
    var view
    var selected = self.state.selectedOption.value
    switch (selected) {
      case 'all':
        view = (
          <ClosedCollectionTable
            org={this.state.org}
            closedCollection={closedCollection}
            idList={this.state.idList}
            handleChange={(e, collections) => this.handleChange(e, collections)}
          />
        )
        break
      case 'byCollection':
        view = (
          <ClosedTableByCollection
            org={this.state.org}
            closedCollection={self.getCollectionByCollection(closedCollection)}
            idList={this.state.idList}
            handleChange={(e, collections) => this.handleChange(e, collections)}
            searchKey={searchKey}
          />
        )
        break
      case 'byUser':
        view = (
          <ClosedTableByUser
            org={this.state.org}
            closedCollection={self.getCollectionByUser(closedCollection)}
            idList={this.state.idList}
            handleChange={(e, collections) => this.handleChange(e, collections)}
            searchKey={searchKey}
          />
        )
        break
      default:
        view = (
          <ClosedCollectionTable
            org={this.state.org}
            closedCollection={closedCollection}
            idList={this.state.idList}
            handleChange={(e, collections) => this.handleChange(e, collections)}
          />
        )
    }
    return view
  }

  render () {
    const { t } = this.props
    const options = [
      { value: 'all', label: t('journal') },
      { value: 'byCollection', label: t('by_collections') },
      { value: 'byUser', label: t('by_users') }
    ]
    if (!this.state.closedCollection || !this.state.closedCollectionNumber || !this.state.loaded) {
      if (this.state.closedCollection && this.state.closedCollection.length === 0) {
        return (
          <div className='container'>
            <div style={styles.content}>
              <div style={styles.closedContent}>
                <SearchBar getSearchKey={(key) => this.searchClosedCollection(key, this.state.selectedPageSize)} />
                <ClosedCollectionContentHeader
                  handleChangeFilter={(selected) => this.handleChangeFilter(selected)}
                  selectedOption={this.state.selectedOption}
                  options={options}
                  idList={this.state.idList}
                  closedCollectionLength={this.state.allClosedCollectionNumber}
                />
                <EmptyList text={t('empty_closed_collection_list')} />
              </div>
            </div>
          </div>
        )
      }
      return (
        <div className='container'>
          <div style={styles.content}>
            <div style={styles.closedContent}>
              <SearchBar getSearchKey={(key) => this.searchClosedCollection(key, this.state.selectedPageSize)} />
              <ClosedCollectionContentHeader
                handleChangeFilter={(selected) => this.handleChangeFilter(selected)}
                selectedOption={this.state.selectedOption}
                options={options}
                idList={this.state.idList}
                closedCollectionLength={this.state.allClosedCollectionNumber}
              />
              <FadeLoader />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='container'>
        <div style={styles.content}>
          <div style={styles.closedContent}>
            {/* <SearchBar getSearchKey={(key) => this.setState({ searchKey: key })} /> */}
            <SearchBar getSearchKey={(key) => this.searchClosedCollection(key, this.state.selectedPageSize)} />
            <ClosedCollectionContentHeader
              handleChangeFilter={(selected) => this.handleChangeFilter(selected)}
              selectedOption={this.state.selectedOption}
              options={options}
              idList={this.state.idList}
              closedCollectionLength={this.state.allClosedCollectionNumber}
            />
            {this.renderTableContent(this.state.searchKey, this.state.closedCollection)}
            {this.state.selectedOption.value === 'all' && this.state.closedCollectionNumber > 10
              ? (
                <Pagination
                  dataLength={this.state.closedCollectionNumber}
                  setPage={(page) => { this.setPage(page) }}
                  setPageSize={(pageSize, page) => { this.setPageSize(pageSize, page) }}
                  selectedPage={this.state.selectedPage}
                  selectedPageSize={this.state.selectedPageSize}
                />
              )
              : null}
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto'
  },
  closedContent: {
    paddingTop: 24,
    width: '100%',
    margin: 'auto',
    maxWidth: 766,
    minWidth: 360
  }
}

ClosedCollectionContent.propTypes = {
  t: PropTypes.func,
  org: PropTypes.string
}

export default (withTranslation()(ClosedCollectionContent))
